import React from "react";
import ErrorMessage from "app.components/Util/ErrorMessage";
import ToggleSwitch from "app.components/Util/ToggleSwitch";
import Input from "app.components/Util/Form/Input";
import Label from "app.components/Util/Form/Label";
import { useTranslation, Trans } from "react-i18next";

const OrganizationProfileEditorForm = ({
  upsertProfileError,
  profileDisplayName,
  upsertProfileLoading,
  handleUpdate,
  sharingAccess,
  profile,
  shareAccess,
  changeShareAccess,
  associatedEmailAddress,
  firstName,
  lastName,
  assumeOnCreation,
  setAssumeOnCreation,
}) => {
  const { t } = useTranslation();

  const errorData =
    upsertProfileError && upsertProfileError?.payload?.error
      ? upsertProfileError.payload?.error
      : upsertProfileError || undefined;

  return (
    <div className="c_organization-profile-editor-form">
      {upsertProfileError ? <ErrorMessage {...errorData} /> : null}
      <h3 className="mb-2 uppercase text-zinc-500">
        {t("organization.profiles.accountInformation")}
      </h3>
      <div className="mb-4 flex flex-col">
        <Label htmlFor="name" required>
          {t("organization.profiles.displayName")}
        </Label>
        <p className="text-xxs">
          {t("organization.profiles.displayNameCanHelpYouWith")}
        </p>
        <Input
          maxLength="36"
          className="max-w-96"
          placeholder=""
          data-tabbable={true}
          type="text"
          id="profileDisplayName"
          data-testid="profileDisplayName"
          name="profileDisplayName"
          value={profileDisplayName}
          readOnly={upsertProfileLoading}
          onChange={handleUpdate}
        />
      </div>
      <h3 className="mb-2 uppercase text-zinc-500">
        {t("organization.profiles.accountAccess")}
      </h3>
      {sharingAccess ? (
        <p className="text-sm" data-testid="sharing-access">
          <Trans i18nKey="organization.profiles.sharingAccessWith">
            <strong>{{ email: profile.email }}</strong>
          </Trans>
        </p>
      ) : (
        <React.Fragment>
          <p className="mb-4 text-sm">
            {t("organization.profiles.youCanElectToAllowClientsAccess")}
          </p>
          <ToggleSwitch
            className="mb-4"
            key="setupProfileAccount"
            id="setupProfileAccount"
            disabled={upsertProfileLoading}
            label={t("input.label.allowUserToManageData")}
            currentValue={shareAccess}
            callback={changeShareAccess}
          />

          {shareAccess ? (
            <React.Fragment>
              <div className="mb-4 flex flex-col">
                <Label htmlFor="accountEmail" required>
                  {t("input.label.emailAddress")}
                </Label>
                <p className="text-xxs">
                  {t(
                    "organization.profiles.emailAddressOfTheAccountYouWantToManage"
                  )}
                </p>
                <Input
                  readOnly={upsertProfileLoading}
                  onChange={handleUpdate}
                  id="associatedEmailAddress"
                  name="associatedEmailAddress"
                  type="text"
                  value={associatedEmailAddress}
                  placeholder={t("input.placeholder.emailExample")}
                />
              </div>
              <div className="mb-4 flex">
                <div className="mr-4 flex basis-1/2 flex-col">
                  <Label htmlFor="firstName" optional>
                    {t("input.label.firstName")}
                  </Label>
                  <Input
                    readOnly={upsertProfileLoading}
                    onChange={handleUpdate}
                    id="firstName"
                    name="firstName"
                    type="text"
                    value={firstName}
                  />
                </div>
                <div className="ml-4 flex basis-1/2 flex-col">
                  <Label htmlFor="lastName" optional>
                    {t("input.label.lastName")}
                  </Label>
                  <Input
                    readOnly={upsertProfileLoading}
                    onChange={handleUpdate}
                    id="lastName"
                    name="lastName"
                    type="text"
                    value={lastName}
                  />
                </div>
              </div>
            </React.Fragment>
          ) : null}
        </React.Fragment>
      )}
      {setAssumeOnCreation ? (
        <div className="flex flex-row">
          <Label className="ml-auto" htmlFor="assumeOnCreation">
            <Input
              data-tabbable={true}
              type="checkbox"
              data-testid="assumeOnCreation"
              id="assumeOnCreation"
              name="assumeOnCreation"
              checked={assumeOnCreation}
              onChange={(e) => {
                const nextVal = !assumeOnCreation;
                setAssumeOnCreation(nextVal);
              }}
            />
            <span className="ms-1">{t("input.label.assumeOnCreation")}</span>
          </Label>
        </div>
      ) : null}
    </div>
  );
};

export default OrganizationProfileEditorForm;
