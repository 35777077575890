import { call, put, all, select, delay } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";
import {
  dismissModal,
  startModalWorking,
  endModalWorking,
} from "app.reducers/ui";
import {
  requestbulkDeleteExchangeLedgers,
  receiveBulkDeleteExchangeLedgers,
  fetchLedgers,
} from "app.actions/exchanges";
import { addFlashMessage, removeFlashMessage } from "app.reducers/ui";
import { generateGUID } from "app.utils";
import {
  STATUS_DANGER,
  STATUS_SUCCESS,
} from "app.components/Messages/FlashMessage";
import i18n from "i18next";

import { ONE_MINUTE } from "app.constants";

function* bulkDeleteExchangeLedgers(action) {
  const { exchangeId, linkGUID } = action;

  const exchangeLedgers = yield select((state) => state.exchanges.ledgers);
  const ledgersForThisExchange = exchangeLedgers.filter(
    (x) => x.exchangeId === exchangeId
  );
  let ledgerIds = [];
  if (typeof linkGUID !== "undefined") {
    const ledgersForThisLinkGUID = ledgersForThisExchange.filter(
      (x) => x.linked?.linkGUID === linkGUID
    );
    ledgerIds = ledgersForThisLinkGUID.map((x) => x.id);
  } else {
    ledgerIds = ledgersForThisExchange.map((x) => x.id);
  }

  yield all([
    put(startModalWorking()),
    put(requestbulkDeleteExchangeLedgers()),
  ]);

  const response = yield call(
    ExchangeLedgerAPI.bulkDeleteExchangeLedgers,
    ledgerIds
  );

  if (response.error) {
    const messageId = generateGUID();
    yield all([
      put(endModalWorking()),
      put(dismissModal()),
      put(
        addFlashMessage(
          i18n.t("messages.ledgerDeleteError"),
          messageId,
          STATUS_DANGER
        )
      ),
    ]);
    yield delay(ONE_MINUTE);
    yield put(removeFlashMessage(messageId));
  } else {
    yield all([
      put(receiveBulkDeleteExchangeLedgers(STATUS_SUCCESS, ledgerIds)),
      put(endModalWorking()),
      put(dismissModal()),
      put(fetchLedgers()),
    ]);
  }
}

export default bulkDeleteExchangeLedgers;
