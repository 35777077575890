import React from "react";
import LoadingSpinner from "app.components/Util/LoadingSpinner";
import { Link } from "react-router-dom";

const BUTTON_BASE_CLASS =
  "border-0 cursor-pointer transition-all rounded whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed";

const BUTTON_BASE_SIZE = "text-sm px-3 py-1.5";

// we can add support for LG and XLG buttons later, but we don't
// even currently use them anywhere
const BUTTON_SIZE = {
  xs: "text-xxs px-2 py-.5",
  sm: "text-xs px-2.5 py-1",
  md: "text-xs px-2.5 py-1.5",
  lg: "text-md px-2.5 py-1.5",
};

const BUTTON_TYPE_BASE_STYLES = "bg-blue-100 text-blue-500";

const BUTTON_TYPE_STYLES = {
  pagination:
    "uppercase text-black text-center hover:bg-zinc-100 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50",
  paginationActive: "bg-green-400 text-white text-center uppercase",
  text: "bg-transparent text-zinc-500 text-center hover:underline pl-0 pr-0 uppercase",
  primary: "bg-blue-600 hover:bg-blue-605 text-white text-center uppercase",
  tag: "bg-sky-500 text-white text-center hover:bg-sky-600 uppercase",
  tagAlt:
    "bg-white border-x border-y border-sky-300 text-sky-500 text-center hover:bg-sky-100 uppercase",
  primaryAlt:
    "bg-blue-100 hover:bg-blue-200/50 text-blue-600 text-center uppercase",
  enroll: "bg-green-600 hover:bg-green-700 text-white text-center uppercase",
  controlGroup:
    "bg-white text-blue-400 text-center hover:text-blue-600 rounded-none uppercase",
  controlGroupActive:
    "text-white bg-blue-600 text-center rounded-none uppercase",
  secondaryControlGroup: "capitalize text-white text-center uppercase",
  secondaryControlGroupActive:
    "bg-white text-blue-600 text-center rounded-full capitalize uppercase",
  link: "text-linkBlue text-center hover:text-linkBlueActive hover:underline pl-0 py-0 disabled:opacity-50 disabled:text-zinc-800 disabled:cursor-not-allowed",
  linkArrow:
    "py-4 relative rounded bg-blue-50 block leading-none p-4 text-blue-600 text-left hover:bg-blue-100 group after:content-[' '] after:absolute after:right-4 after:top-1/2 after:-mt-2 after:border-transparent after:border-8 after:border-r-0 after:border-l-blue-500 after:hover:border-l-blue-600",
  quickPick:
    "bg-zinc-100 text-blue-600 text-center uppercase hover:bg-zinc-200",
  note: "bg-transparent text-zinc-500 text-center uppercase underline normal-case hover:text-zinc-800",
  danger: "bg-red-700 text-white text-center uppercase hover:bg-red-800",
  "less-danger":
    "bg-red-100 text-red-700 text-center uppercase hover:bg-red-200",
  iconOnlyAlt:
    "px-0 pt-0 pb-0 flex items-center justify-center bg-blue-100 text-center uppercase hover:bg-blue-200/50",
  iconOnly:
    "px-0 pt-0 pb-0 flex items-center text-center justify-center bg-zinc-100 uppercase hover:bg-zinc-200",
  iconOnlyNoBG:
    "px-0 pt-0 pb-0 flex items-center justify-center bg-transparent uppercase",
  iconOnlyCustomColors:
    "pl-0 pr-0 pt-0 pb-0 flex items-center justify-center uppercase",
  reconnect: "bg-green-50 text-green-600 uppercase hover:bg-green-100",
  review:
    "rounded group shadow-sm text-center ml-auto px-2 py-0.5 bg-indigo-50 border-indigo-500 border-x border-y text-indigo-500 flex items-center normal-case text-xs uppercase hover:bg-indigo-100 hover:text-indigo-600",
  outlinePrimary:
    "bg-transparent text-green-400 text-center uppercase hover:bg-green-50",
  secondary:
    "flex justify-center bg-amber-400 text-white text-center uppercase hover:bg-amber-500",
  outlineSecondary:
    "border-x border-y border-blue-300 text-blue-400 text-center uppercase hover:border-0rder-blue-400 hover:text-blue-400 hover:bg-blue-100",
};

const Button = ({
  text,
  buttonSize,
  buttonType,
  customClasses,
  children,
  asAnchor = false,
  flexButton = true,
  workingText,
  working = false,
  ...htmlAttributes
}) => {
  const buttonClassName = [
    buttonType !== "link" ? "relative" : null,
    buttonType !== "link" ? BUTTON_BASE_CLASS : null,
    BUTTON_SIZE[buttonSize] ? BUTTON_SIZE[buttonSize] : BUTTON_BASE_SIZE,
    customClasses ? customClasses : null,
    BUTTON_TYPE_STYLES[buttonType]
      ? BUTTON_TYPE_STYLES[buttonType]
      : BUTTON_TYPE_BASE_STYLES,
  ]
    .filter(Boolean)
    .join(" ");

  const buttonContent = (
    <React.Fragment>
      {working ? (
        <span className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
          <LoadingSpinner xsmall screenReaderText="" spinnerType={buttonType} />{" "}
        </span>
      ) : null}

      <span
        className={[
          flexButton ? "flex items-center justify-center" : "inline-block",
          working ? "opacity-0" : "",
        ].join(" ")}
        aria-hidden={working ? "true" : null}
      >
        {children || text}
      </span>
    </React.Fragment>
  );

  if (asAnchor) {
    if (typeof htmlAttributes.href !== "undefined") {
      return (
        <a className={buttonClassName} {...htmlAttributes}>
          {children || text}
        </a>
      );
    } else {
      return (
        <Link
          className={buttonClassName}
          title={working ? workingText : null}
          {...htmlAttributes}
        >
          {buttonContent}
        </Link>
      );
    }
  } else {
    return (
      <button
        className={buttonClassName}
        title={working ? workingText : null}
        {...htmlAttributes}
      >
        {buttonContent}
      </button>
    );
  }
};

Button.displayName = "Button";

export default Button;
