import { call, put, all, select } from "redux-saga/effects";

import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestClearLedgerItemReviewReason,
  receiveClearLedgerItemReviewReason,
  clearLedgerItemReviewReasonError,
  fetchExchangeLedger,
  fetchTimeline,
} from "app.actions/exchangeLedger";

import { fetchLedgers } from "app.actions/exchanges";

function* clearLedgerItemReviewReason(action) {
  const { itemId, reason, isTimeline = false } = action;

  yield put(requestClearLedgerItemReviewReason());
  const lastRequestParams = yield select(
    (state) => state.exchangeLedger.lastRequestParams
  );

  const response = yield call(
    ExchangeLedgerAPI.clearReviewReason,
    itemId,
    reason
  );

  if (response.error) {
    console.log(
      "Error in clearLedgerItemReviewReason",
      response.body,
      response.error
    );
    clearLedgerItemReviewReasonError(response.body);
  } else {
    const noLoading = true;
    const itemsFetch = isTimeline
      ? fetchTimeline(...lastRequestParams, noLoading)
      : fetchExchangeLedger(...lastRequestParams, noLoading);

    yield all([
      put(receiveClearLedgerItemReviewReason(response.body)),
      put(itemsFetch),
      put(fetchLedgers(true)), // true for oneTime so this doesn't trigger a duplicate recurring call to fetch ledgers.
    ]);
  }
}

export default clearLedgerItemReviewReason;
