import { makeActionCreator } from "app.actions/helpers";
/******************************************************************************/
/* linked exchanges */
/******************************************************************************/
export const LINKED_EXCHANGES_ERROR = "LINKED_EXCHANGES_ERROR";
export const LINKED_EXCHANGES_BEGIN = "LINKED_EXCHANGES_BEGIN";
export const LINKED_EXCHANGES_RECEIVE = "LINKED_EXCHANGES_RECEIVE";
export const LINKED_EXCHANGES_REQUEST = "LINKED_EXCHANGES_REQUEST";

export const requestLinkedExchanges = makeActionCreator(
  LINKED_EXCHANGES_REQUEST,
  "nextStatus"
);

export const receiveLinkedExchanges = makeActionCreator(
  LINKED_EXCHANGES_RECEIVE,
  "response",
  "flattenedLinkedExchanges",
  "nextStatus"
);

export const receiveLinkedExchangesError = makeActionCreator(
  LINKED_EXCHANGES_ERROR,
  "response"
);

export const fetchLinkedExchanges = makeActionCreator(LINKED_EXCHANGES_BEGIN);
