import { makeActionCreator } from "app.actions/helpers";
/******************************************************************************/
/* migrate exchange ledgers via upload */
/******************************************************************************/
export const MIGRATE_UPLOAD_ERROR = "MIGRATE_UPLOAD_ERROR";
export const MIGRATE_UPLOAD_RECEIVE = "MIGRATE_UPLOAD_RECEIVE";
export const MIGRATE_UPLOAD_REQUEST = "MIGRATE_UPLOAD_REQUEST";
export const MIGRATE_UPLOAD_BEGIN = "MIGRATE_UPLOAD_BEGIN";
export const MIGRATE_UPLOAD_RESET = "MIGRATE_UPLOAD_RESET";

export const migrateViaUpload = makeActionCreator(
  MIGRATE_UPLOAD_BEGIN,
  "files",
  "exchangeId"
);
export const requestMigrateViaUpload = makeActionCreator(
  MIGRATE_UPLOAD_REQUEST
);
export const receiveMigrateViaUpload = makeActionCreator(
  MIGRATE_UPLOAD_RECEIVE,
  "exchangeId",
  "responseId",
  "sources"
);
export const migrateViaUploadError = makeActionCreator(
  MIGRATE_UPLOAD_ERROR,
  "response",
  "error"
);

export const migrateViaUploadReset = makeActionCreator(MIGRATE_UPLOAD_RESET);
