import { createSlice } from "@reduxjs/toolkit";

import { unassumeProfileReceive } from "app.reducers/organizations";
import { reinitializePortfolio } from "app.reducers/portfolios";
import { STATUS } from "app.constants"; //,
import { jwtDecode } from "jwt-decode";

// initial state, eventually we'll restore this from local storage (per user)
let initialState = {
  token: undefined,
  ssoToken: undefined,
  user: {},
  userId: undefined,
  userLoading: false,
  isAuthenticating: false,
  isAuthenticated: false,
  subscriptionStatus: STATUS.UNINITIALIZED,
  subscription: {},
  requestingReset: undefined,
  receiveForgotPasswordError: undefined,
  savingPassword: undefined,
  passwordError: undefined,
  passwordErrorRecoverable: undefined,
  passwordSaved: false,
  verificationSent: false,
  rollForwardStatus: STATUS.UNINITIALIZED,
  preferences: {},
  preferencesStatus: STATUS.UNINITIALIZED,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    authorizeUser: () => {},
    requestAuthorizeUser: () => {},
    receiveAuthorizeUser: (state, action) => {
      let decodedJWT = {};
      try {
        decodedJWT = jwtDecode(action.payload);
      } catch (e) {
        //
      }

      state.isAuthenticated = true;
      state.isAuthenticating = false;
      state.token = action.payload;
      state.user = decodedJWT;
    },
    receiveSSOToken: (state, action) => {
      state.ssoToken = action.payload;
    },
    resetUser: (state) => {
      state.isAuthenticated = false;
      state.isAuthenticating = false;
      state.token = undefined;
      state.ssoToken = undefined;
      state.user = undefined;
    },
    markUserAuthenticated: (state) => {
      state.isAuthenticating = false;
      state.isAuthenticated = true;
    },
    receiveUserSubscription: (state, action) => {
      state.subscription = action.payload.response;
      state.subscriptionStatus = action.payload.status;
    },
    fetchUser: () => {},
    requestUser: (state) => {
      state.userLoading = true;
    },
    receiveUser: (state) => {
      state.userLoading = false;
    },
    forgotPassword: () => {},
    requestForgotPassword: (state) => {
      state.requestingReset = true;
      state.receiveForgotPasswordError = undefined;
    },
    receiveForgotPassword: (state) => {
      state.requestingReset = false;
    },
    receiveForgotPasswordError: (state, action) => {
      state.requestingReset = false;
      state.receiveForgotPasswordError = action.payload;
    },
    resetPassword: () => {},
    requestResetPassword: (state) => {
      state.savingPassword = true;
      state.passwordSaved = false;
    },
    receiveResetPassword: (state) => {
      state.savingPassword = false;
      state.passwordSaved = true;
      state.passwordError = undefined;
      state.passwordErrorRecoverable = undefined;
    },
    receiveResetPasswordError: (state, action) => {
      state.savingPassword = false;
      state.passwordSaved = false;
      state.passwordError = action.payload.message;
      state.passwordErrorRecoverable = action.payload.errorRecoverable;
    },
    updateRollForward: () => {},
    requestUpdateRollForward: (state) => {
      state.rollForwardStatus = STATUS.LOADING;
    },
    receiveUpdateRollForward: (state) => {
      state.rollForwardStatus = STATUS.LOADED;
    },
    userVerificationSent: (state) => {
      state.verificationSent = true;
    },
    resendUserAccountVerification: () => {},
    fetchAccountingPreferences: () => {},
    requestAccountingPreferences: (state) => {
      const { preferencesStatus } = state;
      state.preferencesStatus =
        preferencesStatus === STATUS.UNINITIALIZED
          ? STATUS.LOADING
          : STATUS.RELOADING;
    },
    receiveAccountingPreferences: (state, action) => {
      state.preferencesStatus = STATUS.LOADED;
      state.preferences = action.payload;
    },
    saveAccountingPreferences: () => {},
    requestSaveAccountingPreferences: () => {},
    receiveSaveAccountingPreferences: () => {},
    saveAccountingPreferencesError: () => {},
  },

  extraReducers: (builder) => {
    builder.addCase(reinitializePortfolio, (state) => {
      state.isAuthenticated = false;
      state.isAuthenticating = false;
      state.token = undefined;
      state.ssoToken = undefined;
      state.user = undefined;
    });
    builder.addCase(unassumeProfileReceive, (state) => {
      state.subscriptionStatus = STATUS.UNINITIALIZED;
      state.subscription = {};
      state.user = {};
      state.token = undefined;
    });
  },
});

// all actions
export const actions = userSlice.actions;

// individual actions
export const {
  authorizeUser,
  receiveAuthorizeUser,
  requestAuthorizeUser,
  receiveSSOToken,
  resetUser,
  markUserAuthenticated,
  fetchUserSubscription,
  requestUserSubscription,
  receiveUserSubscription,
  fetchUser,
  requestUser,
  receiveUser,
  forgotPassword,
  requestForgotPassword,
  receiveForgotPassword,
  receiveForgotPasswordError,
  resetPassword,
  requestResetPassword,
  receiveResetPassword,
  receiveResetPasswordError,
  updateRollForward,
  requestUpdateRollForward,
  receiveUpdateRollForward,
  userVerificationSent,
  resendUserAccountVerification,
  saveAccountingPreferences,
  fetchAccountingPreferences,
  requestAccountingPreferences,
  receiveAccountingPreferences,
  requestSaveAccountingPreferences,
  receiveSaveAccountingPreferences,
  saveAccountingPreferencesError,
} = userSlice.actions;

export default userSlice.reducer;
