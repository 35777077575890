import { take, call, put, select } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { openQuickSearch, closeQuickSearch } from "app.reducers/ui";

const SEARCH_TRIGGER = "search-trigger";
const ESC = "esc";
const TAB = "tab";

function createKeyboardEventChannel() {
  return eventChannel(emit => {
    const callback = e => {
      if (e.shiftKey || e.metaKey || e.altKey || e.ctrlKey) {
        return;
      }
      const target = e.target.tagName.toLowerCase();
      // only do keyboard shortcut if the user is not in a text editable field
      if (target !== "input" && target !== "textarea" && target !== "select") {
        if ("/" === e.key || "s" === e.key) {
          emit({ pressed: SEARCH_TRIGGER, e });
          return;
        }
      }
      if ("Escape" === e.key) {
        emit({ pressed: ESC, e: e });
        return;
      }
      if ("Tab" === e.key) {
        emit({ pressed: TAB, e: e });
        return;
      }
    };
    window.addEventListener("keydown", callback);

    return () => {
      window.removeEventListener("keydown", callback);
    };
  });
}

function* watchKeyboardChannel() {
  const keyboardChannel = yield call(createKeyboardEventChannel);
  while (true) {
    const hotKey = yield take(keyboardChannel);
    if (hotKey.pressed === SEARCH_TRIGGER) {
      const { modalBody, quickSearchOpen, quickSearchAvailable } = yield select(
        state => state.ui
      );
      if (
        modalBody === null &&
        quickSearchAvailable &&
        quickSearchOpen === false
      ) {
        hotKey.e.preventDefault();
        yield put(openQuickSearch());
      }
    }
    if (hotKey.pressed === ESC || hotKey.pressed === TAB) {
      const { quickSearchOpen, quickSearchAvailable } = yield select(
        state => state.ui
      );
      if (quickSearchAvailable && quickSearchOpen === true) {
        yield put(closeQuickSearch());
      }
    }
  }
}

export default watchKeyboardChannel;
