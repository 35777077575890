import { BALANCE_SERVICE_ID, TELLER_SERVICE_ID } from "./ssoServiceIds";

export const USER_BASE = whitelabel.config.USER_BASE;
export const ORGS_BASE = whitelabel.config.ORGS_BASE;
export const EXCHANGE_BASE = whitelabel.config.EXCHANGE_BASE;
export const ASSETS_BASE = whitelabel.config.ASSETS_BASE;
export const TELLER_BASE = whitelabel.config.TELLER_BASE;
export const SSO_BASE = whitelabel.config.SSO_BASE;
export const NEW_TICKET_URL_BALANCE =
  whitelabel.config.SSO_NEW_TICKET_URL + BALANCE_SERVICE_ID;
export const NEW_TICKET_URL_TELLER =
  whitelabel.config.SSO_NEW_TICKET_URL + TELLER_SERVICE_ID;

export const MARKETING_SITE_URL = whitelabel.config.MARKETING_SITE_URL;
export const MARKETING_SITE_NAME = whitelabel.config.MARKETING_SITE_NAME;

export const SUPPORT_SITE_URL = whitelabel.config.SUPPORT_SITE_URL;

export const TOS_URL = whitelabel.config.TOS_URL;
export const PRIVACY_POLICY_URL = whitelabel.config.PRIVACY_POLICY_URL;
export const CONTACT_US_URL = whitelabel.config.CONTACT_US_URL;

export const API_DOCS_URL = whitelabel.config.API_DOCS_URL;

export const HEADER_IMG = whitelabel.config.HEADER_IMG;
