export const makeTokenHash = (symbol, name) => {
  const input = `${typeof symbol === "string" ? symbol : ""}${
    typeof name === "string" ? name : ""
  }`;

  let hash = 0;
  if (input.length === 0) return hash;
  for (let i = 0; i < input.length; i++) {
    let char = input.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return `${symbol}-${hash}`;
};
