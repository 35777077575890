import api from "app.api/clients/exchange";

/**
 * Submits a request to get an exchange ledger timeline
 * @async
 * @function
 * @param {number} offset - The offset
 * @param {number} max - The max
 * @param {string} sort - How to sort
 * @param {string} order - How to order
 * @param {object} range - An object describing the range
 * @returns {object}  response - The Response or Error message
 */
export const getExchangeLedgerTimeline = (
  offset,
  max,
  sort,
  order,
  range,
  filter,
  timelineFilters,
  showAccrualTransactions
) => {
  const query = new URLSearchParams();
  if (max) query.append("max", max);
  if (offset) query.append("offset", offset);
  if (sort) query.append("sort", sort);
  if (order) query.append("order", order);
  if (filter) query.append(filter, true);
  if (showAccrualTransactions) query.append("acrl", true);

  if (timelineFilters.hashFilter) {
    query.append("h", timelineFilters.hashFilter);
  } else {
    if (timelineFilters.cannedFilters) {
      timelineFilters.cannedFilters.forEach((item) => {
        query.append("cf", item);
      });
    }
    if (timelineFilters.nftFilter) {
      timelineFilters.nftFilter.forEach((item) => {
        query.append("cf", item);
      });
    }
    if (timelineFilters.tokenFilter) {
      timelineFilters.tokenFilter.forEach((item) => {
        query.append("t", item);
      });
    }
    if (timelineFilters.sourceFilter) {
      timelineFilters.sourceFilter.forEach((item) => {
        query.append("s", item);
      });
    }
    if (timelineFilters.protocolFilter) {
      timelineFilters.protocolFilter.forEach((item) => {
        query.append("p", item);
      });
    }
    if (timelineFilters.addressFilter) {
      const addressFilters = timelineFilters.addressFilter.split(",");
      for (let i in addressFilters) {
        query.append("a", addressFilters[i]);
      }
    }
    if (timelineFilters.tagsFilter) {
      timelineFilters.tagsFilter.forEach((item) => {
        query.append("l", item);
      });
    }

    if (
      typeof range?.startDate !== "undefined" &&
      typeof range?.endDate !== "undefined"
    ) {
      query.append("startDate", range.startDate);
      query.append("endDate", range.endDate);
    }
  }

  return api.get(`/ledger/timeline`, {
    params: query,
    authenticated: true,
  });
};

/**
 * Submits a request to get metadata for a timeline, used to populated filters
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getTimelineMetadata = () => {
  return api.get(`/ledger/timeline/metadata`, {
    authenticated: true,
  });
};

/**
 * Submits a request to get the ledgers range date
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getTimelineDateRange = () => {
  return api.get(`/ledger/portfolioDateBounds`, {
    authenticated: true,
  });
};
