import { call, put, all } from "redux-saga/effects";
import {
  requestCreateWalletLedgers,
  createWalletLedgersError,
  receiveCreateWalletLedgers,
  fetchLedgers,
} from "app.actions/exchanges";
import { doRedirect } from "app.reducers/ui";

import {
  WALLET_XPUB,
  WALLET_MULTI_SIG,
  WALLET_ADDRESS,
  WALLET_TOKEN,
  WALLET_ADDRESS_GROUPS,
  FILE_UPLOAD,
  SPECID,
  FIFO,
} from "app.constants";

import ExchangesAPI from "app.api/ExchangesAPI";

function getStrategy(groupKey, evm, smartContractPlatform) {
  if (groupKey === "XTZ") return FIFO;
  if (evm === true) return FIFO;
  if (smartContractPlatform === true) return FIFO;
  if (groupKey === "BITGO") return "";
  return SPECID;
}

function* createWalletLedgers(action) {
  const {
    exchange,
    xpubs,
    addresses,
    token,
    signatures,
    addressGroupings,
    ledgerName,
  } = action;

  const nickname = ledgerName;

  yield put(requestCreateWalletLedgers());

  let response;
  switch (exchange.integrationType) {
    case WALLET_XPUB:
      response = yield call(
        ExchangesAPI.setExchangeXpubKey,
        exchange.id,
        xpubs,
        nickname
      );
      break;
    case WALLET_MULTI_SIG:
      response = yield call(
        ExchangesAPI.setExchangeMultiSig,
        exchange.id,
        xpubs,
        signatures,
        nickname
      );
      break;
    case WALLET_ADDRESS:
      response = yield call(
        ExchangesAPI.setExchangeAddresses,
        exchange.id,
        addresses,
        nickname
      );
      break;
    case WALLET_TOKEN:
      response = yield call(ExchangesAPI.setExchangeToken, exchange.id, token);
      break;
    case FILE_UPLOAD:
      // response = yield call(
      //   ExchangesAPI.createDashCoreLedgerViaUpload,
      //   exchange.id,
      //   file
      // );
      console.log("FILE_UPLOAD currently not enabled");
      return;
    case WALLET_ADDRESS_GROUPS:
      response = yield call(
        ExchangesAPI.createDashCoreLedger,
        exchange.id,
        addressGroupings
      );
      break;
    default:
      console.log("no matching integration type found");
      return;
  }

  const { body, error } = response;

  if (error) {
    if (
      typeof body.metadata !== "undefined" &&
      typeof body.metadata.code !== "undefined"
    ) {
      switch (body.metadata.code) {
        case "apiTokenAccessDenied":
        case "apiTokenOverScope":
        case "apiTokenExpired":
          yield put(
            createWalletLedgersError(
              { invalidToken: body.metadata.message },
              error
            )
          );
          break;
        default:
          yield put(createWalletLedgersError(body, error));
          break;
      }
    } else {
      // invalid xpubs etc, probably
      yield put(createWalletLedgersError(body, error));
    }
    //apiTokenAccessDenied
  } else {
    const strategy = getStrategy(
      exchange.groupKey,
      exchange.evm,
      exchange.smartContractPlatform
    );
    const linkGUID = body.linkGUID;
    const ledgerResponse = yield call(
      ExchangesAPI.addBulkExchangeLedgers,
      exchange.id,
      linkGUID,
      [],
      strategy,
      ledgerName
    );
    const { ledgerReponseBody, ledgerResponseError } = ledgerResponse;

    if (ledgerResponseError) {
      if (
        typeof ledgerReponseBody.metadata !== "undefined" &&
        typeof ledgerReponseBody.metadata.code !== "undefined"
      ) {
        switch (ledgerReponseBody.metadata.code) {
          case "apiTokenAccessDenied":
          case "apiTokenOverScope":
          case "apiTokenExpired":
            yield put(
              createWalletLedgersError(
                { invalidToken: ledgerReponseBody.metadata.message },
                ledgerResponseError
              )
            );
            break;
          default:
            yield put(
              createWalletLedgersError(ledgerReponseBody, ledgerResponseError)
            );
            break;
        }
      }
    } else {
      yield all([
        put(receiveCreateWalletLedgers(ledgerResponse.body, exchange.id)),
        put(fetchLedgers()),
        put(doRedirect(`/portfolio/add-ledger/confirm/${exchange.groupKey}`)),
      ]);
    }
  }
}

export default createWalletLedgers;
