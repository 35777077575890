// reducer for UI state
import { createSlice } from "@reduxjs/toolkit";
import { CREATE_MANUAL_TRANSFER_LINK_RECEIVE } from "app.actions/exchangeLedger";

import { testLocalStorage } from "app.utils";
import {
  STATUS,
  LISTBY,
  LEDGER_ACTION_BULK_SELECT,
  LEDGER_ACTION_NONE,
} from "app.constants";

import { ALL } from "app.components/Util/DatePicker";
import { testSessionStorage } from "app.utils/lib/testSessionStorage";
import { formatISO } from "date-fns";
// each reducer needs its own local storage key

// initial state, eventually we'll restore this from local storage (per user)
const initialState = {
  addressLabels: [],
  addressLabelsStatus: STATUS.UNINITIALIZED,
  addressLabelWorking: false,
  apiKeyError: undefined,
  apiKeyResponse: undefined,
  apiKeys: [],
  apiKeysStatus: STATUS.UNINITIALIZED,
  apiKeyWorking: false,
  customFieldsStatus: STATUS.UNINITIALIZED,
  customLedgersFields: {},
  enableSmartHidden: false,
  existingTags: [],
  failedCoinLogos: [],
  flashMessages: [],
  globalError: undefined,
  isUpdatingFields: {},
  lastPortfolioTab: "/portfolio",
  ledgerDetailGroupVisible: {},
  ledgerItemsPerPage: 50,
  ledgerListAdvancedFilter: [],
  ledgerListCurrencyFilter: [],
  ledgerListSourceFilter: [],
  ledgerListTextFilter: "",
  ledgerListViewBy: LISTBY.SOURCE,
  ledgersCustomFilters: {},
  logoSessionKey: "",
  manualTransferItems: {},
  modalBody: null,
  modalError: null,
  modalParams: null,
  modalTitle: null,
  modalWorking: false,
  networkError: undefined,
  pagination: {},
  pushTo: undefined,
  quickSearchAvailable: false,
  quickSearchOpen: false,
  recentlyViewedLedgers: [],
  selectedActionMode: LEDGER_ACTION_BULK_SELECT,
  selectedDatePreset: ALL.id,
  selectedDateRange: ALL.range,
  selectedPrecision: undefined,
  shouldBypassRollforward: false,
  shouldSkipIntro: false,
  showAccrualTransactions: false,
  showNewTransactionInfoColumn: false,
  tableColumns: {},
  tableSort: {},
  timelineFilters: {},
  timelineRangeDates: {},
  timelineRangeStatus: STATUS.UNINITIALIZED,
  uiLocalStorageKey: undefined,
  uiStateSessionKey: "",
  upgradeBannerHidden: false,
  upgradeSlideoutHidden: false,
  userRecentlyTags: [],
};

export const uiSlice = createSlice({
  name: "ui",
  initialState: initialState,
  reducers: {
    // local storage stuff
    setBasicLocalStorageSettings: (state, action) => {
      return persistState({ ...action.payload });
    },
    resetBasicLocalStorageSettings: (state, action) => {
      state.selectedDateRange = ALL.range;
      state.selectedDatePreset = ALL.id;
      state.ledgerListViewBy = LISTBY.SOURCE;
      state.ledgerListCurrencyFilter = [];
      state.ledgerListSourceFilter = [];
      state.ledgerListAdvancedFilter = [];
      state.ledgerListTextFilter = "";
      state.ledgerItemsPerPage = 50;
      state.ledgersCustomFilters = {};
      state.tableColumns = {};
      state.manualTransferItems = {};
    },
    setUiLocalStorageKey: (state, action) => {
      state.uiLocalStorageKey = action.payload;
    },

    selectDateRange: {
      reducer: (state, action) => {
        state.selectedDateRange = action.payload.selectedDateRange;
        state.selectedDatePreset = action.payload.selectedDatePreset;
        persistState(state);
      },
      prepare(selectedDateRange, selectedDatePreset) {
        return { payload: { selectedDateRange, selectedDatePreset } };
      },
    },
    // hide parts of the UI
    hideUpgradeSlideout: (state, action) => {
      state.upgradeSlideoutHidden = true;
      persistState(state);
    },
    hideUpgradeBanner: (state, action) => {
      state.upgradeBannerHidden = true;
    },
    hideEnableSmart: (state, action) => {
      state.enableSmartHidden = true;
    },
    skipIntro: (state, action) => {
      state.shouldSkipIntro = true;
    },
    bypassRollforward: (state, action) => {
      state.shouldBypassRollforward = true;
    },
    // modal related
    openModal: {
      reducer: (state, action) => {
        state.modalBody = action.payload.modalBody;
        state.modalTitle = action.payload.modalTitle;
        state.modalParams = action.payload.params;
        state.modalWorking = false; // new modals shouldn't just have this as true
      },
      prepare(modalBody, modalTitle, params) {
        return { payload: { modalBody, modalTitle, params } };
      },
    },
    dismissModal: (state, action) => {
      state.modalBody = null;
      state.modalTitle = null;
      state.modalParams = null;
      state.modalError = null;
      state.modalWorking = false;
      state.apiKeyResponse = undefined;
      state.apiKeyError = undefined;
    },
    receiveModalActionError: (state, action) => {
      state.modalError = action.payload.error;
    },
    startModalWorking: (state, action) => {
      state.modalWorking = true;
    },

    endModalWorking: (state, action) => {
      state.modalWorking = false;
    },

    openApiKeyModal: {
      reducer: () => {},
      prepare(params, apiLinkSuccessCallback, apiDismissCallback) {
        return {
          payload: { params, apiLinkSuccessCallback, apiDismissCallback },
        };
      },
    },
    dismissApiKeyModal: () => {},

    openWalletTokenModal: {
      reducer: () => {},
      prepare(params, tokenLinkSuccessCallback, tokenDismissCallback) {
        return {
          payload: { params, tokenLinkSuccessCallback, tokenDismissCallback },
        };
      },
    },
    dismissWalletTokenModal: () => {},

    // errors
    receiveGlobalError: (state, action) => {
      state.globalError = action.payload.error;
      state.modalBody = null;
      state.modalTitle = null;
      state.modalParams = null;
      state.modalError = null;
      state.modalWorking = false;
    },
    receiveNetworkError: {
      reducer: (state, action) => {
        state.networkError = action.payload.error;
      },
      prepare(error) {
        return { payload: { error } };
      },
    },

    // flash messages
    addFlashMessage: {
      reducer: (state, action) => {
        const updatedFlashMessages = [
          ...state.flashMessages,
          {
            message: action.payload.message,
            id: action.payload.messageId,
            status: action.payload.nextStatus,
          },
        ];
        state.flashMessages = updatedFlashMessages;
      },
      prepare(message, messageId, nextStatus) {
        return { payload: { message, messageId, nextStatus } };
      },
    },
    removeFlashMessage: {
      reducer: (state, action) => {
        const updatedFlashMessages = state.flashMessages.filter(
          (item) => item.id !== action.payload.messageId
        );
        state.flashMessages = updatedFlashMessages;
      },
      prepare(messageId) {
        return { payload: { messageId } };
      },
    },

    // quick search
    openQuickSearch: (state, action) => {
      state.quickSearchOpen = true;
    },

    closeQuickSearch: (state, action) => {
      state.quickSearchOpen = false;
    },
    setQuickSearchAvailable: (state, action) => {
      state.quickSearchAvailable = action.payload;
    },

    // recently  viewed ledgers
    viewedLedger: {
      reducer: (state, action) => {
        const { id, ledgerType } = action.payload;
        const newList = [
          { id: id, type: ledgerType },
          ...state.recentlyViewedLedgers.filter(
            (x) => !(x.id === id && x.type === ledgerType)
          ),
        ].slice(0, 10);
        state.recentlyViewedLedgers = newList;
        persistState(state);
      },
      prepare(id, ledgerType) {
        return { payload: (id, ledgerType) };
      },
    },
    removeViewedLedger: {
      reducer: (state, action) => {
        const newList = state.recentlyViewedLedgers.filter(
          (x) =>
            !(
              x.id === action.payload.id && x.type === action.payload.ledgerType
            )
        );
        state.recentlyViewedLedgers = newList;
        persistState(state);
      },
      prepare(id, ledgerType) {
        return { payload: (id, ledgerType) };
      },
    },

    // save some UI state
    setLedgerListView: (state, action) => {
      state.ledgerListViewBy = action.payload;
      persistState(state);
    },

    setLedgerPrecision: (state, action) => {
      state.selectedPrecision = action.payload;
      persistState(state);
    },

    setLastPortfolioTab: (state, action) => {
      state.lastPortfolioTab = action.payload;
    },

    setLedgerActionMode: (state, action) => {
      let manualTransferItems = { ...state.manualTransferItems };
      if (
        action.payload === LEDGER_ACTION_NONE ||
        action.payload === LEDGER_ACTION_BULK_SELECT
      ) {
        manualTransferItems = {};
      }
      state.manualTransferItems = manualTransferItems;
      state.selectedActionMode = action.payload;
      persistState(state);
    },

    // filters
    setLedgerListCurrencyFilter: (state, action) => {
      state.ledgerListCurrencyFilter = action.payload;
      persistState(state);
    },

    setLedgerListSourceFilter: (state, action) => {
      state.ledgerListSourceFilter = action.payload;
      persistState(state);
    },

    setLedgerListAdvancedFilter: (state, action) => {
      state.ledgerListAdvancedFilter = action.payload;
      persistState(state);
    },

    setLedgerListTextFilter: (state, action) => {
      state.ledgerListTextFilter = action.payload;
      persistState(state);
    },

    setLedgerItemsPerPage: (state, action) => {
      state.ledgerItemsPerPage = action.payload;
      persistState(state);
    },

    setShowAccrualTransactions: (state, action) => {
      state.showAccrualTransactions = action.payload;
      persistState(state);
    },

    setShowNewTransactionInfoColumn: (state, action) => {
      state.showNewTransactionInfoColumn = action.payload;
      persistState(state);
    },

    setTransactionFilter: {
      reducer: (state, action) => {
        state.ledgersCustomFilters[action.payload.ledgerGUID] = {
          transactionFilter: action.payload.filter,
        };
        persistState(state);
      },
      prepare(ledgerGUID, filter) {
        return { payload: { ledgerGUID, filter } };
      },
    },

    setTimelineFilters: {
      reducer: (state, action) => {
        state.timelineFilters[action.payload.filterName] =
          action.payload.filter;
        persistState(state);
      },
      prepare(filterName, filter) {
        return { payload: { filterName, filter } };
      },
    },

    clearTimelineFilters: (state, action) => {
      state.timelineFilters = {};
      persistState(state);
    },

    // timeline range
    requestTimelineLedgersRange: (state, action) => {
      state.timelineRangeStatus = action.payload;
    },
    receiveTimelineLedgersRange: (state, action) => {
      state.timelineRangeStatus = action.payload.nextStatus;
      state.timelineRangeDates.earliest =
        action.payload.response.earliestSeconds;
      state.timelineRangeDates.latest = action.payload.response.latestSeconds;
      state.timelineRangeDates.earliestAsISO = formatISO(
        new Date(action.payload.response.earliestSeconds * 1000),
        { representation: "date" }
      );
      state.timelineRangeDates.latestAsISO = formatISO(
        new Date(action.payload.response.latestSeconds * 1000),
        { representation: "date" }
      );
    },
    receiveTimelineLedgersRangeError: () => {},

    // redirect
    doRedirect: (state, action) => {
      state.pushTo = action.payload;
    },
    clearRedirect: (state, action) => {
      state.pushTo = undefined;
    },

    setLedgerRowDetailGroupVisible: (state, action) => {
      state.ledgerDetailGroupVisible[action.payload.groupId] =
        action.payload.state;
      persistState(state);
    },

    // custom fields
    fetchCustomFieldsData: {
      reducer: () => {},
      prepare(isOrganizationAdmin) {
        return { payload: { isOrganizationAdmin } };
      },
    },

    requestCustomFieldsData: (state, action) => {
      state.customFieldsStatus =
        state.customFieldsStatus === STATUS.LOADING
          ? STATUS.RELOADING
          : STATUS.LOADING;
    },
    receiveCustomFieldsData: (state, action) => {
      state.customLedgersFields = action.payload.response;
      state.customFieldsStatus = STATUS.LOADED;
    },

    receiveCustomFieldsDataError: (state, action) => {
      state.customLedgersFields = {};
      state.customFieldsStatus = STATUS.LOADED;
    },
    updateCustomFieldsData: {
      reducer: () => {},
      prepare(customData, updatingField, isOrganizationAdmin) {
        return { payload: { customData, updatingField, isOrganizationAdmin } };
      },
    },
    requestUpdateCustomFieldsData: (state, action) => {
      state.isUpdatingFields[action.payload] = true;
    },
    receiveUpdateCustomFieldsData: (state, action) => {
      state.isUpdatingFields[action.payload] = false;
    },
    updateCustomFieldsDataError: (state, action) => {
      state.isUpdatingFields[action.payload] = false;
    },

    setFailedCoinLogo: (state, action) => {
      state.failedCoinLogos.push(action.payload);
      persistSessionState(state);
    },

    savePagination: {
      reducer: (state, action) => {
        state.pagination[action.payload.tableId] = action.payload.page;
        persistSessionState(state);
      },
      prepare(tableId, page) {
        return { payload: { tableId, page } };
      },
    },

    saveSort: {
      reducer: (state, action) => {
        state.tableSort[action.payload.tableId] = action.payload.sort;
        persistSessionState(state);
      },
      prepare(tableId, sort) {
        return { payload: { tableId, sort } };
      },
    },

    saveColumns: {
      reducer: (state, action) => {
        state.tableColumns[action.payload.tableId] = action.payload.columns;
        persistState(state);
      },
      prepare(tableId, columns) {
        return { payload: { tableId, columns } };
      },
    },

    setUiStateFromSessionStorage: (state, action) => {
      state.pagination = action.payload.state.pagination || {};
      state.tableSort = action.payload.state.tableSort || {};
      state.manualTransferItems =
        action.payload.state.manualTransferItems || {};
    },

    setUiStateSessionStorageKey: (state, action) => {
      state.uiStateSessionKey = action.payload;
    },

    setLogosFromSessionStorage: (state, action) => {
      state.failedCoinLogos = action.payload.failedCoinLogos;
    },

    setUiLogoStorageKey: (state, action) => {
      state.logoSessionKey = action.payload;
    },

    // address labels
    requestAddressLabels: (state, action) => {
      const lastState = state.addressLabelsStatus;
      state.addressLabelsStatus =
        lastState === STATUS.LOADED ? STATUS.RELOADING : STATUS.LOADING;
    },
    receiveAddressLabels: {
      reducer: (state, action) => {
        state.addressLabels = action.payload.addressLabels;
        state.addressLabelsStatus = STATUS.LOADED;
      },
      prepare(response) {
        return { payload: { addressLabels: response } };
      },
    },
    addressLabelsError: () => {},
    requestSetAddressLabel: () => {},
    setAddressLabel: {
      reducer: (state, action) => {},
      prepare(address, label) {
        return { payload: { address, label } };
      },
    },
    receiveSetAddressLabel: (state, action) => {
      state.addressLabelWorking = false;
    },
    setAddressLabelError: (state, action) => {
      state.addressLabelWorking = false;
    },

    // API Keys
    requestApiKeys: (state, action) => {
      const lastState = state.apiKeysStatus;
      state.apiKeysStatus =
        lastState === STATUS.LOADED ? STATUS.RELOADING : STATUS.LOADING;
    },

    receiveApiKeys: (state, action) => {
      state.apiKeys = action.payload;
      state.apiKeysStatus = STATUS.LOADED;
    },

    requestCreateApiKey: (state, action) => {
      state.apiKeyError = undefined;
      state.apiKeyWorking = true;
    },

    receiveCreateApiKey: (state, action) => {
      state.apiKeyWorking = false;
      state.apiKeyResponse = action.payload;
    },

    createApiKeyError: (state, action) => {
      state.apiKeyError = action.payload;
      state.apiKeyWorking = false;
    },

    // manual transfer
    addItemToManualTransfer: {
      reducer: (state, action) => {
        const { manualTransferItems } = state;
        manualTransferItems[action.payload.entryId] = {
          ...action.payload.entry,
        };
        state.manualTransferItems = { ...manualTransferItems };
        persistSessionState(state);
      },
      prepare(entryId, entry) {
        return { payload: { entryId, entry } };
      },
    },

    removeItemFromManualTransfer: (state, action) => {
      const { manualTransferItems } = state;
      delete manualTransferItems[action.payload];
      state.manualTransferItems = { ...manualTransferItems };
      persistSessionState(state);
    },
    createApiKey: {
      reducer: () => {},
      prepare(readOnlyApiKey, apiKeyLabel, profileGUID, orgGUID) {
        return {
          payload: { readOnlyApiKey, apiKeyLabel, profileGUID, orgGUID },
        };
      },
    },
    completeApiKeyModal: {
      reducer: () => {},
      prepare(linkGUID, autoImport) {
        return {
          payload: { linkGUID, autoImport },
        };
      },
    },
    completeWalletTokenModal: {
      reducer: () => {},
      prepare(linkGUID) {
        return {
          payload: { linkGUID },
        };
      },
    },
    fetchTimelineLedgersRange: () => {},
    fetchApiKeys: {
      reducer: () => {},
      prepare(reload) {
        return {
          payload: { reload },
        };
      },
    },
    fetchAddressLabels: {
      reducer: () => {},
      prepare(reload) {
        return {
          payload: { reload },
        };
      },
    },

    // tags
    fetchUserTagsData: () => {},
    receiveUserTagsData: {
      reducer: (state, action) => {
        state.existingTags = action.payload.response;
      },
      prepare(response) {
        return { payload: { response } };
      },
    },
    receiveUserTagsDataError: (state, action) => {
      state.existingTags = action.payload;
    },

    // bulktags
    bulkCreateTag: {
      reducer: () => {},
      prepare(ledgerItemIds, tag, isTimeline) {
        return { payload: { ledgerItemIds, tag, isTimeline } };
      },
    },
    requestBulkCreateTag: () => {},
    receiveBulkCreateTag: {
      reducer: () => {},
      prepare(data) {
        return { payload: { data } };
      },
    },
    bulkCreateTagError: {
      reducer: () => {},
      prepare(data) {
        return { payload: { data } };
      },
    },
    // create tag
    createTag: {
      reducer: () => {},
      prepare(ledgerItemId, tag, tags) {
        return { payload: { ledgerItemId, tag, tags } };
      },
    },
    receiveCreateTagError: {
      reducer: () => {},
      prepare(data) {
        return { payload: { data } };
      },
    },
    receiveCreateTag: {
      reducer: () => {},
      prepare(data) {
        return { payload: { data } };
      },
    },
    // delete tag
    deleteTag: {
      reducer: () => {},
      prepare(ledgerItemId, tag, tags) {
        return { payload: { ledgerItemId, tag, tags } };
      },
    },
    requestDeleteTag: {
      reducer: () => {},
      prepare(data) {
        return { payload: { data } };
      },
    },
    receiveDeleteTag: {
      reducer: () => {},
      prepare(data) {
        return { payload: { data } };
      },
    },
    receiveDeleteTagError: {
      reducer: () => {},
      prepare(data) {
        return { payload: { data } };
      },
    },

    // ui only tag stuff
    updateRecentTags: (state, action) => {
      const newTag = action.payload;
      const updatedTags = state.userRecentlyTags.filter(
        (tag) => tag !== newTag
      );
      state.userRecentlyTags = [newTag, ...updatedTags];
      persistState(state);
    },

    // delete Api Key
    deleteApiKey: {
      reducer: () => {},
      prepare(key) {
        return { payload: { key } };
      },
    },
    requestDeleteApiKey: () => {},
    receiveDeleteApiKey: () => {},
    deleteApiKeyError: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase(CREATE_MANUAL_TRANSFER_LINK_RECEIVE, (state) => {
      state.manualTransferItems = {};
      persistSessionState(state);
    });
  },
});

// all actions
export const actions = uiSlice.actions;

// individual actions
export const {
  addFlashMessage,
  addItemToManualTransfer,
  addressLabelsError,
  apiKeysError,
  bulkCreateTag,
  bulkCreateTagError,
  bypassRollforward,
  clearRedirect,
  clearTimelineFilters,
  closeQuickSearch,
  completeApiKeyModal,
  completeWalletTokenModal,
  createApiKey,
  createApiKeyError,
  createTag,
  deleteApiKey,
  deleteApiKeyError,
  deleteTag,
  dismissApiKeyModal,
  dismissModal,
  dismissWalletTokenModal,
  doRedirect,
  endModalWorking,
  fetchAddressLabels,
  fetchApiKeys,
  fetchCustomFieldsData,
  fetchTimelineLedgersRange,
  fetchUserTagsData,
  hideEnableSmart,
  hideUpgradeBanner,
  hideUpgradeSlideout,
  openApiKeyModal,
  openModal,
  openQuickSearch,
  openWalletTokenModal,
  receiveAddressLabels,
  receiveApiKeys,
  receiveBulkCreateTag,
  receiveCreateApiKey,
  receiveCreateTag,
  receiveCreateTagError,
  receiveCustomFieldsData,
  receiveCustomFieldsDataError,
  receiveDeleteApiKey,
  receiveDeleteTag,
  receiveDeleteTagError,
  receiveGlobalError,
  receiveModalActionError,
  receiveNetworkError,
  receiveSetAddressLabel,
  receiveTimelineLedgersRange,
  receiveTimelineLedgersRangeError,
  receiveUpdateCustomFieldsData,
  receiveUserTagsData,
  receiveUserTagsDataError,
  removeFlashMessage,
  removeItemFromManualTransfer,
  removeViewedLedger,
  requestAddressLabels,
  requestApiKeys,
  requestBulkCreateTag,
  requestCreateApiKey,
  requestCustomFieldsData,
  requestDeleteApiKey,
  requestDeleteTag,
  requestSetAddressLabel,
  requestTimelineLedgersRange,
  requestUpdateCustomFieldsData,
  resetBasicLocalStorageSettings,
  saveColumns,
  savePagination,
  saveSort,
  selectDateRange,
  setAddressLabel,
  setAddressLabelError,
  setBasicLocalStorageSettings,
  setFailedCoinLogo,
  setLastPortfolioTab,
  setLedgerActionMode,
  setLedgerItemsPerPage,
  setLedgerListAdvancedFilter,
  setLedgerListCurrencyFilter,
  setLedgerListSourceFilter,
  setLedgerListTextFilter,
  setLedgerListView,
  setLedgerPrecision,
  setLedgerRowDetailGroupVisible,
  setLogosFromSessionStorage,
  setQuickSearchAvailable,
  setShowAccrualTransactions,
  setShowNewTransactionInfoColumn,
  setTimelineFilters,
  setTransactionFilter,
  setUiLocalStorageKey,
  setUiLogoStorageKey,
  setUiStateFromSessionStorage,
  setUiStateSessionStorageKey,
  skipIntro,
  startModalWorking,
  updateCustomFieldsData,
  updateCustomFieldsDataError,
  updateRecentTags,
  viewedLedger,
} = uiSlice.actions;

export default uiSlice.reducer;

// we don't want to persist all state to local storage, so when a some UI actions happen,
// we'll pull out the keys we are interested in and save them
function persistState(state) {
  if (testLocalStorage() && state.uiLocalStorageKey) {
    const {
      enableSmartHidden,
      ledgerDetailGroupVisible,
      ledgerItemsPerPage,
      ledgerListAdvancedFilter,
      ledgerListCurrencyFilter,
      ledgerListSourceFilter,
      ledgerListTextFilter,
      ledgerListViewBy,
      ledgersCustomFilters,
      recentlyViewedLedgers,
      selectedActionMode,
      selectedDatePreset,
      selectedDateRange,
      selectedPrecision,
      showAccrualTransactions,
      showNewTransactionInfoColumn,
      tableColumns,
      timelineFilters,
      upgradeSlideoutHidden,
      userRecentlyTags,
    } = state;

    localStorage[state.uiLocalStorageKey] = JSON.stringify({
      enableSmartHidden,
      ledgerDetailGroupVisible,
      ledgerItemsPerPage,
      ledgerListAdvancedFilter,
      ledgerListCurrencyFilter,
      ledgerListSourceFilter,
      ledgerListTextFilter,
      ledgerListViewBy,
      ledgersCustomFilters,
      recentlyViewedLedgers,
      selectedActionMode,
      selectedDatePreset,
      selectedDateRange,
      selectedPrecision,
      showAccrualTransactions,
      showNewTransactionInfoColumn,
      tableColumns,
      timelineFilters,
      userRecentlyTags,
      upgradeSlideoutHidden,
    });
  }
  return state;
}

function persistSessionState(state) {
  const {
    failedCoinLogos,
    logoSessionKey,
    manualTransferItems,
    pagination,
    stateSessionKey,
    tableSort,
  } = state;

  const sessionState = { pagination, tableSort, manualTransferItems };

  if (testSessionStorage() && stateSessionKey) {
    sessionStorage.setItem(stateSessionKey, JSON.stringify(sessionState));
    sessionStorage.setItem(logoSessionKey, JSON.stringify(failedCoinLogos));
  }

  return state;
}
