export const ledgerNameExists = (ledgerName, ledgersByCurrency) => {
  if (ledgerName !== "") {
    const currencies = Object.keys(ledgersByCurrency);
    for (let i = 0; i < currencies.length; i++) {
      const ledgers = ledgersByCurrency[currencies[i]];
      for (let i = 0; i < ledgers.length; i++) {
        const ledger = ledgers[i];
        if (ledger.name.trim() === ledgerName.trim()) {
          return true;
        }
      }
    }
  }
  return false;
};
