import { makeActionCreator } from "app.actions/helpers";
/******************************************************************************/
/* rename ledger */
/******************************************************************************/
export const RENAME_EXCHANGE_LEDGER_BEGIN = "RENAME_EXCHANGE_LEDGER_BEGIN";
export const RENAME_EXCHANGE_LEDGER_REQUEST = "RENAME_EXCHANGE_LEDGER_REQUEST";
export const RENAME_EXCHANGE_LEDGER_RECEIVE = "RENAME_EXCHANGE_LEDGER_RECEIVE";
export const RENAME_EXCHANGE_LEDGER_ERROR = "RENAME_EXCHANGE_LEDGER_ERROR";

export const renameLedger = makeActionCreator(
  RENAME_EXCHANGE_LEDGER_BEGIN,
  "name",
  "ledgerId"
);
export const requestRenameLedger = makeActionCreator(
  RENAME_EXCHANGE_LEDGER_REQUEST
);
export const receiveRenameLedger = makeActionCreator(
  RENAME_EXCHANGE_LEDGER_RECEIVE,
  "name",
  "ledgerId"
);
export const renameLedgerError = makeActionCreator(
  RENAME_EXCHANGE_LEDGER_ERROR,
  "response",
  "error"
);
