import configureApi from "../core/configureApi";
import { store } from "app.config/configureStore";
import { receiveAuthorizeUser } from "app.reducers/user";
// going to do every step inline here - then refactor everything out in a logical way
import {
  EXCHANGE_BASE,
  TELLER_BASE,
  NEW_TICKET_URL_BALANCE,
} from "app.constants";

export const REFRESH_TOKEN_URL = [TELLER_BASE, "/checkpoint/checkTicket"].join(
  ""
);

export function getBearerToken() {
  const { user } = store.getState();
  if (user) {
    const { token } = user;
    return token;
  }
  return undefined;
}

export function setBearerToken(body) {
  store.dispatch(receiveAuthorizeUser(body.access_token));
}

export function getAssumedProfile() {
  const { organizations } = store.getState();
  if (organizations) {
    const { currentlyAssumedProfile } = organizations;
    return currentlyAssumedProfile;
  }
  return undefined;
}

const api = configureApi({
  baseUrl: EXCHANGE_BASE,
  refreshTokenUrl: REFRESH_TOKEN_URL,
  newTicketURL: NEW_TICKET_URL_BALANCE,
  getBearerToken: getBearerToken,
  setBearerToken: setBearerToken,
  getAssumedProfile: getAssumedProfile,
});

export default api;
