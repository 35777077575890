import { store } from "app.config/configureStore";

import { receiveGlobalError } from "app.reducers/ui";

function handleResponseError(response) {
  if (typeof response !== "undefined" && response.error) {
    if (typeof response.error === "string") {
      // If we receive an error that is a string based, show that string
      store.dispatch(receiveGlobalError({ error: response.error }));
      return;
    } else if (
      typeof response.error === "object" &&
      typeof response.error.message === "string"
    ) {
      if (typeof response.error.code === "undefined") return;
      // if ECONNABORTED is the error code, I think we'll swallow the error, as
      // this seems to pop an error on the page if a user reloads the page during
      // an XHR request
      if (response.error.code === "ECONNABORTED") return;
      console.log("response error code", response.error.code);
      store.dispatch(receiveGlobalError({ error: response.error.message }));
      return;
    }
  }

  store.dispatch(
    receiveGlobalError({
      error: "An unexpected error has occurred. Our team has been notified.",
    })
  );
  return response;
}

export default handleResponseError;
