import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dismissModal } from "app.reducers/ui";
import { clearUpgradeQuote } from "app.actions/account";

import StripeContainer from "app.components/Util/StripeContainer";

import SelectPlan from "./SelectPlan";
import Payment from "./Payment";

import ModalHeader from "app.components/Modal/ModalHeader";

import { ACCOUNT_STATUS } from "app.constants";
import { useTranslation } from "react-i18next";

export const STEPS = {
  PLANS: "PLANS",
  PAYMENT: "PAYMENT",
};

function ModalChangePlan({ canDismissCallback }) {
  const { t } = useTranslation();

  const { currentlyActivePlan, upgradePlanStatus, plans, referrer } =
    useSelector((state) => state.account);

  const [step, setStep] = useState(STEPS.PLANS);
  const [newPlan, setNewPlan] = useState(undefined);

  const reduxDispatch = useDispatch();

  const { modalWorking } = useSelector((state) => state.ui);

  const isLoading = upgradePlanStatus === ACCOUNT_STATUS.LOADING;

  useEffect(() => {
    canDismissCallback(!modalWorking === true);
  }, [modalWorking, canDismissCallback]);

  const selectPlanCallback = (plan, step) => {
    setStep(step);
    setNewPlan(plan);
    reduxDispatch(clearUpgradeQuote());
  };

  useEffect(() => {
    // just to do some clean up when this modal unmounts.
    return () => {
      reduxDispatch(clearUpgradeQuote());
    };
  }, [reduxDispatch]);

  if (!currentlyActivePlan) return null;

  return (
    <React.Fragment>
      <ModalHeader
        title={t("modals.managedAccount.manageSubscription")}
        disabled={modalWorking || isLoading}
        closeCallback={(event) => {
          event.preventDefault();
          if (modalWorking || isLoading) return;
          reduxDispatch(dismissModal());
        }}
      />

      {STEPS[step] === STEPS.PLANS ? (
        <div className="m-4 -mt-4 flex">
          {plans.map((plan, i) => (
            <SelectPlan
              key={i}
              selectPlanCallback={selectPlanCallback}
              plan={plan}
              referrer={referrer}
              currentlyActivePlan={currentlyActivePlan}
            />
          ))}
        </div>
      ) : null}

      {STEPS[step] === STEPS.PAYMENT ? (
        <StripeContainer>
          <Payment newPlan={newPlan} />
        </StripeContainer>
      ) : null}
    </React.Fragment>
  );
}

ModalChangePlan.displayName = "ModalChangePlan";

export default ModalChangePlan;
