import { makeActionCreator } from "app.actions/helpers";
/******************************************************************************/
/* exchange accounts */
/******************************************************************************/

export const EXCHANGE_ACCOUNTS_BEGIN = "EXCHANGE_ACCOUNTS_BEGIN";
export const EXCHANGE_ACCOUNTS_REQUEST = "EXCHANGE_ACCOUNTS_REQUEST";
export const EXCHANGE_ACCOUNTS_RECEIVE = "EXCHANGE_ACCOUNTS_RECEIVE";
export const EXCHANGE_ACCOUNTS_ERROR = "EXCHANGE_ACCOUNTS_ERROR";

export const fetchExchangeAccounts = makeActionCreator(
  EXCHANGE_ACCOUNTS_BEGIN,
  "exchange",
  "linkGUID",
  "apiLinkSuccessCallback",
  "apiDismissCallback",
  "oauthRequiredCallback"
);

export const requestExchangeAccounts = makeActionCreator(
  EXCHANGE_ACCOUNTS_REQUEST
);

export const receiveExchangeAccounts = makeActionCreator(
  EXCHANGE_ACCOUNTS_RECEIVE,
  "exchange",
  "response"
);
export const exchangeAccountsError = makeActionCreator(
  EXCHANGE_ACCOUNTS_ERROR,
  "response"
);
