import { makeActionCreator } from "app.actions/helpers";
/******************************************************************************/
/* update roll forward for open debit lots via upload */
/******************************************************************************/
export const UPDATE_ROLLFORWARD_OPEN_LOTS_UPLOAD_BEGIN =
  "UPDATE_ROLLFORWARD_OPEN_LOTS_UPLOAD_BEGIN";
export const UPDATE_ROLLFORWARD_OPEN_LOTS_UPLOAD_REQUEST =
  "UPDATE_ROLLFORWARD_OPEN_LOTS_UPLOAD_REQUEST";
export const UPDATE_ROLLFORWARD_OPEN_LOTS_UPLOAD_RECEIVE =
  "UPDATE_ROLLFORWARD_OPEN_LOTS_UPLOAD_RECEIVE";
export const UPDATE_ROLLFORWARD_OPEN_LOTS_UPLOAD_ERROR =
  "UPDATE_ROLLFORWARD_OPEN_LOTS_UPLOAD_ERROR";

export const updateRollforwardOpenLotsViaUpload = makeActionCreator(
  UPDATE_ROLLFORWARD_OPEN_LOTS_UPLOAD_BEGIN,
  "file"
);

export const requestUpdateRollforwardOpenLotsViaUpload = makeActionCreator(
  UPDATE_ROLLFORWARD_OPEN_LOTS_UPLOAD_REQUEST
);

export const receiveUpdateRollforwardOpenLotsViaUpload = makeActionCreator(
  UPDATE_ROLLFORWARD_OPEN_LOTS_UPLOAD_RECEIVE,
  "response"
);

export const updateRollforwardOpenLotsViaUploadError = makeActionCreator(
  UPDATE_ROLLFORWARD_OPEN_LOTS_UPLOAD_ERROR,
  "error"
);
