// In certain scenarios handful of API calls to the exchange API need to be sent using an ORG
// admin JWT

import configureApi from "../core/configureApi";
import { store } from "app.config/configureStore";
import { receiveAuthorizeOrganization } from "app.reducers/organizations";
// going to do every step inline here - then refactor everything out in a logical way
import {
  EXCHANGE_BASE,
  TELLER_BASE,
  NEW_TICKET_URL_BALANCE,
} from "app.constants";

export const REFRESH_TOKEN_URL = [TELLER_BASE, "/checkpoint/checkTicket"].join(
  ""
);

export function getBearerToken() {
  const { organizations } = store.getState();
  if (organizations) {
    const { organizationAuthToken } = organizations;
    return organizationAuthToken;
  }

  return undefined;
}

export function setBearerToken(body) {
  store.dispatch(receiveAuthorizeOrganization(body.access_token, body));
}

const api = configureApi({
  baseUrl: EXCHANGE_BASE,
  refreshTokenUrl: REFRESH_TOKEN_URL,
  newTicketURL: NEW_TICKET_URL_BALANCE,
  getBearerToken: getBearerToken,
  setBearerToken: setBearerToken,
});

export default api;
