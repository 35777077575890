import React from "react";
import AddExchangeImportForm from "app.components/Ledger/Import/AddExchangeImportForm";
import UpdateAddressGroupings from "app.components/Ledger/Import/UpdateAddressGroupings";
import Button from "app.components/Util/Button";
import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";

import { useDispatch, useSelector } from "react-redux";
import { dismissModal } from "app.reducers/ui";
import {
  FILE_UPLOAD,
  FILE_UPLOAD_MIGRATION,
  WALLET_ADDRESS_GROUPS,
} from "app.constants";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const ModalAddExchangeImport = ({
  canDismissCallback,
  exchange,
  ledgerIds,
  linkGUID,
}) => {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();

  const { uploading, updating } = useSelector((state) => state.exchanges);

  const disableButtons = useMemo(() => {
    if (uploading || updating) return true;
    return false;
  }, [updating, uploading]);

  let title = null;

  if (
    exchange.integrationType === FILE_UPLOAD ||
    exchange.integrationType === FILE_UPLOAD_MIGRATION
  ) {
    title = t("modals.uploadTransactions");
  }
  if (exchange.integrationType === WALLET_ADDRESS_GROUPS) {
    title = t("modals.updateAddressGroupings");
  }

  return (
    <React.Fragment>
      <ModalHeader
        title={title}
        closeCallback={(event) => {
          event.preventDefault();
          reduxDispatch(dismissModal());
        }}
      />

      <div className="m-4">
        {exchange.integrationType === FILE_UPLOAD ||
        exchange.integrationType === FILE_UPLOAD_MIGRATION ? (
          <AddExchangeImportForm
            exchange={exchange}
            linkGUID={linkGUID}
            ledgerIds={ledgerIds}
            canDismissCallback={canDismissCallback}
            uploading={uploading}
          />
        ) : null}
        {exchange.integrationType === WALLET_ADDRESS_GROUPS ? (
          <UpdateAddressGroupings
            exchange={exchange}
            ledgerIds={ledgerIds}
            canDismissCallback={canDismissCallback}
            updating={updating}
          />
        ) : null}
      </div>
      <ModalControls>
        <Button
          disabled={disableButtons}
          onClick={(e) => {
            e.preventDefault();
            reduxDispatch(dismissModal());
          }}
          data-tabbable={true}
          buttonType="text"
          text={t("button.close")}
        />
      </ModalControls>
    </React.Fragment>
  );
};

ModalAddExchangeImport.displayName = "ModalAddExchangeImport";

export default ModalAddExchangeImport;
