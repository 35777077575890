import React from "react";
import SvgLoader from "app.components/Util/SvgLoader";
import { useTranslation } from "react-i18next";
import Button from "app.components/Util/Button";
import { PAGINATION_TYPES } from "./constants";

export function PreviousButton({ location, currentPage, type, onSelect }) {
  const { t } = useTranslation();

  const linkBody = (
    <React.Fragment>
      <SvgLoader id="Chevron" className="h-4 w-4 rotate-180 stroke-black" />
      <span className="sr-only">{t("common.previousPage")}</span>
    </React.Fragment>
  );

  if (currentPage !== 0) {
    const prevPage = currentPage - 1;

    if (type === PAGINATION_TYPES.BUTTON) {
      return (
        <Button
          onClick={(e) => {
            e.preventDefault();
            onSelect(prevPage);
          }}
          buttonType="pagination"
        >
          {linkBody}
        </Button>
      );
    }

    const queryParams = new URLSearchParams(location.search);
    queryParams.set("p", prevPage);
    const toURL = `${location.pathname}?${queryParams.toString()}`;

    return (
      <Button asAnchor={true} to={toURL} buttonType="pagination">
        {linkBody}
      </Button>
    );
  }

  return (
    <Button disabled={true} buttonType="pagination">
      {linkBody}
    </Button>
  );
}
PreviousButton.displayName = "PreviousButton";
export default PreviousButton;
