import { call, put, all, delay } from "redux-saga/effects";
import * as organizationActions from "app.reducers/organizations";
import { dismissModal } from "app.reducers/ui";
import OrganizationAPI from "app.api/OrganizationAPI";
import { getSagaResult } from "app.sagas/helpers";
import { STATUS, TEN_SECONDS } from "app.constants";
import { STATUS_SUCCESS } from "app.components/Messages/FlashMessage";

import { addFlashMessage, removeFlashMessage } from "app.reducers/ui";
import { generateGUID } from "app.utils";

import i18n from "i18next";

function* updateProfile(action) {
  const {
    profileGUID,
    displayName,
    userGUID,
    associatedEmailAddress,
    firstName,
    lastName,
    shouldInvite,
  } = action.payload;

  yield put(organizationActions.updateProfileRequest(STATUS.LOADING));

  const response = yield call(
    OrganizationAPI.updateProfile,
    profileGUID,
    displayName
  );

  if (response.error) {
    const code = response.error;
    const message = response.body.message;
    const error = { code, message };

    yield put(organizationActions.updateProfileError(error, STATUS.ERROR));
    return;
  }

  if (userGUID && shouldInvite) {
    try {
      yield* getSagaResult(
        organizationActions.inviteUser(
          userGUID,
          associatedEmailAddress,
          firstName,
          lastName
        ),
        organizationActions.inviteUserReceive,
        organizationActions.inviteUserError
      );
    } catch (error) {
      // ToDo: figure out what to do here
      yield put(organizationActions.updateProfileError(error, STATUS.ERROR));

      return;
    }
  }

  const messageId = generateGUID();

  const flashMessage = addFlashMessage(
    i18n.t("messages.profileUpdateSuccess", {
      profileName: displayName || associatedEmailAddress,
    }),
    messageId,
    STATUS_SUCCESS
  );

  yield all([
    put(organizationActions.updateProfileReceive(response.body, STATUS.LOADED)),
    put(organizationActions.fetchOrganization()),
    put(dismissModal()),
    put(flashMessage),
    put(organizationActions.exitProfileEditor()),
  ]);

  yield delay(TEN_SECONDS);
  yield put(removeFlashMessage(messageId));
}

export default updateProfile;
