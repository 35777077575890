/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import HelpBanner from "app.components/Util/HelpBanner";
import { Label, TextArea } from "app.components/Util/Form";

import Big from "big.js";
import { APP_NAME, DASH_CORE_SUPPORT_URL } from "app.constants";

const AddressGroupings = ({
  selectedCurrency,
  addressGroupings,
  updateAddressGroupingCallback,
}) => {
  const { t } = useTranslation();

  const [addressData, setAddressData] = useState("");
  const [addressCount, setAddressCount] = useState(0);
  const [balance, setBalance] = useState(0);

  const updateAddresses = (rawData) => {
    setAddressData(rawData);

    if (rawData.trim() === "") {
      updateAddressGroupingCallback([]);
      return;
    }

    const re = /\t/gi;
    const cleanerData = rawData.replace(re, "");
    // remove everything before "listaddressgroupings" command
    const dataParts = (
      cleanerData.split("listaddressgroupings")[1] || ""
    ).split("\n");

    try {
      if (dataParts.length > 0) {
        const findFirstBracket = dataParts.findIndex((i) => i === "[");
        if (findFirstBracket > -1) {
          const asData = JSON.parse(dataParts.slice(findFirstBracket).join(""));
          const flattened = asData.flat(1);
          setBalance(
            flattened.reduce((sum, addr) => sum.plus(addr[1]), new Big(0))
          );
          setAddressCount(flattened.length);
          updateAddressGroupingCallback(flattened.map((addr) => addr[0]));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const textAreaHelpMessage =
    addressGroupings.length === 0 && addressData.trim() !== "" ? (
      <p className="text-xs text-red-700">
        {t("addLedger.address.noAddressesFound")}
      </p>
    ) : (
      <p className="text-xs">
        {addressGroupings.length === 0 ? (
          <Trans i18nKey="addLedger.address.howToGetYourDashCoreWalletAddress">
            <strong></strong>
            <a
              href={DASH_CORE_SUPPORT_URL}
              rel="noopener noreferrer"
              target="_blank"
              className="text-linkBlue hover:text-linkBlueActive hover:underline"
            ></a>
          </Trans>
        ) : (
          <Trans
            i18nKey="addLedger.address.addressWithBalance"
            count={addressCount}
          >
            <strong></strong>
            <strong>
              {{ balance: balance.toString() }}
              {{ selectedCurrency }}
            </strong>
          </Trans>
        )}
      </p>
    );

  return (
    <div className="mt-2">
      <HelpBanner
        text={t("addLedger.address.appUsesYourAddressGroupingsFor", {
          appName: APP_NAME,
        })}
      />
      <Label htmlFor="addressData">
        {t("addLedger.address.pasteAddressGroupingsBelow")}
      </Label>
      <TextArea
        id="addressData"
        name="addressData"
        data-tabbable={true}
        value={addressData}
        className="w-full"
        onChange={(e) => updateAddresses(e.target.value)}
        clearButton={addressData !== ""}
        onClear={(e) => updateAddresses("")}
        helpMessage={textAreaHelpMessage}
      ></TextArea>
    </div>
  );
};

AddressGroupings.displayName = "AddressGroupings";

export default AddressGroupings;
