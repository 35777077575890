import { call, put, select } from "redux-saga/effects";
import logout from "app.utils/logout";
import { receiveAuthorizeUser } from "app.reducers/user";
import { jwtDecode } from "jwt-decode";
import i18n, { setHTMLLang, changeLanguage } from "app.config/i18n";

import AuthAPI from "app.api/AuthAPI";

function* refreshUserToken(action) {
  try {
    const ticket = yield call(AuthAPI.getTicket);

    const currentlyAssumedProfile = yield select(
      (state) => state.organizations.currentlyAssumedProfile
    );
    const response = yield call(
      AuthAPI.getTokenFromTicket,
      ticket,
      currentlyAssumedProfile
    );

    if (response.error) {
      // Bubble up so we can just go to login
      throw response.error;
    } else {
      const { body } = response;

      yield put(receiveAuthorizeUser(body.access_token));

      const userPreference = jwtDecode(body.access_token)?.preference;

      const language = userPreference?.languageTag;
      if (i18n.language !== language) {
        changeLanguage(language);
        setHTMLLang(language);
      }
    }
  } catch (err) {
    if (err.error === 401) {
      logout();
    }
  }
}

export default refreshUserToken;
