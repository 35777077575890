import { call, put, select } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  receiveCreateTag,
  receiveCreateTagError,
  fetchUserTagsData,
} from "app.reducers/ui";

function* createTag(action) {
  const { ledgerItemId, tag, tags } = action.payload;

  const updatedTags = [...tags, tag];

  const { data } = yield select((state) => state.exchangeLedger);
  const items = data.items;

  const updatedData = items.map((entry) =>
    entry.id === ledgerItemId ? { ...entry, customTags: updatedTags } : entry
  );

  yield put(receiveCreateTag(updatedData));

  const response = yield call(
    ExchangeLedgerAPI.createLedgerTag,
    ledgerItemId,
    updatedTags
  );

  const { body, error } = response;
  if (error) {
    yield put(receiveCreateTagError(body));
  } else {
    yield put(fetchUserTagsData());
  }
}

export default createTag;
