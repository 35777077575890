import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import { rootReducer } from "app.reducers";
import { SAGA_ERROR } from "app.constants";
import createSagaMiddleware from "redux-saga";
import rootSaga from "app.sagas";
import crashReporter from "app.config/crashReporter";
//import browserHistory from "app.config/configureBrowserHistory";
//import networkCancelMiddleware from "app.config/networkCancelMiddleware";
//import disableLocationChangeWhenModalOpen from "app.config/disableLocationChangeWhenModalOpen";

const IS_DEV =
  import.meta.env.VITE_NODE_ENV === "development" &&
  import.meta.env.VITE_NODE40_ENV === "development";

const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    if (IS_DEV) {
      console.log("DEVEOPMENT MODE: Crash Reporting has been disabled.");
      console.error(error);
      return;
    }
    const state = store.getState();
    const profileGUID =
      state.user && state.user.user ? state.user.user.profileGUID : undefined;
    const username =
      state.user && state.user.user ? state.user.user.sub : undefined;
    crashReporter(SAGA_ERROR, error, profileGUID, username);
  },
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    if (import.meta.env.VITE_NODE40_ENV !== "production") {
      return getDefaultMiddleware({ immutableCheck: { warnAfter: 128 } })
        .concat(sagaMiddleware)
        .concat(createLogger({ collapsed: true, diff: true }));
    } else {
      return getDefaultMiddleware({
        immutableCheck: { warnAfter: 128 },
      }).concat(sagaMiddleware);
    }
  },
  devTools: import.meta.env.VITE_NODE40_ENV !== "production" ? true : false,
});

sagaMiddleware.run(rootSaga);
