import { buffers } from "redux-saga";
import {
  put,
  call,
  select,
  take,
  all,
  actionChannel,
} from "redux-saga/effects";
import {
  COUPON_APPLY,
  COUPON_CLEAR,
  UPDATE_ENROLLMENT_ZIP_CODE,
  ENROLLMENT_LEAVE,
  UPDATE_ENROLLMENT_PROFILE_LIMIT,
  requestPlanQuote,
  receivePlanQuote,
  receivePlanQuoteError,
  setBillingError,
} from "app.actions/enrollment";
import TellerAPI from "app.api/TellerAPI";
import { PROFESSIONAL_PLAN_ID } from "app.constants";
import { lookupTranslation } from "app.utils";
import { BILLING_ERROR_PATH } from "app.constants";

import { couponMatchesInputCoupon } from "app.utils";

function* fetchPlanQuote(planId, zipCode, couponCode, profileLimit) {
  const userToken = yield select((state) => state.user.token);
  const isLoggedIn = typeof userToken !== "undefined";

  const getPlansCall = isLoggedIn
    ? TellerAPI.getPaymentProductsForUser
    : TellerAPI.getPaymentProductsForEnrollment;

  const plansResponse = yield call(
    getPlansCall,
    couponCode,
    zipCode,
    profileLimit
  );

  if (plansResponse.error) throw new Error(plansResponse.body.message);

  const plans = plansResponse.body.reduce(
    (acc, product) => [...acc, ...product.plans],
    []
  );
  return plans.find((x) => x.planId === planId);
}

function* enterEnrollmentCheckout(action) {
  const { planId } = action;

  const enrollablePlans = yield select(
    (state) => state.enrollment.enrollablePlans
  );

  const currentPlans = enrollablePlans.reduce((acc, plan) => {
    if (plan.subPlans) acc.push(...plan.subPlans);
    else acc.push(plan);

    return acc;
  }, []);

  const matchingPlan = currentPlans.find((x) => x.planId === planId);

  const enrollingInProfessionalPlan =
    matchingPlan.planId === PROFESSIONAL_PLAN_ID;
  const enrollingInDefaultPlan = matchingPlan.isDefaultPlan;

  if (enrollingInDefaultPlan) {
    // If we're signing up for the default plan, we don't need to do any auto update
    yield put(receivePlanQuote(matchingPlan, true));
    return;
  }

  // Store if we've got a user
  const referral = yield select((state) => state.enrollment.referredBy);
  const hasReferral = typeof referral !== "undefined";

  const useReferralCode =
    hasReferral &&
    couponMatchesInputCoupon(matchingPlan.availableCouponId, referral.code);

  const initialPlanQuote = yield* fetchPlanQuote(
    planId,
    undefined,
    useReferralCode ? referral.code : undefined,
    enrollingInProfessionalPlan ? 1 : undefined
  );

  yield put(receivePlanQuote(initialPlanQuote, true));

  let couponCode = useReferralCode ? referral.code : undefined;
  let zipCode = undefined;
  let profileLimit = enrollingInProfessionalPlan ? 1 : undefined;

  // We make a channel so that we can batch these actions and process them as they happen
  const channelBuffer = buffers.expanding();
  const updateChannel = yield actionChannel(
    [
      ENROLLMENT_LEAVE,
      COUPON_APPLY,
      COUPON_CLEAR,
      UPDATE_ENROLLMENT_PROFILE_LIMIT,
      UPDATE_ENROLLMENT_ZIP_CODE,
    ],
    channelBuffer
  );

  while (true) {
    const { type, ...payload } = yield take(updateChannel);
    if (type === ENROLLMENT_LEAVE) return;
    let runUpdate = false;
    let skipUpdate = false;
    if ((type === COUPON_APPLY || type === COUPON_CLEAR) && !useReferralCode) {
      yield put(requestPlanQuote());
      const newCouponCode = payload.coupon || "";
      try {
        const couponPlanQuote = yield* fetchPlanQuote(
          planId,
          zipCode,
          newCouponCode,
          profileLimit
        );
        if (newCouponCode) {
          if (
            couponMatchesInputCoupon(
              couponPlanQuote.availableCouponId,
              newCouponCode
            )
          ) {
            couponCode = newCouponCode;
            yield put(
              receivePlanQuote(couponPlanQuote, channelBuffer.isEmpty())
            );
          } else {
            yield all([
              put(receivePlanQuoteError(channelBuffer.isEmpty())),
              put(
                setBillingError(
                  lookupTranslation(
                    "invalid_coupon_for_plan",
                    BILLING_ERROR_PATH
                  )
                )
              ),
            ]);
          }
        } else {
          couponCode = undefined;
          yield put(receivePlanQuote(couponPlanQuote, channelBuffer.isEmpty()));
        }
      } catch (err) {
        yield all([
          put(receivePlanQuoteError(channelBuffer.isEmpty())),
          put(
            setBillingError(
              lookupTranslation("invalid_coupon_for_plan", BILLING_ERROR_PATH)
            )
          ),
        ]);
      }
    } else if (type === UPDATE_ENROLLMENT_ZIP_CODE) {
      if (zipCode !== payload.zipCode) {
        zipCode = payload.zipCode;
        runUpdate = true;
      } else {
        skipUpdate = true;
      }
    } else if (type === UPDATE_ENROLLMENT_PROFILE_LIMIT) {
      if (profileLimit !== payload.profileLimit && payload.profileLimit >= 1) {
        profileLimit = payload.profileLimit;
        runUpdate = true;
      } else {
        skipUpdate = true;
      }
    }
    if (runUpdate) {
      yield put(requestPlanQuote());
      const newPlanQuote = yield* fetchPlanQuote(
        planId,
        zipCode,
        couponCode,
        profileLimit
      );
      yield put(receivePlanQuote(newPlanQuote, channelBuffer.isEmpty()));
    } else if (skipUpdate) {
      const currentPlanQuote = yield select(
        (state) => state.enrollment.planQuote
      );
      yield put(receivePlanQuote(currentPlanQuote, channelBuffer.isEmpty()));
    }
  }
}

export default enterEnrollmentCheckout;
