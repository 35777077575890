import React from "react";
import { useTranslation } from "react-i18next";

import ExchangeLogo from "app.components/Images/ExchangeLogo";
import WalletGroup from "app.components/Images/WalletGroup";
import CardIndicator from "app.components/Util/CardIndicator";

import {
  WALLET,
  FILE_UPLOAD,
  FILE_UPLOAD_MIGRATION,
  SOURCELESS,
  ETH_ADDRESS_SHADOW,
} from "app.constants";

function getMessage(source, t) {
  let message = null;
  switch (true) {
    case source.isSyncing:
      message = "modals.syncAll.syncInProgess";
      break;
    case source.type === FILE_UPLOAD:
      message = "modals.syncAll.requiresUpload";
      break;
    case source.type === FILE_UPLOAD_MIGRATION:
      message = "modals.syncAll.unableToSync";
      break;
    case source.linkedExchange?.active === false:
      message = "modals.syncAll.connectionNeedsToBeRepaired";
      break;
    default:
      break;
  }

  if (message)
    return <em className="mr-2 text-xs text-zinc-600">{t(message)}</em>;
}

const ModalSyncAllListItems = ({
  source,
  selectedSources,
  setSelectedSources,
}) => {
  const sourceIndex = selectedSources.findIndex((s) => s === source.id);
  const { t } = useTranslation();
  const itemDisabled =
    source.type === FILE_UPLOAD ||
    source.type === FILE_UPLOAD_MIGRATION ||
    source.type === SOURCELESS ||
    source.type === ETH_ADDRESS_SHADOW ||
    source.linkedExchange?.active === false ||
    source.isSyncing === true;

  const itemClass = [
    "h-14",
    "px-4",
    itemDisabled ? null : "focus:bg-blue-50",
    itemDisabled ? null : "hover:bg-blue-50",
    itemDisabled ? "opacity-60 bg-zinc-100/70" : null,
    "text-left",
    "items-center",
    "flex",
    "justify-between",
    "w-full",
    "gap-2",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <li className="border-b last:border-0">
      <button
        disabled={itemDisabled}
        className={itemClass}
        onClick={(e) => {
          e.preventDefault();
          if (sourceIndex > -1) {
            const nextSet = selectedSources.filter(
              (item) => item !== source.id
            );
            setSelectedSources(nextSet);
          } else {
            setSelectedSources([...selectedSources, source.id]);
          }
        }}
      >
        <div>
          {source.source.isWallet ||
          source.type === SOURCELESS ||
          source.type === ETH_ADDRESS_SHADOW ? (
            <WalletGroup
              shadowLedger={source.type === ETH_ADDRESS_SHADOW}
              source={source.exchange?.name}
            />
          ) : (
            <ExchangeLogo name={source.exchange?.name} small={true} />
          )}
        </div>

        {source.type === WALLET ? (
          <div>
            <p>Wallets</p>
            {source.unsyncableCount ? (
              <p className="text-xs leading-none text-zinc-500">
                {t("modals.syncAll.walletsWillAutoSync", {
                  count: source.syncableWallets.length,
                })}{" "}
                {t("modals.syncAll.walletsCannotAutoSync", {
                  count: source.unsyncableCount,
                })}
              </p>
            ) : null}
          </div>
        ) : (
          <div>
            <p>{source.exchange?.label}</p>
            <p className="text-xs leading-none text-zinc-500">
              {source.linkedExchange?.nickname}
            </p>
          </div>
        )}
        <div className="ml-auto">
          <div className="flex items-center">
            {getMessage(source, t)}
            <CardIndicator
              disabled={itemDisabled}
              type="square"
              active={sourceIndex > -1}
            />
          </div>
        </div>
      </button>
    </li>
  );
};

export default ModalSyncAllListItems;
