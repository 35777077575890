import api from "../clients/teller";

/**
 * Gets the current user based on attached credentials
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getUser = () => {
  return api.get("/account", {
    authenticated: true,
  });
};

/**
 * Submits a request to update the profile of the current user
 * @async
 * @function
 * @param {string} username - The user's username
 * @param {string} firstName - The user's first name
 * @param {string} lastName - The user's last name
 * @returns {object}  response - The Response or Error message
 */
export const sendProfileUpdate = (username, firstName, lastName) => {
  return api.put("/account", {
    data: { username, email: username, firstName, lastName },
    authenticated: true,
  });
};
/**
 * Submits a request to trigger the forgot-password emai;
 * @async
 * @function
 * @param {string} emailAddress - The user's email
 * @returns {object}  response - The Response or Error message
 */
export const sendForgotPassword = (email) => {
  return api.post(`/account/forgot-password`, { data: email });
};

/**
 * Sends a request to reset the user's password
 * @async
 * @function
 * @param {string} username - The user's email
 * @param {string} token - The cognito token
 * @param {string} password - The new password
 * @returns {object}  response - The Response or Error message
 */
export const sendResetPassword = (username, token, password) => {
  return api.post("/account/forgot-password/submit", {
    data: {
      username,
      token,
      password,
    },
  });
};

/**
 * Submits a request to update the password of the current user
 * @async
 * @function
 * @param {string} currentPassword - The user's current password
 * @param {string} newPassword - The user's new password
 * @param {string} confirmNewPassword - The user's new password confirmation
 * @returns {object}  response - The Response or Error message
 */
export const sendPasswordUpdate = (
  currentPassword,
  newPassword,
  confirmNewPassword,
  totpToken
) => {
  return api.put("/account/change-password", {
    data: {
      currentPassword: currentPassword,
      newPassword,
      confirmNewPassword,
      totpToken,
    },
    authenticated: true,
  });
};

export const updateUserLanguage = (languageTag) => {
  return api.put("/account/preference", {
    data: { languageTag },
    authenticated: true,
  });
};

export const updateUserTimeZone = (timeZone) => {
  return api.put("/account/preference", {
    data: { timeZone },
    authenticated: true,
  });
};

export const updateUserFiat = (currencyCode) => {
  return api.put("/account/preference", {
    data: { currencyCode },
    authenticated: true,
  });
};

export const resendUserAccountVerification = (email) => {
  return api.post("/account/resend-verification-email", {
    data: { email },
    authenticated: true,
  });
};
