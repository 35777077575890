import { select, put } from "redux-saga/effects";
import Big from "big.js";
import i18n from "i18next";

import { saveSmartWalletChartData } from "app.actions/computables";

import { formatCurrencyValue } from "app.utils/currencies";

function* runComputeChartData() {
  const smartWalletList =
    (yield select((state) => state.smartWallet.smartWalletList)) || [];

  const valueArray = [];

  smartWalletList.forEach((item) => {
    let totalAmount;
    try {
      totalAmount = new Big(item.overviewData.totalAmount);
    } catch {
      totalAmount = new Big(0);
    }

    if (item.overviewData !== null) {
      const valuation = item.currentPrice || 0;

      const totalValue = totalAmount.times(valuation);

      const formattedBalance = formatCurrencyValue(totalAmount);

      // don't bother charting anything with absurdly small values
      if (parseFloat(totalValue.toFixed(8)) > 0) {
        valueArray.push({
          symbol: item.currencyType,
          label: item.tokenName || item.currencyType,
          value: totalValue.toFixed(8),
          rawValue: totalValue,
          balance: formattedBalance,
          isMultiToken: (item.tokenBalances || []).length > 0,
        });
      }
    }
  });

  // take value array and sort by rawValue and split
  valueArray.sort((a, b) => {
    if (b.rawValue.gt(a.rawValue)) return 1;
    if (b.rawValue.lt(a.rawValue)) return -1;
    return 0;
  });

  // leave the top 4, save the bottom N
  const bottomValues = valueArray.splice(4);

  // if there is only one left, just toss it back on the pile
  if (bottomValues.length === 1) {
    valueArray.push(bottomValues[0]);
  } else {
    // if its 0, we're done
    if (bottomValues.length > 1) {
      const remainingValue = bottomValues.reduce((sum, item) => {
        return sum.plus(item.rawValue);
      }, new Big(0));
      valueArray.push({
        symbol: undefined,
        label: i18n.t("portfolio.allOtherAssets"),
        value: remainingValue.toFixed(8),
        rawValue: remainingValue,
        balance: undefined,
        color: "#efefef",
      });
    }
  }

  const totalPortfolioValue = valueArray.reduce((sum, item) => {
    return sum.plus(item.rawValue);
  }, new Big(0));

  // before sending in an action - convert all Big.js values to string
  valueArray.forEach((i) => (i.rawValue = i.rawValue.toJSON()));

  yield put(
    saveSmartWalletChartData(valueArray, {
      totalPortfolioValue: totalPortfolioValue.toString(),
    })
  );
}

export default runComputeChartData;
