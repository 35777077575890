import api from "../clients/exchange";

/**
 * Submits a request to get the available tax forms for a ledger
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @returns {object}  response - The Response or Error message
 */
export const listAvailableFiles = () => {
  return api.get(`/document/available`, {
    authenticated: true,
  });
};

/**
 * Submits a request to get generate a document
 * @async
 * @function
 * @param {string} fileType - The file to generate
 * @param {params} params - query params will be different depending on the file being requested
 * @returns {object}  response - The Response or Error message
 */
export const generateFile = (fileType, requestParams) => {
  return api.post(`/document/generate`, {
    data: { documentForms: [{ ...requestParams, fileType: fileType }] },
    authenticated: true,
  });
};

/**
 * Submits a request to get a document URL
 * @async
 * @function
 * @param {string} documentGUID - The file to generate
 * @returns {object}  response - The Response or Error message
 */
export const downloadFile = (documentGUID) => {
  return api.get(`/document/download/${documentGUID}`, {
    authenticated: true,
  });
};

/**
 * Submits a request to get a document URL
 * @async
 * @function
 * @param {string} documentGUID - The file to generate
 * @returns {object}  response - The Response or Error message
 */
export const shareFile = (documentGUID) => {
  return api.get(`/document/share/${documentGUID}`, {
    authenticated: true,
  });
};
