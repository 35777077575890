import { call, put, all } from "redux-saga/effects";
//import { push } from "connected-react-router";
import * as organizationActions from "app.reducers/organizations";
import OrganizationAPI from "app.api/OrganizationAPI";
import { getSagaResult } from "app.sagas/helpers";
import { STATUS } from "app.constants";
import { doRedirect } from "app.reducers/ui";

function* updateTeamMemberAccess(action) {
  const { id, admin } = action.payload;

  yield put(organizationActions.updateTeamMemberAccessRequest(STATUS.LOADING));

  const response = yield call(OrganizationAPI.updateCoworkerAccess, id, admin);

  if (response.error) {
    const code = response.error;
    const message = response.body.message;
    const error = { code, message };

    yield put(
      organizationActions.updateTeamMemberAccessError(error, STATUS.ERROR)
    );
  } else {
    // refresh teamMember list, then go to teamMember page
    try {
      yield* getSagaResult(
        organizationActions.fetchTeamMemberList(),
        organizationActions.receiveTeamMemberList,
        organizationActions.receiveTeamMemberListError
      );
    } catch (error) {
      // ToDo: figure out what to do here
      console.log(error);
      return;
    }

    yield all([
      put(
        organizationActions.updateTeamMemberAccessReceive(
          response.body,
          STATUS.LOADED
        )
      ),
      put(doRedirect("/organization/team-members")),
    ]);
  }
}

export default updateTeamMemberAccess;
