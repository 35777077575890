import { makeActionCreator } from "app.actions/helpers";
/******************************************************************************/
/* authentication status check */
/******************************************************************************/
export const AUTHENTICATION_STATUS_CHECK_BEGIN =
  "AUTHENTICATION_STATUS_CHECK_BEGIN";

export const AUTHENTICATION_STATUS_CHECK_REQUEST =
  "AUTHENTICATION_STATUS_CHECK_REQUEST";

export const AUTHENTICATION_STATUS_CHECK_RECEIVE =
  "AUTHENTICATION_STATUS_CHECK_RECEIVE";

export const checkAuthenticationStatus = makeActionCreator(
  AUTHENTICATION_STATUS_CHECK_BEGIN
);

export const requestAuthenticationStatus = makeActionCreator(
  AUTHENTICATION_STATUS_CHECK_REQUEST
);

export const receiveAuthenticationStatus = makeActionCreator(
  AUTHENTICATION_STATUS_CHECK_RECEIVE
);
