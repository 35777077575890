import { makeActionCreator } from "app.actions/helpers";

export const REMOVE_MANUAL_TRANSFER_LINK_BEGIN =
  "REMOVE_MANUAL_TRANSFER_LINK_BEGIN";
export const REMOVE_MANUAL_TRANSFER_LINK_REQUEST =
  "REMOVE_MANUAL_TRANSFER_LINK_REQUEST";
export const REMOVE_MANUAL_TRANSFER_LINK_RECEIVE =
  "REMOVE_MANUAL_TRANSFER_LINK_RECEIVE";
export const REMOVE_MANUAL_TRANSFER_LINK_ERROR =
  "REMOVE_MANUAL_TRANSFER_LINK_ERROR";

export const removeManualTransferLink = makeActionCreator(
  REMOVE_MANUAL_TRANSFER_LINK_BEGIN,
  "itemId",
  "isTimeline",
);

export const requestRemoveManualTransferLink = makeActionCreator(
  REMOVE_MANUAL_TRANSFER_LINK_REQUEST,
  "nextStatus",
);

export const receiveRemoveManualTransferLink = makeActionCreator(
  REMOVE_MANUAL_TRANSFER_LINK_RECEIVE,
  "nextStatus",
);

export const errorRemoveManualTransferLink = makeActionCreator(
  REMOVE_MANUAL_TRANSFER_LINK_ERROR,
  "message",
  "nextStatus",
);
