import { put } from "redux-saga/effects";
import * as organizationActions from "app.reducers/organizations";
import { stopRefreshingUserToken } from "app.actions/authentication";
import { reinitializePortfolio } from "app.reducers/portfolios";
import { STATUS } from "app.constants";

function* unassumeProfile(action) {
  yield put(stopRefreshingUserToken());
  yield put(organizationActions.unassumeProfileRequest(STATUS.WORKING));
  yield put(reinitializePortfolio());
  yield put(organizationActions.unassumeProfileReceive(STATUS.LOADED));
}

export default unassumeProfile;
