import { call, put, all, delay } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";
import {
  dismissModal,
  startModalWorking,
  endModalWorking,
} from "app.reducers/ui";
import {
  requestBulkDeleteExchangeLedgersByIds,
  receiveBulkDeleteExchangeLedgersByIds,
  fetchLedgers,
} from "app.actions/exchanges";
import { addFlashMessage, removeFlashMessage } from "app.reducers/ui";
import { generateGUID } from "app.utils";
import {
  STATUS_DANGER,
  STATUS_SUCCESS,
} from "app.components/Messages/FlashMessage";

import { ONE_MINUTE } from "app.constants";

function* bulkDeleteExchangeLedgersByIds(action) {
  const { ledgerIds } = action;

  yield all([
    put(startModalWorking()),
    put(requestBulkDeleteExchangeLedgersByIds()),
  ]);

  const response = yield call(
    ExchangeLedgerAPI.bulkDeleteExchangeLedgers,
    ledgerIds
  );

  if (response.error) {
    const messageId = generateGUID();
    yield all([
      put(endModalWorking()),
      put(dismissModal()),
      put(
        addFlashMessage(
          "There has been an error deleting your exchange ledgers",
          messageId,
          STATUS_DANGER
        )
      ),
    ]);
    yield delay(ONE_MINUTE);
    yield put(removeFlashMessage(messageId));
  } else {
    yield all([
      put(receiveBulkDeleteExchangeLedgersByIds(STATUS_SUCCESS, ledgerIds)),
      put(endModalWorking()),
      put(dismissModal()),
      put(fetchLedgers()),
    ]);
  }
}

export default bulkDeleteExchangeLedgersByIds;
