import React from "react";

import HeaderLogo from "app.components/Header/HeaderLogo";
import PlaceholderLine from "app.components/Placeholder/PlaceholderLine";
import NavigationBarPlaceholder from "app.components/Placeholder/NavigationBarPlaceholder";

import { Route, Routes } from "react-router-dom";

const HeaderPlaceholder = () => (
  <React.Fragment>
    <header
      data-testid="placeholder-header"
      className="c_header_placeholder bg-header-primary relative z-50 flex h-16 justify-between lg:justify-start"
    >
      <HeaderLogo />
      <div className="ml-3.5 hidden flex-1 items-center md:flex"></div>
      <div className="flex-initial">
        <span className="flex h-16 items-center px-4">
          <PlaceholderLine width="w-10 md:w-64" color="bg-header-dark" />
        </span>
      </div>
    </header>
    <Routes>
      <Route path="portfolio/" index element={null} />
      <Route path="portfolio/new" index element={null} />
      <Route path="portfolio/timeline" index element={null} />
      <Route path="portfolio/smart" index element={null} />
      <Route path="portfolio/reports" index element={null} />
      <Route path="portfolio/*" element={<NavigationBarPlaceholder />} />
      <Route path="*" element={null} />
    </Routes>
  </React.Fragment>
);

HeaderPlaceholder.displayName = "HeaderPlaceholder";

export default HeaderPlaceholder;
