import formatResponse from "./formatResponse";
import formatApiError from "./formatApiError";
import handleResponseError from "./handleResponseError";
import handleMaintenanceError from "./handleMaintenanceError";
import makeRequest from "./makeRequest";
import { logRequest } from "app.api/core/requestLogger";

import logout from "app.utils/logout";
import { isLoaded } from "app.utils";

import { unassumeProfile } from "app.reducers/organizations";

import { store } from "app.config/configureStore";

import { doRedirect } from "app.reducers/ui";

import { getNewTicket, getTokenFromTicket } from "./util";

const METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

// self contained reauth flow -
const reauthenticateAndRetry = async (request, config) => {
  try {
    // this call gets a new ticket from SSO
    const profileId =
      typeof config.getAssumedProfile !== "undefined"
        ? config.getAssumedProfile()
        : undefined;

    const ticket = await getNewTicket(config.newTicketURL);
    // the ticket above is used to get a new JWT, access_token
    const body = await getTokenFromTicket(
      ticket,
      config.refreshTokenUrl,
      profileId
    );

    // then token is written to local storage and the redux store
    config.setBearerToken(body);

    // replay the request with the new token from localStorage for consistency
    request.headers.set("Authorization", `Bearer ${config.getBearerToken()}`);
    return await fetch(request);
  } catch (e) {
    // this is likely a network error because the session
    // expired and the network connection is asleep
    // regardless, there is no server response to handle here.
    return undefined;
  }
};

// make the fetch call, if it gives a 401, reauth and retry
async function sendRequest(request, config) {
  // we need to clone this request before it is consumed by a fetch call
  // so that if the first call fails we can replay it. if we don't clone it
  // the second call may fail trying to
  const requestCopy = request.clone();
  // first try
  let response;
  try {
    response = await fetch(request);
  } catch (e) {
    // we should only end up here if there was a network error when making a
    // request, requests that POLL the API, should probably fail silently, until
    // eventually they try again
    return formatApiError();
  }

  // if the response is a 401, we need to re-auth and retry the request
  // if there is no response, reauth and retry, perhaps the cookie is expired.
  if (!response || response.status === 401) {
    response = await reauthenticateAndRetry(requestCopy, config);
  }

  if (typeof response !== "undefined") {
    // we store the last 10 requests which can then be sent to the crash reporter
    // in the event of an unexpected crash
    logRequest(request.url, response?.status, request.method);
    // return the response, 200 range, or error
    if (response.status >= 200 && response.status <= 299) {
      return formatResponse(response);
    } else {
      let data = {};
      try {
        data = await response.json();
      } catch (e) {
        /* swallow non-json errors*/
      }
      // server error likely means something went wrong in a way that we can't
      // assume can be recovered from
      if (
        parseInt(response.status, 10) >= 500 &&
        parseInt(response.status, 10) <= 599
      ) {
        const globallyHandledResponse = handleResponseError(response);
        return formatApiError(globallyHandledResponse);
      } else {
        // if we received a 401, that means we should be logged out
        if (parseInt(response.status, 10) === 401) {
          logout();
          return;
          // if we received a 403, we might need to redirect to the root URL
        } else if (parseInt(response.status, 10) === 403) {
          const authLoaded = isLoaded(
            store.getState().authentication.authenticationStatus
          );

          if (authLoaded) {
            // These things happen; we only care if this happens
            // after the auth is bootstrapped

            if (data?.message && data.message === "profile_guid_required") {
              // clear out assumed profile and reload into org menu
              store.dispatch(unassumeProfile());
              window.location.assign("/");
            } else {
              // not assuming a user - just go back to the root
              store.dispatch(doRedirect("/"));
              return;
            }
          }
          // if we get an API error (400) that we should handle (e.g invalid data)
        } else if (parseInt(response.status, 10) === 400) {
          // certain errors should be handled globally
          // such as if data faucet is down
          handleMaintenanceError(response, data);
        }
        return formatApiError(response, data);
      }
    }
  } else {
    // we really shouldn't get here, most likely we will have had an expired
    // session and have been redirected to the log in screen
    // we toss a placeholder error here so the saga can run the error state
    // in the event it makes it back there
    return { error: "error" };
  }
}

// to create an API you must send in a configuration object
// which contains the baseUrl for the
export default function configureApi(config) {
  // HTTP operations
  function get(path, options) {
    const url = [config.baseUrl, path].join("");
    const request = makeRequest(METHODS.GET, url, options, config);
    return sendRequest(request, config);
  }

  function del(path, options) {
    const url = [config.baseUrl, path].join("");
    const request = makeRequest(METHODS.DELETE, url, options, config);
    return sendRequest(request, config);
  }

  function post(path, options) {
    const url = [config.baseUrl, path].join("");
    const request = makeRequest(METHODS.POST, url, options, config);
    return sendRequest(request, config);
  }
  function put(path, options) {
    const url = [config.baseUrl, path].join("");
    const request = makeRequest(METHODS.PUT, url, options, config);
    return sendRequest(request, config);
  }

  return {
    get: get,
    delete: del,
    post: post,
    put: put,
  };
}
