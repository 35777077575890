import { call, put, all } from "redux-saga/effects";
import TellerAPI from "app.api/TellerAPI";
import {
  receiveCancelSubscription,
  enterSubscriptionSection
} from "app.actions/account";
import { dismissModal } from "app.reducers/ui";

function* cancelSubscription(action) {
  const { subscriptionId, planId } = action;

  const response = yield call(
    TellerAPI.removeCustomerSubscription,
    subscriptionId,
    planId
  );

  if (response.error) {
    console.log(response.error);
  } else {
    yield put(enterSubscriptionSection());
    yield all([
      put(dismissModal()),
      put(receiveCancelSubscription()) //,
      //put(enterSubscriptionSection())
    ]);
  }
}

export default cancelSubscription;
