let sessionStorageTestResult;

export const testSessionStorage = function () {
  const latest = "node40-ls-test";

  if (typeof sessionStorageTestResult === "undefined") {
    try {
      sessionStorage.setItem(latest, latest);
      sessionStorage.removeItem(latest);
      sessionStorageTestResult = true;
      return sessionStorageTestResult;
    } catch (e) {
      sessionStorageTestResult = false;
      return sessionStorageTestResult;
    }
  } else {
    return sessionStorageTestResult;
  }
};
