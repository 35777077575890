import React, { useReducer, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updatePassword } from "app.actions/account";
import { ACCOUNT_STATUS } from "app.constants/loadingStates";
import { useIsPasswordUpdateFormValid } from "app.hooks";

import ErrorMessage from "app.components/Util/ErrorMessage";
import ModalControls from "app.components/Modal/ModalControls";
import { dismissModal } from "app.reducers/ui";
import { Input, Label, PasswordInput } from "app.components/Util/Form";
import Button from "app.components/Util/Button";
import { useTranslation } from "react-i18next";
import { PASSWORD_MIN_LENGTH } from "app.constants";

// actions
const UPDATE_FIELD = "update_field";
const CLEAR = "clear";

const initialState = {
  totpToken: "",
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

function updatePasswordReducer(state, action) {
  switch (action.type) {
    case UPDATE_FIELD:
      return {
        ...state,
        [action.name]: action.value,
      };
    case CLEAR:
      return { ...initialState };
    default:
      throw new Error();
  }
}

function ModalPasswordSettings() {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();

  const [state, localDispatch] = useReducer(
    updatePasswordReducer,
    initialState
  );
  const { passwordStatus, passwordError, userProfile } = useSelector(
    (state) => state.account
  );
  const { mfaEnabled } = userProfile;

  const { currentPassword, newPassword, confirmNewPassword, totpToken } = state;

  const { blockSubmit } = useIsPasswordUpdateFormValid(
    currentPassword,
    newPassword,
    confirmNewPassword,
    totpToken
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (blockSubmit) return;
    reduxDispatch(
      updatePassword(
        currentPassword,
        newPassword,
        confirmNewPassword,
        totpToken
      )
    );
  };

  useEffect(() => {
    if (passwordStatus === ACCOUNT_STATUS.UPDATED) {
      reduxDispatch(dismissModal());
    }
  }, [passwordStatus, reduxDispatch]);

  const handleUpdate = (name, value) => {
    localDispatch({
      type: UPDATE_FIELD,
      name: name,
      value: value,
    });
  };

  const handleClear = (e) => {
    e.preventDefault();
    localDispatch({
      type: CLEAR,
    });
  };

  const passwordUpdating = passwordStatus === ACCOUNT_STATUS.UPDATING;

  return (
    <form onSubmit={handleSubmit}>
      <div className="mx-4">
        <ErrorMessage message={passwordError} type={passwordError} />
        <PasswordInput
          className="mt-4"
          currentPassword={{
            value: currentPassword,
            label: t("input.label.currentPassword"),
            callback: handleUpdate,
          }}
          newPassword={{
            value: newPassword,
            label: t("input.label.newPassword"),
            labelDescription: t(
              "account.password.passwordMustBeAtLeastCharacters",
              { num: PASSWORD_MIN_LENGTH }
            ),

            callback: handleUpdate,
          }}
          confirmNewPassword={{
            value: confirmNewPassword,
            label: t("input.label.confirmNewPassword"),
            labelDescription: t("account.password.passwordMustMatch"),
            callback: handleUpdate,
          }}
          disabled={passwordUpdating}
        />

        {mfaEnabled ? (
          <div className="mb-4 flex flex-col">
            <Label htmlFor="totpToken">
              {t("input.label.verificationCode")}
            </Label>
            <Input
              value={totpToken}
              type="text"
              data-testid="totpToken"
              id="totpToken"
              name="totpToken"
              className="w-20"
              onChange={(e) => {
                e.preventDefault();
                handleUpdate(e.target.name, e.target.value);
              }}
              disabled={passwordUpdating}
            />
          </div>
        ) : undefined}
      </div>

      <ModalControls>
        <Button
          disabled={blockSubmit || passwordUpdating}
          type="submit"
          buttonType="primary"
          working={passwordUpdating}
          workingText={t("button.changingPassword")}
          customClasses="ml-4"
          text={t("button.changePassword")}
        />
        <Button
          buttonType="text"
          disabled={passwordUpdating}
          onClick={(e) => {
            e.preventDefault();
            reduxDispatch(dismissModal());
            handleClear(e);
          }}
          text={t("button.cancel")}
        />
      </ModalControls>
    </form>
  );
}

ModalPasswordSettings.displayName = "ModalPasswordSettings";

export default ModalPasswordSettings;
