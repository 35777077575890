import React, { Suspense, lazy } from "react";
import GenericPlaceholder from "app.components/Placeholder/GenericPlaceholder";

const InspectorContainer = lazy(() =>
  import("app.components/Inspector/InspectorContainer")
);

const Inspector = () => {
  return (
    <Suspense fallback={<GenericPlaceholder />}>
      <InspectorContainer />
    </Suspense>
  );
};

export default Inspector;
