import React from "react";

import { ASSETS_BASE, GROUP_LOGO } from "app.constants";

const WalletGroupLogo = ({ group, embed = false, name, large = false }) => {
  const logoPath = GROUP_LOGO[group];

  const size = large ? 40 : 32;
  const maxH = large ? "max-h-[40px]" : "max-h-[32px]";

  if (typeof logoPath === "undefined") {
    return null;
  }

  const logoURL = [ASSETS_BASE, logoPath].join("");

  if (embed) {
    return (
      <image alt={name} href={logoURL} width="12" height="12" x="12" y="12" />
    );
  } else {
    return (
      <img
        className={maxH}
        alt={name}
        src={logoURL}
        width={size}
        height={size}
      />
    );
  }
};

WalletGroupLogo.displayName = "WalletGroupLogo";

export default WalletGroupLogo;
