import i18n from "i18next";

export const getLabelWithNickname = (i18nKey, label, nickname, props = {}) => {
  props.label = label;

  if (nickname && nickname !== label) {
    props.nickname = nickname;

    return i18n.t(i18nKey, {
      context: "nickname",
      ...props,
    });
  }

  return i18n.t(i18nKey, { ...props });
};
