import Big from "big.js";

/**
 * Gets a formatted string for the display of a digital currency value.
 *
 * @param {Array} coinList The list of coins
 * @param {number} value The value of the digital currency.
 * @param {string} symbol The digital currency symbol/unit (e.g. BTC)
 * @returns {number} returns a string formatted value showing the correct number of decimal places for the provided currency
 * @example
 *
 * formatCurrencyValue(coinList, 150000000, "BTC")
 * // => "1.50000000"
 */

const trailingZerosRegex = /[0]+$/;
///(?<=.{8})+[0]+$/;

export const formatCurrencyValue = function (value) {
  const isBig = value instanceof Big ? true : false;

  // handle when a value which is not a number is send in
  if (!isBig && (Number.isNaN(value) || !Number.isFinite(value))) {
    return "";
  }

  const bigValue = isBig ? value : new Big(value);
  const isNegative = bigValue.lt(0);

  const valueAsString = bigValue.toFixed();
  const parts = valueAsString.split(".");
  const bigPart = parts[0]
    .replace(/\D/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  let littlePart = parts[1] || "0";

  littlePart =
    littlePart.substring(0, 1) +
    littlePart.substring(1).replace(trailingZerosRegex, "");

  return `${isNegative ? "-" : ""}${bigPart}.${littlePart}`;
};
