import { call, put, all } from "redux-saga/effects";
import TellerAPI from "app.api/TellerAPI";
import {
  receiveCancelDowngrade,
  enterSubscriptionSection,
} from "app.actions/account";
import { dismissModal, startModalWorking } from "app.reducers/ui";

function* cancelDowngrade(action) {
  const { subscriptionId } = action;
  yield put(startModalWorking());
  const response = yield call(
    TellerAPI.cancelCustomerDowngrade,
    subscriptionId
  );

  if (response.error) {
    console.log(response.error);
  } else {
    yield put(enterSubscriptionSection());
    yield all([put(dismissModal()), put(receiveCancelDowngrade())]);
  }
}

export default cancelDowngrade;
