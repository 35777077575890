import { call, put, delay, select } from "redux-saga/effects";

import { STATUS, FIVE_SECONDS } from "app.constants";
import { getLoadingState } from "app.utils/selectors";

import ExchangesAPI from "app.api/ExchangesAPI";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestLedgers,
  ledgersError,
  receiveLedgers,
} from "app.actions/exchanges";

import {
  receiveArchivedLedgers,
  setTimelineStale,
} from "app.actions/exchangeLedger";

import { fetchSmartWalletList } from "app.reducers/smartWallet";

import { fetchTimelineLedgersRange } from "app.reducers/ui";

function* fetchLedgers(action) {
  const oneTime = action?.oneTime || false;

  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.exchanges.ledgersStatus
  );

  if (isLoading) return;

  const { ledgersCreating, ledgersUpdating } = yield select(
    (state) => state.computables
  );

  yield put(requestLedgers(nextStatus));

  const response = yield call(ExchangesAPI.getLedgers);
  const archiveResponse = yield call(ExchangeLedgerAPI.getArchivedLedgers);

  const { body, error } = response;

  if (error) {
    if (body?.serverError === true) {
      yield put(
        ledgersError(
          {
            type: "server_error",
            message: "There was an error accessing your Ledgers.",
          },
          error
        )
      );
    } else {
      yield put(ledgersError(body, error));
    }
  } else {
    yield put(receiveLedgers(body, STATUS.LOADED));
    yield put(receiveArchivedLedgers(archiveResponse.body));

    const anyLedgersBeingCreated = body.filter((x) => x.creationInProgress);
    const anyLedgersBeingUpdated = body.filter((x) => x.importInProgress);
    const anyLedgersValuationBeingCached = body.filter(
      (x) => x.currentPrice === null
    );

    if (
      (ledgersCreating || []).length > anyLedgersBeingCreated.length ||
      (ledgersUpdating || []).length > anyLedgersBeingUpdated.length
    ) {
      yield put(setTimelineStale(true));
      // whenever an import completes - update the available date range
      yield put(fetchTimelineLedgersRange());
      // and fetch smart wallets to ensure UI updates to reflect cost basis
      // is calculating
      yield put(fetchSmartWalletList());
    }

    if (
      oneTime === false &&
      (anyLedgersBeingCreated.length > 0 ||
        anyLedgersBeingUpdated.length > 0 ||
        anyLedgersValuationBeingCached.length > 0)
    ) {
      yield delay(FIVE_SECONDS);
      return yield* fetchLedgers({ ...action });
    }
  }
}

export default fetchLedgers;
