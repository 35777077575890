import { makeActionCreator } from "app.actions/helpers";
/******************************************************************************/
/* import addresses */
/******************************************************************************/
export const ADDRESSES_BY_IMPORT_BEGIN = "ADDRESSES_BY_IMPORT_BEGIN";
export const ADDRESSES_BY_IMPORT_REQUEST = "ADDRESSES_BY_IMPORT_REQUEST";
export const ADDRESSES_BY_IMPORT_RECEIVE = "ADDRESSES_BY_IMPORT_RECEIVE";

export const fetchAddressesByImport = makeActionCreator(
  ADDRESSES_BY_IMPORT_BEGIN,
  "importId",
  "page"
);

export const requestAddressesByImport = makeActionCreator(
  ADDRESSES_BY_IMPORT_REQUEST,
  "importId"
);

export const receiveAddressesByImport = makeActionCreator(
  ADDRESSES_BY_IMPORT_RECEIVE,
  "response",
  "importId"
);
