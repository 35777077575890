import React from "react";
import PlaceholderLine from "app.components/Placeholder/PlaceholderLine";

export const Setting = () => (
  <div className="mb-3">
    <div className="mb-1">
      <PlaceholderLine width="w-20" />
    </div>
    <PlaceholderLine width="w-1/2" />
  </div>
);

export const SettingsGroupPlaceholder = ({ noBorder, settingLines = 4 }) => {
  const settings = new Array(settingLines)
    .fill(0)
    .map((x, index) => <Setting key={index} />);

  const className = [
    "md:flex",
    noBorder !== true ? "" : "border-b",
    "pb-4",
    "mb-4",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div className={className}>
      <div className="mb-4 pb-4 md:basis-52">
        <PlaceholderLine width="w-14" />
      </div>
      <div className="flex-1">
        {settings}
        <div className="mt-3 flex justify-end">
          <PlaceholderLine width="w-24" />
        </div>
      </div>
    </div>
  );
};

const AccountPlaceholder = ({ groups }) => {
  const settingsGroups = new Array(groups ?? 3)
    .fill(0)
    .map((x, index) => (
      <SettingsGroupPlaceholder key={index} noBorder={index > 0} />
    ));

  return (
    <div className="my-5 px-4" data-testid="account-loading-placeholder">
      <div className="pb-4 text-2xl">
        <PlaceholderLine width="w-48" />
      </div>
      <div className="rounded bg-white px-4 py-4 shadow">{settingsGroups}</div>
    </div>
  );
};

AccountPlaceholder.displayName = "AccountPlaceholder";

export default AccountPlaceholder;
