import React from "react";
import CashMoney from "app.components/Util/CashMoney";
import TableListCell from "app.components/TableList/TableListCell";
import { useTranslation, Trans } from "react-i18next";

function TaxRow({ isUpgradeQuoteLoaded, taxPercent, taxDollars, zipCode }) {
  const { t } = useTranslation();

  return (
    <tr data-testid="tax-row">
      <TableListCell custom="pl-0" size="sm">
        {taxPercent === 0 || !isUpgradeQuoteLoaded ? (
          t("modals.salesTax")
        ) : (
          <Trans i18nKey="modals.salesTaxWithPercent">
            <small>{{ tax: taxPercent.toFixed(2) }}</small>
          </Trans>
        )}
      </TableListCell>
      <TableListCell custom="pr-0" size="sm" right>
        {zipCode?.trim() === "" ? (
          <small>{t("modals.calculatedAtNextStep")}</small>
        ) : !isUpgradeQuoteLoaded ? (
          <small>{t("modals.calculating")}</small>
        ) : (
          <CashMoney
            size="none"
            align="right"
            dollars={taxDollars}
            fiatOverride="USD"
          />
        )}
      </TableListCell>
    </tr>
  );
}

export default TaxRow;
