import { call, put } from "redux-saga/effects";
import AuthAPI from "app.api/AuthAPI";
import logout from "app.utils/logout";
import { receiveAuthorizeOrganization } from "app.reducers/organizations";

function* refreshOrganizationToken(action) {
  try {
    const ticket = yield call(AuthAPI.getTicket);
    const response = yield call(AuthAPI.getTokenFromTicket, ticket);

    if (response.error) {
      // Bubble up so we can just go to login
      throw response.error;
    } else {
      const { body } = response;

      yield put(receiveAuthorizeOrganization(body.access_token, body));
    }
  } catch (err) {
    //console.log("Error refreshing token", err);
    logout();
  }
}

export default refreshOrganizationToken;
