import { call, put } from "redux-saga/effects";
import * as filesActions from "app.actions/files";
import FilesAPI from "app.api/FilesAPI";

function* downloadFile(action) {
  const { documentGUID } = action;

  yield put(filesActions.requestDownloadFile(documentGUID));

  const response = yield call(FilesAPI.downloadFile, documentGUID);

  if (response.error) {
    console.log("Error in generateFile", response.body, response.error);
    yield put(filesActions.downloadFileError(response.body));
  } else {
    // trigger the download
    yield call(() => {
      let downloadLink = document.createElement("a");
      downloadLink.setAttribute("href", response.body);
      downloadLink.setAttribute("download", "");
      downloadLink.setAttribute("target", "_blank");
      downloadLink.click();
      downloadLink = null;
    });
    yield put(filesActions.receiveDownloadFile(documentGUID));
  }
}

export default downloadFile;
