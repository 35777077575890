import { select, fork, cancel, take } from "redux-saga/effects";
import { selectDateRange } from "app.reducers/ui";
import { leaveSmartWalletSection } from "app.reducers/smartWallet";
import fetchSmartWalletRange from "./fetchSmartWalletRange";
import fetchSmartWalletSummary from "./fetchSmartWalletSummary";

function* enterSmartWalletSection(action) {
  const smartWalletGUID = action.payload;

  const dateRangeSync = yield fork(syncData, smartWalletGUID);

  yield* fetchSmartWalletRange(action);

  yield take(leaveSmartWalletSection);

  yield cancel(dateRangeSync);
}

function* syncData(smartWalletGUID) {
  while (true) {
    const { selectedDateRange } = yield select((state) => state.ui);

    yield* fetchSmartWalletSummary({
      smartWalletGUID,
      range: selectedDateRange,
    });

    yield take(selectDateRange);
  }
}

export default enterSmartWalletSection;
