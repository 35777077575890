import { WALLET, SOURCELESS, ETH_ADDRESS_SHADOW } from "app.constants";

export function getLinkedExchangeByGUID(linkedExchanges, linkGUID) {
  // if linkGUID is undefined, then there is just one exchange and
  // its probably a file upload
  if (typeof linkGUID === "undefined") {
    return undefined;
  }

  for (let i in linkedExchanges) {
    for (let j in linkedExchanges[i].linked) {
      if (linkedExchanges[i].linked[j].linkGUID === linkGUID) {
        return linkedExchanges[i].linked[j];
      }
    }
  }

  return undefined;
}

export function isSourceSyncing(ledgersBySource) {
  const ledgers = ledgersBySource.ledgers;
  for (let i in ledgers) {
    if (
      ledgers[i].ledger.importInProgress === true ||
      ledgers[i].ledger.creationInProgress
    ) {
      return true;
    }
  }
  return false;
}

export function getSourceType(source, supportedExchanges) {
  if (source === WALLET) {
    return WALLET;
  }
  if (source === SOURCELESS) {
    return SOURCELESS;
  }
  if (source === ETH_ADDRESS_SHADOW) {
    return ETH_ADDRESS_SHADOW;
  }
  const exchange = supportedExchanges.find((se) => se.name === source);
  return exchange?.integrationType;
}
