import React from "react";
import { useTranslation } from "react-i18next";
import { useGetUserLocale } from "app.hooks";

const PaginationShowing = ({
  pageStart, // 0 indexed page number
  visibleRecordCount,
  totalRecordCount,
  label = "rows",
}) => {
  const { t } = useTranslation();
  const locale = useGetUserLocale();

  return (
    <p className="flex-1 text-xs text-zinc-500">
      {t("common.paginationShowing", {
        from: (pageStart + 1).toLocaleString(locale),
        to: (pageStart + visibleRecordCount).toLocaleString(locale),
        of: totalRecordCount.toLocaleString(locale),
        label: label,
      })}
    </p>
  );
};

PaginationShowing.displayName = "PaginationShowing";

export default PaginationShowing;
