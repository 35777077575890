/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { connectExchangeOAuth } from "app.actions/exchanges";
import { APP_NAME, COMPANY_NAME } from "app.constants";
import Button from "app.components/Util/Button";
import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";

const ModalExchangeOauthPopUp = ({
  exchange,
  dismiss,
  reLink,
  alternateExchange,
  alternateExchangeCallback,
  oauthRequiredCallback,
  pathname,
  autoImportPathname,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const reduxDispatch = useDispatch();

  const [autoImport, setAutoImport] = useState(true);

  const startConnectExchange = (exchange) => {
    const path = autoImport ? autoImportPathname : pathname;
    reduxDispatch(connectExchangeOAuth(exchange.id, path || location.pathname));
  };

  const handleCheckboxChange = (e) => {
    setAutoImport(e.target.checked);
  };

  const label = exchange ? exchange.label : "";
  let alternateExchangeBody = null;

  if (typeof alternateExchange !== "undefined") {
    alternateExchangeBody = (
      <React.Fragment>
        <p className="mx-4 text-sm">
          <Trans i18nKey="modals.youCanUploadYourTransactionHistory">
            <a
              href="#upload"
              className="text-linkBlue text-linkBlueActive hover:underline"
              data-testid="alterate-exchange-link"
              onClick={(e) => {
                e.preventDefault();
                alternateExchangeCallback(alternateExchange);
                dismiss();
              }}
            ></a>
            {{ label: exchange.label }}
          </Trans>
        </p>
      </React.Fragment>
    );
  }
  const title = t(
    reLink === true ? "modals.linkToLabel" : "modals.connectWithLabel",
    {
      label,
    }
  );
  return (
    <div>
      <ModalHeader
        title={title}
        closeCallback={(e) => {
          e.preventDefault();
          if (oauthRequiredCallback) {
            oauthRequiredCallback();
          }
          dismiss();
        }}
      />

      <div className="m-4">
        {reLink === true ? (
          <p className="text-lg">
            {t("modals.yourConnectionToLabelHasExpiredOrUnlinked", {
              label,
            })}
          </p>
        ) : (
          <React.Fragment>
            <p className="mb-4 text-lg">
              <Trans i18nKey="modals.toContinueAuthorizeAppToViewYourTransactions">
                <strong>{{ label: exchange.label }}</strong>
                {{ appName: APP_NAME }}
              </Trans>
            </p>
            <p className="text-sm">
              {t("modals.youWillBeRedirectedToLabelToAuthorizeApp", {
                label: exchange.label,
                appName: APP_NAME,
                company: COMPANY_NAME,
              })}
            </p>
          </React.Fragment>
        )}
        <label htmlFor="autoImport" className="my-4 flex items-center text-sm">
          <input
            type="checkbox"
            data-testid="autoImport"
            id="autoImport"
            name="autoImport"
            checked={autoImport}
            onChange={handleCheckboxChange}
          />
          <span className="ml-2">{t("input.label.autoImport")}</span>
        </label>
      </div>
      {alternateExchangeBody}
      <ModalControls>
        <Button
          buttonType="primary"
          disabled={typeof exchange === "undefined"}
          onClick={(e) => {
            e.preventDefault();
            startConnectExchange(exchange);
          }}
          text={t("button.authorize")}
          customClasses="ml-4"
        />
        <Button
          buttonType="text"
          onClick={(e) => {
            e.preventDefault();
            if (oauthRequiredCallback) {
              oauthRequiredCallback();
            }
            dismiss();
          }}
          text={t("button.cancel")}
        />
      </ModalControls>
    </div>
  );
};

ModalExchangeOauthPopUp.displayName = "ModalExchangeOauthPopUp";

export default ModalExchangeOauthPopUp;
