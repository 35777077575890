import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { dismissModal, openModal } from "app.reducers/ui";

import {
  MODAL_CONFIRM_DIALOG,
  MODAL_SELECTIVE_ARCHIVE_LEDGERS,
} from "app.constants/modals";
import { CANCEL_TYPES } from "app.constants";
import { ARCHIVE_LEDGER_BEGIN } from "app.actions/exchangeLedger";

import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";
import Button from "app.components/Util/Button";
import SelectableLedgersList from "app.components/Portfolio/SelectableLedgersList";
import { getLabelWithNickname } from "app.utils";

const ModalSelectiveArchive = ({
  exchange,
  nickname,
  ledgers,
  linkGUID,
  preSelectedLedgers,
}) => {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();

  const { sourcesImporting } = useSelector((state) => state.computables);
  const isSourceImporting = typeof sourcesImporting[linkGUID] !== "undefined";
  const [selectedLedgers, setSelectedLedgers] = useState(
    preSelectedLedgers || []
  );

  const archiveLedgers = () => {
    const message = t(
      selectedLedgers.length === ledgers.length
        ? "portfolio.areYouSureYouWantToArchiveAllLedgers"
        : "portfolio.areYouSureYouWantToArchiveLedgers",
      {
        amount: selectedLedgers.length,
        label: exchange,
      }
    );

    const callbackAction = {
      type: ARCHIVE_LEDGER_BEGIN,
      ledgerId:
        selectedLedgers.length === 1 ? selectedLedgers[0] : selectedLedgers,
      userArchive: true,
    };

    const callbackCancel = {
      type: openModal,
      modalBody: MODAL_SELECTIVE_ARCHIVE_LEDGERS,
      modalTitle: undefined,
      params: {
        exchange,
        nickname,
        ledgers,
        linkGUID,
        preSelectedLedgers: selectedLedgers,
      },
    };

    reduxDispatch(
      openModal(MODAL_CONFIRM_DIALOG, null, {
        message: message,
        okText: t("button.archiveLedgers"),
        okType: CANCEL_TYPES.DANGER,
        cancelText: t("common.cancel"),
        accidentPrevention: true,
        accidentPreventionMessage: t(
          "portfolio.archive.archiveLedgerPreventionMessage"
        ),
        callbackAction: callbackAction,
        callbackCancel: callbackCancel,
      })
    );
  };

  return (
    <React.Fragment>
      <ModalHeader
        selectiveSync
        title={t("portfolio.selectiveArchive")}
        closeCallback={(e) => {
          e.preventDefault();
          reduxDispatch(dismissModal());
        }}
      />

      <div className="px-6">
        <div className="mb-2">
          {getLabelWithNickname(
            "portfolio.selectTheLedgersYouWishToArchive",
            exchange,
            nickname
          )}
        </div>

        <SelectableLedgersList
          ledgers={ledgers}
          selectedLedgers={selectedLedgers}
          setSelectedLedgers={setSelectedLedgers}
        />
      </div>

      <ModalControls>
        <Button
          type="submit"
          data-tabbable={true}
          buttonType="primary"
          customClasses="ml-4"
          onClick={(e) => {
            e.preventDefault();
            archiveLedgers();
          }}
          disabled={!selectedLedgers.length || isSourceImporting}
          text={t("button.archiveLedgers_withEllipsis")}
        />
        <Button
          onClick={(e) => {
            e.preventDefault();
            reduxDispatch(dismissModal());
          }}
          data-tabbable={true}
          buttonType="text"
          text={t("button.close")}
        />
      </ModalControls>
    </React.Fragment>
  );
};

ModalSelectiveArchive.displayName = "ModalSelectiveArchive";

export default ModalSelectiveArchive;
