export const parseCookie = cookieString => {
  return cookieString
    .split(";")
    .map(function(c) {
      return c
        .trim()
        .split("=")
        .map(decodeURIComponent);
    })
    .reduce(function(cookieProperties, currentCookieKVP) {
      try {
        cookieProperties[currentCookieKVP[0]] = JSON.parse(currentCookieKVP[1]);
      } catch (e) {
        cookieProperties[currentCookieKVP[0]] = currentCookieKVP[1];
      }
      return cookieProperties;
    }, {});
};
