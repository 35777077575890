import { call, put, all, select, delay } from "redux-saga/effects";
import i18n from "i18next";

import {
  requestUpdateExchangeLedgersViaAddressGroupings,
  receiveUpdateExchangeLedgersViaAddressGroupings,
  updateExchangeLedgersViaAddressGroupingsError,
  fetchLedgers,
} from "app.actions/exchanges";

import { fetchExchangeLedgerRange } from "app.actions/exchangeLedger";

import {
  addFlashMessage,
  removeFlashMessage,
  dismissModal,
} from "app.reducers/ui";

import ExchangesAPI from "app.api/ExchangesAPI";

import {
  STATUS_SUCCESS,
  STATUS_WARNING,
} from "app.components/Messages/FlashMessage";

import { FIVE_SECONDS, TEN_SECONDS } from "app.constants";

import { MESSAGE_TYPES } from "app.components/Messages/MessageGenerator";

import { generateGUID } from "app.utils";

function* updateExchangeLedgersViaAddressGroupings(action) {
  const { addressGroupings, exchangeId, ledgerIds } = action;

  yield put(requestUpdateExchangeLedgersViaAddressGroupings());

  const response = yield call(
    ExchangesAPI.updateDashCoreLedger,
    exchangeId,
    ledgerIds,
    addressGroupings
  );

  const { body, error } = response;

  if (error) {
    yield put(updateExchangeLedgersViaAddressGroupingsError(body, error));
  } else {
    if (body.importResults.length === 0) {
      // failure
      yield put(updateExchangeLedgersViaAddressGroupingsError(body));
    } else {
      yield all([
        put(receiveUpdateExchangeLedgersViaAddressGroupings(body, exchangeId)),
        put(fetchLedgers()),
        put(dismissModal()),
      ]);

      // if we are currently looking at a ledger that was just updated
      const currentLedgerSummary = yield select(
        (state) => state.exchangeLedger.ledgerSummary
      );

      if (typeof currentLedgerSummary !== "undefined") {
        const found = ledgerIds.findIndex(
          (item) => parseInt(item, 10) === currentLedgerSummary.ledgerId
        );
        if (found !== -1) {
          yield put(fetchExchangeLedgerRange(currentLedgerSummary.ledgerId));
        }
      }

      // report to the UI
      const ledgers = yield select((state) => state.exchanges.ledgers);

      if (body.importResults.length > 0) {
        const importMessageData = body.importResults.map((importResult) => {
          const messageId = generateGUID();
          if (body.importResults[0].asyncImportInProgress) {
            return {
              // eslint-disable-next-line redux-saga/yield-effects
              removeFlashMessageAction: put(removeFlashMessage(messageId)),
              // eslint-disable-next-line redux-saga/yield-effects
              putAction: put(
                addFlashMessage(
                  `Your ledger is being updated. We will email you when it is done.`,
                  messageId,
                  STATUS_SUCCESS
                )
              ),
            };
          } else {
            const message = {
              type: MESSAGE_TYPES.LEDGER_CREATED,
              ledgers: ledgers,
              importResult: importResult,
            };
            return {
              // eslint-disable-next-line redux-saga/yield-effects
              removeFlashMessageAction: put(removeFlashMessage(messageId)),
              // eslint-disable-next-line redux-saga/yield-effects
              putAction: put(
                addFlashMessage(message, messageId, STATUS_SUCCESS)
              ),
            };
          }
        });

        yield all(importMessageData.map((x) => x.putAction));
        yield delay(TEN_SECONDS);
        yield all(importMessageData.map((x) => x.removeFlashMessageAction));
      } else if (body.importResults.length === 0) {
        const messageId = generateGUID();
        yield put(
          addFlashMessage(
            i18n.t("messages.noTransactionsFoundMessage"),
            messageId,
            STATUS_WARNING
          )
        );

        yield delay(FIVE_SECONDS);
        yield put(removeFlashMessage(messageId));
      }
    }
  }
}

export default updateExchangeLedgersViaAddressGroupings;
