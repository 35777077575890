import {
  COMPONENT_ERROR,
  WINDOW_ERROR,
  SAGA_ERROR,
  BALANCE_SERVICE_ID,
  TELLER_BASE,
} from "app.constants";
import { getDebugEntries } from "app.api/core/requestLogger";

const REPORT_URL = `${TELLER_BASE}/error`;

const LOG_ENVIRONMENT_NAME = (() => {
  if (
    import.meta.env.VITE_NODE_ENV === "production" &&
    import.meta.env.VITE_NODE40_ENV === "production"
  )
    return "PRODUCTION";

  if (
    import.meta.env.VITE_NODE_ENV === "production" &&
    import.meta.env.VITE_NODE40_ENV === "development"
  )
    return "TEST";

  return "DEVELOPMENT";
})();

const IS_DEV =
  import.meta.env.VITE_NODE_ENV === "development" &&
  import.meta.env.VITE_NODE40_ENV === "development";

const BATCH = [];
const CAPTURE_WINDOW_MS = 1000;

let timeout = undefined;

const RANKINGS = { [WINDOW_ERROR]: 1, [COMPONENT_ERROR]: 2, [SAGA_ERROR]: 3 };

function sendReport(report) {
  return fetch(REPORT_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(report),
  });
}

const sortByRanking = (left, right) => {
  return RANKINGS[right.source] - RANKINGS[left.source];
};

// squash "identical errors" (i.e., component level bubbles to window, we want the most specific error source)
const squashReports = (batch) => {
  return batch
    .reduce((errorsSoFar, curr) => {
      const found = errorsSoFar.filter((x) => x[0] === curr.error)[0];

      if (found) {
        found[1].push(curr);
        found[1].sort(sortByRanking);
      } else {
        errorsSoFar.push([curr.error, [curr]]);
      }

      return errorsSoFar;
    }, [])
    .map((x) => x[1][0].logEntry);
};

const debounceFiling = () => {
  if (typeof timeout !== "undefined") {
    window.clearTimeout(timeout);
    timeout = undefined;
  }

  timeout = window.setTimeout(async () => {
    // Capture all errors in window
    const baseErrors = BATCH.splice(0);
    const errors = squashReports(baseErrors);

    timeout = undefined;

    try {
      while (errors.length > 0) {
        const error = errors.pop();
        await sendReport(error);
      }
    } catch (e) {
      console.log("error submitting errors", e);
    }
  }, CAPTURE_WINDOW_MS);
};

export const crashReporter = async (
  source,
  error,
  profileGUID,
  username,
  additionalData = {}
) => {
  try {
    if (IS_DEV) {
      //console.log("DEVEOPMENT MODE: Crash Reporting has been disabled.");
      //console.error(error);
      return;
    }

    const location = window.location.href;
    const logEntries = getDebugEntries()
      .map((x) => `${x.method} ${x.url} ${x.status}`)
      .join("\n");
    const stack = error.stack || "";
    const errorText = `
<h3>Location: <code>${location}</code></h3>
<h4>Logged In User: <code> ${username}</code></h4>

${stack.split("\n")}

<h3>Last API Calls Logged:</h3>
${logEntries}:
${
  additionalData
    ? `<h3>Additional Errors</h3>
<pre>
${JSON.stringify(additionalData, null, 4)}
</pre>`
    : ``
}`;

    const logEntry = {
      message: error.message,
      profileGUID: profileGUID,
      username: username,
      environment: LOG_ENVIRONMENT_NAME,
      appId: BALANCE_SERVICE_ID,
      codeLocation: source,
      error: errorText,
    };

    BATCH.push({
      error,
      logEntry,
    });

    debounceFiling();
  } catch (error) {
    console.log(error);
  }
};

export default crashReporter;
