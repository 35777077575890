import { call, put } from "redux-saga/effects";
import TellerAPI from "app.api/TellerAPI";
import {
  requestSupportedFiats,
  receiveSupportedFiats,
} from "app.actions/account";
import { ACCOUNT_STATUS } from "app.constants";

function* fetchSupportedFiats() {
  const response = yield call(TellerAPI.getSupportedFiats);

  yield put(requestSupportedFiats(ACCOUNT_STATUS.LOADING));

  if (response.error) {
    console.log("error in fetch fiats");
    //yield put(receiveSupportedFiatsError(response.body.message));
  } else {
    yield put(receiveSupportedFiats(response.body, ACCOUNT_STATUS.LOADED));
  }
}

export default fetchSupportedFiats;
