import { call, put, all } from "redux-saga/effects";
import { getLoadingState } from "app.utils/selectors";
import {
  requestDisableLikeKind,
  disableLikeKindError,
  receiveDisableLikeKind,
  fetchSettings,
} from "app.actions/exchanges";
import { dismissModal, endModalWorking } from "app.reducers/ui";
import SettingsAPI from "app.api/SettingsAPI";

import { STATUS } from "app.constants";

function* disableLikeKind(action) {
  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.exchanges.likeKindStatus
  );

  if (isLoading) return;

  yield put(requestDisableLikeKind(nextStatus));
  // this API call could take a while, so I don't wan to lock
  // the user into the modal
  yield put(dismissModal());

  const response = yield call(SettingsAPI.disableLikeKind);

  if (response.error) {
    yield all([
      put(disableLikeKindError(response.body, response.error, STATUS.ERROR)),
      put(endModalWorking()),
    ]);
  } else {
    yield all([
      put(receiveDisableLikeKind(STATUS.UNINITIALIZED)),
      put(fetchSettings()),
    ]);
  }
}

export default disableLikeKind;
