import { call, put, select } from "redux-saga/effects";

import { receiveAuthorizeOrganization } from "app.reducers/organizations";

import logout from "app.utils/logout";

import { requestAuthorizeUser, receiveAuthorizeUser } from "app.reducers/user";

import { canManageOrganizationProfiles } from "app.utils/selectors";

import AuthAPI from "app.api/AuthAPI";

function* getTokenFromTicket(action) {
  const { ticket, redirectTo } = action.payload;

  yield put(requestAuthorizeUser());

  const currentlyAssumedProfile = yield select(
    (state) => state.organizations.currentlyAssumedProfile
  );

  const response = yield call(
    AuthAPI.getTokenFromTicket,
    ticket,
    currentlyAssumedProfile
  );

  if (response) {
    if (response.error) {
      console.log("Error", response.error);
      //console.log("getting token auto-refresh failed...", response.error);
      logout();
    } else {
      const { body } = response;
      const { organizationAccessLevel, access_token } = body;

      // Do not put these in a yield all call; we have to consume the receive auth call
      // before we can navigate or we crash the app. so there's that
      if (
        organizationAccessLevel &&
        canManageOrganizationProfiles(organizationAccessLevel)
      ) {
        yield put(receiveAuthorizeOrganization(access_token, body));
        window.location.replace("/organization");
      } else {
        yield put(receiveAuthorizeUser(access_token));
        window.location.replace(redirectTo || "/");
      }
    }
  }
}

export default getTokenFromTicket;
