import React from "react";
import SvgLoader from "app.components/Util/SvgLoader";

const HelpBanner = ({ children, text }) => {
  return (
    <div className="py-2">
      <div className="rounded bg-blue-50 p-3 shadow">
        <div className="flex items-center gap-2">
          <div className="h-5 w-5">
            <SvgLoader id="CircleInfo" className="h-6 w-6 fill-blue-500" />
          </div>
          <div className="text-sm text-blue-500">{children || text}</div>
        </div>
      </div>
    </div>
  );
};

export default HelpBanner;
