import React from "react";
import { useDispatch } from "react-redux";

import { dismissModal } from "app.reducers/ui";
import { useTranslation } from "react-i18next";
import Button from "app.components/Util/Button";
import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";

import CopyToClipboard from "app.components/Util/CopyToClipboard";

const META_DATA_HEADER_CLASS =
  "whitespace-nowrap text-sm text-left text-zinc-500 font-normal";
const META_DATA_BODY_CLASS = "text-sm mb-4 last:mb-0 flex items-center gap-1";
const META_DATA_NOWRAP = `max-w-[90%] truncate whitespace-nowrap`;

function ModalNFT({ nftWorksheet }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleDismiss = (e) => {
    e.preventDefault();
    dispatch(dismissModal());
  };

  return (
    <React.Fragment>
      <ModalHeader title={nftWorksheet.name} closeCallback={handleDismiss} />
      <div className="mx-8 my-4 grid grid-cols-12 gap-4">
        <div className="col-span-6 flex items-center justify-center">
          <img
            className="max-h-[500px]"
            alt={nftWorksheet.name}
            src={nftWorksheet.imageUrl}
          />
        </div>
        <div className="col-span-6">
          <dl>
            {nftWorksheet.name ? (
              <React.Fragment>
                <dt className={META_DATA_HEADER_CLASS}>{t("common.name")}</dt>
                <dd className={META_DATA_BODY_CLASS}>
                  {nftWorksheet.name}
                  <CopyToClipboard
                    text={nftWorksheet.name}
                    label={t("common.name")}
                  />
                </dd>
              </React.Fragment>
            ) : null}
            {nftWorksheet.description ? (
              <React.Fragment>
                <dt className={META_DATA_HEADER_CLASS}>
                  {t("common.description")}
                </dt>
                <dd className={META_DATA_BODY_CLASS}>
                  {nftWorksheet.description}
                  <CopyToClipboard
                    text={nftWorksheet.description}
                    label={t("common.description")}
                  />
                </dd>
              </React.Fragment>
            ) : null}
            {nftWorksheet.createdBy ? (
              <React.Fragment>
                {" "}
                <dt className={META_DATA_HEADER_CLASS}>{t("nft.createdBy")}</dt>
                <dd className={META_DATA_BODY_CLASS}>
                  {nftWorksheet.createdBy}
                  <CopyToClipboard
                    text={nftWorksheet.createdBy}
                    label={t("nft.createdBy")}
                  />
                </dd>
              </React.Fragment>
            ) : null}
            {nftWorksheet.contract ? (
              <React.Fragment>
                <dt className={META_DATA_HEADER_CLASS}>
                  {t("ledgers.exchangeLedger.contract")}
                </dt>
                <dd className={META_DATA_BODY_CLASS}>
                  {nftWorksheet.contract}
                  <CopyToClipboard
                    text={nftWorksheet.contract}
                    label={t("ledgers.exchangeLedger.contract")}
                  />
                </dd>
              </React.Fragment>
            ) : null}
            {nftWorksheet.tokenId ? (
              <React.Fragment>
                <dt className={META_DATA_HEADER_CLASS}>
                  {t("ledgers.exchangeLedger.nftId")}
                </dt>
                <dd className={META_DATA_BODY_CLASS}>
                  {nftWorksheet.tokenId}
                  <CopyToClipboard
                    text={nftWorksheet.tokenId}
                    label={t("ledgers.exchangeLedger.nftId")}
                  />
                </dd>
              </React.Fragment>
            ) : null}
            {nftWorksheet.tokenUri ? (
              <React.Fragment>
                <dt className={META_DATA_HEADER_CLASS}>{t("nft.tokenUri")}</dt>
                <dd className={META_DATA_BODY_CLASS}>
                  <p className={META_DATA_NOWRAP}>{nftWorksheet.tokenUri}</p>
                  <CopyToClipboard
                    text={nftWorksheet.tokenUri}
                    label={t("nft.tokenUri")}
                  />
                </dd>
              </React.Fragment>
            ) : null}
            {nftWorksheet.imageUrl ? (
              <React.Fragment>
                <dt className={META_DATA_HEADER_CLASS}>{t("nft.imageUrl")}</dt>
                <dd className={META_DATA_BODY_CLASS}>
                  <p className={META_DATA_NOWRAP}>{nftWorksheet.imageUrl}</p>
                  <CopyToClipboard
                    text={nftWorksheet.imageUrl}
                    label={t("nft.imageUrl")}
                  />
                </dd>
              </React.Fragment>
            ) : null}
            {nftWorksheet.audioUrl ? (
              <React.Fragment>
                <dt className={META_DATA_HEADER_CLASS}>{t("nft.audioUrl")}</dt>
                <dd className={META_DATA_BODY_CLASS}>
                  <p className={META_DATA_NOWRAP}>{nftWorksheet.audioUrl}</p>
                  <CopyToClipboard
                    text={nftWorksheet.audioUrl}
                    label={t("nft.audioUrl")}
                  />
                </dd>
              </React.Fragment>
            ) : null}
          </dl>
        </div>
      </div>
      <ModalControls>
        <Button
          data-testid="close-modal"
          onClick={handleDismiss}
          buttonType="text"
          text={t("common.close")}
        />
      </ModalControls>
    </React.Fragment>
  );
}

ModalNFT.displayName = "ModalNFT";

export default ModalNFT;
