import React from "react";
import Big from "big.js";
import { useSelector } from "react-redux";
import { useGetUserFiat, useGetUserLocale } from "app.hooks";

import { PRECISION_OPTION_FULL, PRECISION_OPTION_MAX } from "app.constants";

const SIZES = {
  none: {
    big: "",
    little: "",
  },
  xs: {
    big: "text-xs leading-none",
    little: "text-xs leading-none",
  },
  sm: {
    big: "text-sm leading-none",
    little: "text-xs leading-none",
  },
  md: {
    big: "text-base leading-none",
    little: "text-xs leading-none",
  },
  lg: {
    big: "text-lg leading-none",
    little: "text-sm leading-none",
  },
  xl: {
    big: "text-xl leading-none",
    little: "text-base leading-none",
  },
  "2xl": {
    big: "text-2xl leading-none",
    little: "text-lg leading-none pt-px",
  },
  "3xl": {
    big: "text-3xl leading-none",
    little: "text-1xl leading-none pt-px",
  },
  "4xl": {
    big: "text-4xl leading-none",
    little: "text-2xl leading-none pt-px",
  },
  "5xl": {
    big: "text-5xl leading-none",
    little: "text-3xl leading-none pt-1",
  },
};

const ALIGN = {
  none: "",
  left: "justify-start text-left",
  center: "justify-center text-center",
  right: "justify-end text-right",
};

const CashMoney = ({
  dollars,
  negativeAsParens = false,
  fiatOverride = undefined,
  className = "",
  autoColors = false,
  positiveClass = "text-green-600",
  negativeClass = "text-red-700",
  zeroClass = "text-black",
  size = "md",
  align = "left",
  boldText = false,
  precisionOverride,
  negativeOverride = false,
}) => {
  const userFiat = useGetUserFiat();
  const userLocale = useGetUserLocale();

  const precision = useSelector((state) => state.ui?.selectedPrecision);

  let dollarsAsBig;
  try {
    if (typeof dollars !== "undefined" && dollars !== null) {
      if (typeof dollars === "string") {
        const cleanString = `${dollars}`.replace(/[^-.\d.]+/g, "");
        dollarsAsBig = new Big(cleanString);
      } else {
        dollarsAsBig = new Big(dollars);
      }
    }
  } catch (error) {
    dollarsAsBig = undefined;
  }

  const sizeClasses = SIZES[size] || SIZES["md"];
  const alignClass = ALIGN[align] || ALIGN["left"];

  const moneyBaseClassName = [
    size === "none" ? "" : "flex",
    size === "none" ? "" : "items-start",
    "leading-none",
    alignClass,
    className ? `${className}` : "",
    boldText ? "font-bold" : "",
  ];

  let digits = 2;
  switch (precision) {
    case PRECISION_OPTION_MAX:
      digits = 16;
      break;
    case PRECISION_OPTION_FULL:
      digits = 8;
      break;
    default:
      digits = 2;
      break;
  }

  if (isFinite(precisionOverride)) {
    digits = precisionOverride;
  }

  const displayFiat =
    typeof fiatOverride !== "undefined" ? fiatOverride : userFiat;

  let isNegative = negativeOverride ? true : false;
  let isZero = false;

  if (typeof dollarsAsBig === "undefined") return null;

  if (dollarsAsBig instanceof Big) {
    if (!negativeOverride) {
      isNegative = dollarsAsBig.lt(0) ? true : false;
    }
    isZero = dollarsAsBig.eq(0) ? true : false;
  }

  if (autoColors) {
    moneyBaseClassName.push(
      isZero ? zeroClass : isNegative ? negativeClass : positiveClass
    );
  }

  if (typeof dollars === "string" && dollars === "") {
    return (
      <span className={moneyBaseClassName.join(" ")}>
        <span className={sizeClasses.big}>&ndash;&ndash;</span>
        <span className={sizeClasses.little}>.&ndash;&ndash;</span>
      </span>
    );
  }

  // if (typeof dollars === "string") {
  //   const cleanString = `${dollars}`.replace(/[^-.\d.]+/g, "");
  //   dollarsAsFloatFull = parseFloat(cleanString);
  //   isZero = dollarsAsFloatFull === 0 ? true : false;
  //   isNegative =
  //     cleanString.substring(0, 1) === "-" || dollars.substring(0, 1) === "(";
  // }

  // if the number is less than a penny OR we are forcing full precision, show up to 8 decimal places.
  let places = digits;
  if (
    (dollarsAsBig.lt(0.01) && dollarsAsBig.gt(0.0)) ||
    (dollarsAsBig.gt(-0.01) && dollarsAsBig.lt(0.0))
  ) {
    places = digits;
  }

  const currencyFormmater = new Intl.NumberFormat(userLocale, {
    style: "currency",
    currency: displayFiat,
    minimumFractionDigits: 2,
    maximumFractionDigits: places,
  });

  const dollarsAsFloat = dollarsAsBig.toFixed(places);

  if (!currencyFormmater.formatToParts) return dollarsAsFloat;

  const moneyParts = currencyFormmater.formatToParts(dollarsAsFloat);

  const moneyDisplay = moneyParts
    .map((part, i) => {
      if (part.type === "minusSign" && negativeAsParens) return null;
      if (
        part.type === "integer" ||
        part.type === "group" ||
        part.type === "minusSign"
      )
        return (
          <span key={i} className={sizeClasses.big}>
            {part.value}
          </span>
        );
      if (part.type === "currency" || part.type === "literal")
        return (
          <span key={i} className={sizeClasses.little}>
            {part.value}
          </span>
        );
      if (part.type === "fraction" || part.type === "decimal")
        return (
          <span key={i} className={sizeClasses.little}>
            {part.value}
          </span>
        );
      return <span key={i}>{part.value}</span>;
    })
    .filter(Boolean);

  const moneyClassName = [
    ...moneyBaseClassName,
    isNegative ? negativeClass : null,
    isZero ? zeroClass : null,
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <span className={moneyClassName} data-testid="cash-money">
      {isNegative && negativeAsParens ? (
        <span className={sizeClasses.little}>(</span>
      ) : null}
      {moneyDisplay}
      {isNegative && negativeAsParens ? (
        <span className={sizeClasses.little}>)</span>
      ) : null}
    </span>
  );
};

export default CashMoney;
