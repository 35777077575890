/**
 * Gets the label for a given address
 *
 * @param {Object} Address Labels
 * @params {string} Address to find a label for
 * @returns {string} Returns the label or undefined
 * @example
 *
 * getLabelByAddress(addressLabels, address)
 * // => "My Label"
 */
export const getLabelByAddress = function (addressLabels, address) {
  const labels = Object.keys(addressLabels);

  for (let i = 0; i < labels.length; i++) {
    const label = labels[i];
    const addresses = addressLabels[label];
    if (
      addresses.findIndex((a) => a.toLowerCase() === address.toLowerCase()) > -1
    ) {
      return label;
    }
  }
  return undefined;
};
