/*
  The entry point for the app. This bootstraps the redux store and attaches the React app to the DOM.
 */

// removed these - not sure if we still need to import them or not
// testing not

//import "regenerator-runtime/runtime";

import "core-js";
import React from "react";
import { createRoot } from "react-dom/client";
import "app.config/configureBig";
import "app.config/i18n";

import Root from "app.components/Root";

import { store } from "app.config/configureStore";

const container = document.getElementById("app-root");
const root = createRoot(container);

root.render(<Root store={store} />);
