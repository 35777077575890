import { call, put, all, select } from "redux-saga/effects";

import {
  requestUpdateExchangeLedgersViaUpload,
  receiveUpdateExchangeLedgersViaUpload,
  updateExchangeLedgersViaUploadError,
  fetchLedgers
} from "app.actions/exchanges";

import { fetchExchangeLedgerRange } from "app.actions/exchangeLedger";

import { dismissModal, openApiKeyModal } from "app.reducers/ui";

import ExchangesAPI from "app.api/ExchangesAPI";

import { handleApiKeyError } from "app.actions/helpers";

function* updateExchangeLedgersViaUpload(action) {
  const { files, exchangeId, linkGUID, ledgerIds } = action;

  const exchange = yield select(state =>
    state.exchanges.supportedExchanges.find(item => item.id === exchangeId)
  );

  yield put(requestUpdateExchangeLedgersViaUpload());

  const apiCall =
    exchange.name === "DASH_CORE"
      ? ExchangesAPI.uploadDashCoreImportTransactions
      : ExchangesAPI.uploadImportTransactions;

  const response = yield call(apiCall, files, exchangeId, ledgerIds);

  const { body, error } = response;

  if (error) {
    if (typeof body.metadata !== "undefined") {
      yield put(
        handleApiKeyError({
          errorCode: body.metadata.code,
          exchange,
          linkGUID,
          openApiKeyModal,
          errorCallback:  updateExchangeLedgersViaUploadError,
          error
        })
      );
    } else {
      yield put(updateExchangeLedgersViaUploadError(body, error));
    }
  } else {
    // if (body.importResults.length === 0) {
    //   // failure
    //   yield put(updateExchangeLedgersViaUploadError(body));
    // } else {
    yield all([
      put(receiveUpdateExchangeLedgersViaUpload(body, exchangeId)),
      put(fetchLedgers()),
      put(dismissModal())
    ]);

    // if we are currently looking at a ledger that was just updated
    const currentLedgerSummary = yield select(
      state => state.exchangeLedger.ledgerSummary
    );

    if (typeof currentLedgerSummary !== "undefined") {
      const found = ledgerIds.findIndex(
        item => parseInt(item, 10) === currentLedgerSummary.ledgerId
      );
      if (found !== -1) {
        yield put(fetchExchangeLedgerRange(currentLedgerSummary.ledgerId));
      }
    }
  }
}

export default updateExchangeLedgersViaUpload;
