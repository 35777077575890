import { all, call, put } from "redux-saga/effects";
import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestExchangeChangeTransferStatus,
  receiveExchangeChangeTransferStatus,
} from "app.actions/exchangeLedger";
import {
  dismissModal,
  startModalWorking,
  endModalWorking,
} from "app.reducers/ui";

function* changeExchangeTransferStatus(action) {
  const { transactionId, externalTransfer, ledgerId } = action;

  yield put(requestExchangeChangeTransferStatus(transactionId));
  yield put(startModalWorking());

  const response = yield call(
    ExchangeLedgerAPI.editExchangeTransfer,
    ledgerId,
    transactionId,
    externalTransfer
  );

  if (response.error) {
    yield put(endModalWorking());
    console.log(
      "Error in changeExchangeTransferStatus (editExchangeTransfer)",
      response.body,
      response.error
    );
  }

  yield all([
    put(
      receiveExchangeChangeTransferStatus(
        response.body,
        transactionId,
        externalTransfer
      )
    ),
    put(dismissModal()),
  ]);
}

export default changeExchangeTransferStatus;
