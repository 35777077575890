import React, { useEffect, useState, useMemo } from "react";
import ErrorMessage from "app.components/Util/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { dismissModal } from "app.reducers/ui";
import { renameLinkedAccount } from "app.actions/exchanges";
import { LEDGER_NAME_MAX_LENGTH } from "app.constants";
import Button from "app.components/Util/Button";
import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";
import Input from "app.components/Util/Form/Input";
import Label from "app.components/Util/Form/Label";
import { useTranslation } from "react-i18next";

const ModalRenameLinkedAccount = ({
  exchangeName,
  isExchange = true,
  currentName,
  canDismissCallback,
  linkGUID,
}) => {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();
  const [name, setName] = useState(currentName);

  const { renamingLinkedAccount, renamingLinkedAccountError } = useSelector(
    (state) => state.exchanges
  );

  const disableButtons = useMemo(() => {
    if (renamingLinkedAccount) return true;
    return false;
  }, [renamingLinkedAccount]);

  useEffect(() => {
    // prevents modal from dismissing when esc is pressed if the user is working
    canDismissCallback(!disableButtons);
  }, [canDismissCallback, disableButtons]);

  const handleUpdate = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const submit = (e) => {
    e.preventDefault();

    if (name === currentName) {
      reduxDispatch(dismissModal());
      return;
    }

    reduxDispatch(renameLinkedAccount(linkGUID, name));
  };

  return (
    <React.Fragment>
      <ModalHeader
        title={t("modals.rename", { label: exchangeName })}
        closeCallback={(e) => {
          e.preventDefault();
          reduxDispatch(dismissModal());
        }}
      />

      <form onSubmit={submit}>
        <div className="m-4">
          <ErrorMessage {...renamingLinkedAccountError} />
          <p className="my-4 text-lg">
            {isExchange
              ? t("modals.enterNewNameForThisAccount", { exchangeName })
              : t("modals.enterNewNameForThisWallet", { exchangeName })}
          </p>

          <div className="mb-4 flex flex-col">
            <Label htmlFor="accountName">
              {isExchange
                ? t("modals.exchangeAccountName")
                : t("modals.walletName")}
            </Label>
            <Input
              maxLength={LEDGER_NAME_MAX_LENGTH}
              disabled={disableButtons}
              placeholder={
                isExchange
                  ? t("input.placeholder.accountName")
                  : t("input.placeholder.walletName")
              }
              data-tabbable={true}
              type="text"
              id="accountName"
              data-testid="accountName"
              name="accountName"
              value={name}
              onChange={handleUpdate}
            />
          </div>
        </div>

        <ModalControls>
          <Button
            type="submit"
            disabled={disableButtons}
            data-tabbable={true}
            buttonType="primary"
            customClasses="ml-4"
            working={renamingLinkedAccount}
            text={t("button.save")}
            workingText={t("button.saving")}
          />
          <Button
            disabled={disableButtons}
            onClick={(e) => {
              e.preventDefault();
              reduxDispatch(dismissModal());
            }}
            data-tabbable={true}
            buttonType="text"
            text={t("button.close")}
          />
        </ModalControls>
      </form>
    </React.Fragment>
  );
};

ModalRenameLinkedAccount.displayName = "ModalRenameLinkedAccount";

export default ModalRenameLinkedAccount;
