import formatApiError from "./formatApiError";
import logout from "app.utils/logout";

export async function getNewTicket(newTicketURL) {
  const response = await fetch(newTicketURL, {
    credentials: "include",
  });
  if (parseInt(response.status, 10) === 200) {
    const body = await response.json();
    return body;
  } else {
    // if we get a 401 here, that means the session is expired and we need
    // to log out
    if (parseInt(response.status, 10) === 401) {
      logout();
    } else {
      return Promise.reject(formatApiError(response));
    }
  }
}

export async function getTokenFromTicket(ticket, refreshURL, profileId) {
  const queryParams = new URLSearchParams(ticket);
  if (typeof profileId !== "undefined") {
    queryParams.append("profileGUID", profileId);
  }
  const response = await fetch(refreshURL + "?" + queryParams.toString());

  const body = await response.json();

  if (response.status === 200) {
    return body;
  } else {
    return Promise.reject(formatApiError(response, body));
  }
}

export function filterEmptyParams(params) {
  return Object.fromEntries(
    Object.entries(params).filter(
      ([k, v]) => typeof v !== "undefined" && v !== null
    )
  );
}
