import React from "react";
import { useDispatch } from "react-redux";

import { dismissModal } from "app.reducers/ui";
import { useTranslation } from "react-i18next";
import Button from "app.components/Util/Button";
import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";

function ModalInfo({ title, content, moreInfoLink }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const handleDismiss = (e) => {
    e.preventDefault();
    dispatch(dismissModal());
  };

  return (
    <React.Fragment>
      <ModalHeader title={title} closeCallback={handleDismiss} />

      <div className="m-4">
        {content}
        {moreInfoLink ? (
          <div className="my-4">
            <a
              className="text-linkBlue hover:text-linkBlueActive hover:underline"
              href={moreInfoLink?.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {moreInfoLink?.title}
            </a>
          </div>
        ) : null}
      </div>
      <ModalControls>
        <Button
          data-testid="close-modal"
          onClick={handleDismiss}
          buttonType="text"
          text={t("common.close")}
        />
      </ModalControls>
    </React.Fragment>
  );
}

ModalInfo.displayName = "ModalInfo";

export default ModalInfo;
