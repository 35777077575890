import React from "react";
import Button from "app.components/Util/Button";
import { useTranslation } from "react-i18next";

const TextArea = ({
  hasError,
  className,
  helpMessage = false,
  clearButton = false,
  clearButtonDisabled = false,
  saveButton = false,
  footer = true,
  onClear,
  clearLabel,
  onSave,
  saveButtonDisabled = false,
  children,
  ...htmlAttributes
}) => {
  const { t } = useTranslation();
  // const [focused, setFocused] = useState(false);

  const textAreaContainerClass = [
    "flex",
    "flex-col",
    "transition-all",
    "rounded",
    "border",
    "shadow-inner",
    "leading-7",
    "disabled:cursor-not-allowed",
    "disabled:opacity-70",
    "placeholder:text-sm",
    "relative",
    className,
    hasError ? "border-red-700 text-red-700" : "border-zinc-300",
    // focused ? "border-focusBlue" : ""
  ]
    .filter(Boolean)
    .join(" ");

  const textAreaClass = [
    "h-30",
    "w-full",
    "rounded",
    "px-2",
    "py-1.5",
    "text-sm",
    "text-zinc-600",
    footer ? "border-b-[2.5rem] border-b-zinc-50" : "",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div className={textAreaContainerClass}>
      <textarea
        data-testid="text-area"
        className={textAreaClass}
        {...htmlAttributes}
      ></textarea>
      {helpMessage || clearButton || saveButton ? (
        <div
          className={[
            "absolute bottom-px flex h-10 w-full items-center rounded-b border-t bg-zinc-50 p-2",
            helpMessage ? "justify-between" : "justify-end",
          ].join(" ")}
        >
          {helpMessage}
          {clearButton && (
            <Button
              buttonSize="sm"
              buttonType="text"
              disabled={clearButtonDisabled}
              onClick={onClear}
              text={t("button.cancel")}
            />
          )}
          {saveButton && (
            <Button
              disabled={saveButtonDisabled}
              buttonType="primary"
              buttonSize="sm"
              onClick={onSave}
              customClasses="ml-2"
              text={t("button.save")}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

TextArea.displayName = "TextArea";

export default TextArea;
