import { makeActionCreator } from "app.actions/helpers";
/******************************************************************************/
/* create exchange ledgers via upload */
/******************************************************************************/
export const CREATE_EXCHANGE_LEDGERS_UPLOAD_ERROR =
  "CREATE_EXCHANGE_LEDGERS_UPLOAD_ERROR";
export const CREATE_EXCHANGE_LEDGERS_UPLOAD_RECEIVE =
  "CREATE_EXCHANGE_LEDGERS_UPLOAD_RECEIVE";
export const CREATE_EXCHANGE_LEDGERS_UPLOAD_REQUEST =
  "CREATE_EXCHANGE_LEDGERS_UPLOAD_REQUEST";
export const CREATE_EXCHANGE_LEDGERS_UPLOAD_BEGIN =
  "CREATE_EXCHANGE_LEDGERS_UPLOAD_BEGIN";
export const CREATE_EXCHANGE_LEDGERS_UPLOAD_RESET =
  "CREATE_EXCHANGE_LEDGERS_UPLOAD_RESET";

export const createExchangeLedgersViaUpload = makeActionCreator(
  CREATE_EXCHANGE_LEDGERS_UPLOAD_BEGIN,
  "files",
  "exchangeId",
  "accountingStrategy"
);
export const requestCreateExchangeLedgersViaUpload = makeActionCreator(
  CREATE_EXCHANGE_LEDGERS_UPLOAD_REQUEST
);
export const receiveCreateExchangeLedgersViaUpload = makeActionCreator(
  CREATE_EXCHANGE_LEDGERS_UPLOAD_RECEIVE,
  "exchangeId"
);
export const createExchangeLedgersViaUploadError = makeActionCreator(
  CREATE_EXCHANGE_LEDGERS_UPLOAD_ERROR,
  "response",
  "error"
);

export const createExchangeLedgersViaUploadReset = makeActionCreator(
  CREATE_EXCHANGE_LEDGERS_UPLOAD_RESET
);
