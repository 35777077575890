import React, { memo } from "react";

import ascendex from "app.images/exchanges/icons/ascendex-icon.svg";
import binance from "app.images/exchanges/icons/binance-icon.svg";
import binanceUS from "app.images/exchanges/icons/binance-us-icon.svg";
import bitfinex from "app.images/exchanges/icons/bitfinex-icon.svg";
import bitgo from "app.images/exchanges/icons/bitgo-icon.svg";
import bitmart from "app.images/exchanges/icons/bitmart-icon.svg";
import bitmex from "app.images/exchanges/icons/bitmex-icon.svg";
import bitso from "app.images/exchanges/icons/bitso-icon.svg";
import bitstamp from "app.images/exchanges/icons/bitstamp-icon.svg";
import bittrex from "app.images/exchanges/icons/bittrex-icon.svg";
import btse from "app.images/exchanges/icons/btse-icon.svg";
import bybit from "app.images/exchanges/icons/bybit-icon.svg";
import cashapp from "app.images/exchanges/icons/cashapp-icon.svg";
import cexio from "app.images/exchanges/icons/cex-io-icon.svg";
import coinbase from "app.images/exchanges/icons/coinbase-icon.svg";
import coinbaseExchange from "app.images/exchanges/icons/coinbase-exchange-icon.svg";
import coinbasePrime from "app.images/exchanges/icons/coinbase-prime-icon.svg";
import coinbasePro from "app.images/exchanges/icons/coinbase-pro-icon.svg";
import cryptocom from "app.images/exchanges/icons/cryptocom-icon.png";
import cryptopia from "app.images/exchanges/icons/cryptopia-icon.svg";
import custom from "app.images/exchanges/icons/custom-icon.svg";
import deribit from "app.images/exchanges/icons/deribit-icon.svg";
import etherscan from "app.images/exchanges/icons/etherscan-icon.svg";
import exodus from "app.images/exchanges/icons/exodus-icon.svg";
import fireblocks from "app.images/exchanges/icons/fireblocks-icon.svg";
import ftx from "app.images/exchanges/icons/ftx-icon.svg";
import ftxUS from "app.images/exchanges/icons/ftxus-icon.svg";
import gateio from "app.images/exchanges/icons/gateio-icon.svg";
import gemini from "app.images/exchanges/icons/gemini-icon.png";
import genericLogo from "app.images/exchanges/icons/generic-exchange-icon.png";
import hitbtc from "app.images/exchanges/icons/hitbtc-icon.svg";
import hotbit from "app.images/exchanges/icons/hotbit-icon.svg";
import huobi from "app.images/exchanges/icons/huobi-icon.png";
import kraken from "app.images/exchanges/icons/kraken-icon.svg";
import kucoin from "app.images/exchanges/icons/kucoin-icon.svg";
import netcoins from "app.images/exchanges/icons/netcoins-icon.svg";
import okx from "app.images/exchanges/icons/okx-icon.svg";
import poloniex from "app.images/exchanges/icons/poloniex-icon.svg";
import robinhood from "app.images/exchanges/icons/robinhood-icon.svg";
import uphold from "app.images/exchanges/icons/uphold-icon.png";
import yobit from "app.images/exchanges/icons/yobit-icon.png";

import ascendexLogo from "app.images/exchanges/ascendex-logo.svg";
import binanceLogo from "app.images/exchanges/binance-logo.svg";
import binanceUSLogo from "app.images/exchanges/binance-us-logo.svg";
import bitfinexLogo from "app.images/exchanges/bitfinex-logo.svg";
import bitgoLogo from "app.images/exchanges/bitgo-logo.svg";
import bitmartLogo from "app.images/exchanges/bitmart-logo.svg";
import bitmexLogo from "app.images/exchanges/bitmex-logo.svg";
import bitsoLogo from "app.images/exchanges/bitso-logo.svg";
import bitstampLogo from "app.images/exchanges/bitstamp-logo.svg";
import bittrexLogo from "app.images/exchanges/bittrex-logo.svg";
import btseLogo from "app.images/exchanges/btse-logo.svg";
import bybitLogo from "app.images/exchanges/bybit-logo.svg";
import cashappLogo from "app.images/exchanges/cashapp-logo.svg";
import cexioLogo from "app.images/exchanges/cex-io-logo.svg";
import coinbaseLogo from "app.images/exchanges/coinbase-logo.svg";
import coinbaseExchangeLogo from "app.images/exchanges/coinbase-exchange-logo.svg";
import coinbasePrimeLogo from "app.images/exchanges/coinbase-prime-logo.png";
import coinbaseProLogo from "app.images/exchanges/coinbase-pro-logo.svg";
import cryptocomLogo from "app.images/exchanges/cryptocom-logo.png";
import cryptopiaLogo from "app.images/exchanges/cryptopia-logo.svg";
import customLogo from "app.images/exchanges/custom-logo.svg";
import deribitLogo from "app.images/exchanges/deribit-logo.svg";
import etherscanLogo from "app.images/exchanges/etherscan-logo.svg";
import exodusLogo from "app.images/exchanges/exodus-logo.svg";
import fireblocksLogo from "app.images/exchanges/fireblocks-logo.svg";
import ftxLogo from "app.images/exchanges/ftx-logo.svg";
import ftxUSLogo from "app.images/exchanges/ftxus-logo.svg";
import gateioLogo from "app.images/exchanges/gateio-logo.svg";
import geminiLogo from "app.images/exchanges/gemini-logo.svg";
import genericCard from "app.images/exchanges/generic-logo.svg";
import hitbtcLogo from "app.images/exchanges/hitbtc-logo.svg";
import hotbitLogo from "app.images/exchanges/hotbit-logo.svg";
import huobiLogo from "app.images/exchanges/huobi-logo.png";
import krakenLogo from "app.images/exchanges/kraken-logo.svg";
import kucoinLogo from "app.images/exchanges/kucoin-logo.svg";
import okxLogo from "app.images/exchanges/okx-logo.svg";
import netcoinsLogo from "app.images/exchanges/netcoins-logo.svg";
import poloniexLogo from "app.images/exchanges/poloniex-logo.svg";
import robinhoodLogo from "app.images/exchanges/robinhood-logo.svg";
import upholdLogo from "app.images/exchanges/uphold-logo.png";
import yobitLogo from "app.images/exchanges/yobit-logo.png";

import { EXCHANGE_IDS } from "app.constants";

const CARDS = {
  [EXCHANGE_IDS.ASCENDEX]: ascendexLogo,
  [EXCHANGE_IDS.BINANCE_US]: binanceUSLogo,
  [EXCHANGE_IDS.BINANCE]: binanceLogo,
  [EXCHANGE_IDS.BITFINEX]: bitfinexLogo,
  [EXCHANGE_IDS.BITGO_WALLET]: bitgoLogo,
  [EXCHANGE_IDS.BITMART]: bitmartLogo,
  [EXCHANGE_IDS.BITMEX]: bitmexLogo,
  [EXCHANGE_IDS.BITSO]: bitsoLogo,
  [EXCHANGE_IDS.BITSTAMP]: bitstampLogo,
  [EXCHANGE_IDS.BITTREX_UPLOAD]: bittrexLogo,
  [EXCHANGE_IDS.BITTREX]: bittrexLogo,
  [EXCHANGE_IDS.BTSE]: btseLogo,
  [EXCHANGE_IDS.BYBIT]: bybitLogo,
  [EXCHANGE_IDS.CASHAPP]: cashappLogo,
  [EXCHANGE_IDS.CEX_IO]: cexioLogo,
  [EXCHANGE_IDS.COINBASE_API_KEY]: coinbaseLogo,
  [EXCHANGE_IDS.COINBASE_EXCHANGE]: coinbaseExchangeLogo,
  [EXCHANGE_IDS.COINBASE_UPLOAD]: coinbaseLogo,
  [EXCHANGE_IDS.COINBASE]: coinbaseLogo,
  [EXCHANGE_IDS.COINBASEPRIME]: coinbasePrimeLogo,
  [EXCHANGE_IDS.COINBASEPRO]: coinbaseProLogo,
  [EXCHANGE_IDS.CRYPTOCOM]: cryptocomLogo,
  [EXCHANGE_IDS.CRYPTOPIA]: cryptopiaLogo,
  [EXCHANGE_IDS.DERIBIT]: deribitLogo,
  [EXCHANGE_IDS.ETHERSCAN_UPLOAD]: etherscanLogo,
  [EXCHANGE_IDS.EXODUS]: exodusLogo,
  [EXCHANGE_IDS.FIREBLOCKS]: fireblocksLogo,
  [EXCHANGE_IDS.FTX_US]: ftxUSLogo,
  [EXCHANGE_IDS.FTX]: ftxLogo,
  [EXCHANGE_IDS.GATEIO]: gateioLogo,
  [EXCHANGE_IDS.GDAX]: coinbaseProLogo,
  [EXCHANGE_IDS.GEMINI_UPLOAD]: geminiLogo,
  [EXCHANGE_IDS.GEMINI]: geminiLogo,
  [EXCHANGE_IDS.GENERIC]: customLogo,
  [EXCHANGE_IDS.HITBTC]: hitbtcLogo,
  [EXCHANGE_IDS.HOTBIT]: hotbitLogo,
  [EXCHANGE_IDS.HUOBI]: huobiLogo,
  [EXCHANGE_IDS.KOINLY]: customLogo,
  [EXCHANGE_IDS.KRAKEN]: krakenLogo,
  [EXCHANGE_IDS.KUCOIN]: kucoinLogo,
  [EXCHANGE_IDS.NETCOINS]: netcoinsLogo,
  [EXCHANGE_IDS.OKX]: okxLogo,
  [EXCHANGE_IDS.POLONIEX]: poloniexLogo,
  [EXCHANGE_IDS.ROBINHOOD]: robinhoodLogo,
  [EXCHANGE_IDS.UPHOLD]: upholdLogo,
  [EXCHANGE_IDS.YOBIT]: yobitLogo,
};

const LOGOS = {
  [EXCHANGE_IDS.ASCENDEX]: ascendex,
  [EXCHANGE_IDS.BINANCE_US]: binanceUS,
  [EXCHANGE_IDS.BINANCE]: binance,
  [EXCHANGE_IDS.BITFINEX]: bitfinex,
  [EXCHANGE_IDS.BITGO_WALLET]: bitgo,
  [EXCHANGE_IDS.BITMART]: bitmart,
  [EXCHANGE_IDS.BITMEX]: bitmex,
  [EXCHANGE_IDS.BITSO]: bitso,
  [EXCHANGE_IDS.BITSTAMP]: bitstamp,
  [EXCHANGE_IDS.BITTREX_UPLOAD]: bittrex,
  [EXCHANGE_IDS.BITTREX]: bittrex,
  [EXCHANGE_IDS.BTSE]: btse,
  [EXCHANGE_IDS.BYBIT]: bybit,
  [EXCHANGE_IDS.CASHAPP]: cashapp,
  [EXCHANGE_IDS.CEX_IO]: cexio,
  [EXCHANGE_IDS.COINBASE_API_KEY]: coinbase,
  [EXCHANGE_IDS.COINBASE_UPLOAD]: coinbase,
  [EXCHANGE_IDS.COINBASE]: coinbase,
  [EXCHANGE_IDS.COINBASE_EXCHANGE]: coinbaseExchange,
  [EXCHANGE_IDS.COINBASEPRIME]: coinbasePrime,
  [EXCHANGE_IDS.COINBASEPRO]: coinbasePro,
  [EXCHANGE_IDS.CRYPTOCOM]: cryptocom,
  [EXCHANGE_IDS.CRYPTOPIA]: cryptopia,
  [EXCHANGE_IDS.DERIBIT]: deribit,
  [EXCHANGE_IDS.ETHERSCAN_UPLOAD]: etherscan,
  [EXCHANGE_IDS.EXODUS]: exodus,
  [EXCHANGE_IDS.FIREBLOCKS]: fireblocks,
  [EXCHANGE_IDS.FTX_US]: ftxUS,
  [EXCHANGE_IDS.FTX]: ftx,
  [EXCHANGE_IDS.GATEIO]: gateio,
  [EXCHANGE_IDS.GDAX]: coinbasePro,
  [EXCHANGE_IDS.GEMINI_UPLOAD]: gemini,
  [EXCHANGE_IDS.GEMINI]: gemini,
  [EXCHANGE_IDS.GENERIC]: custom,
  [EXCHANGE_IDS.HITBTC]: hitbtc,
  [EXCHANGE_IDS.HOTBIT]: hotbit,
  [EXCHANGE_IDS.HUOBI]: huobi,
  [EXCHANGE_IDS.KOINLY]: custom,
  [EXCHANGE_IDS.KRAKEN]: kraken,
  [EXCHANGE_IDS.KUCOIN]: kucoin,
  [EXCHANGE_IDS.OKX]: okx,
  [EXCHANGE_IDS.NETCOINS]: netcoins,
  [EXCHANGE_IDS.POLONIEX]: poloniex,
  [EXCHANGE_IDS.ROBINHOOD]: robinhood,
  [EXCHANGE_IDS.UPHOLD]: uphold,
  [EXCHANGE_IDS.YOBIT]: yobit,
};

const ExchangeLogo = memo(
  ({
    name,
    xsmall = false,
    large = false,
    card = false,
    medium = false,
    alt,
  }) => {
    const altText = typeof alt === "undefined" ? name : alt;

    let width = "30";
    let height = "30";

    if (card === false) {
      if (xsmall) {
        width = "20";
        height = "20";
      }
      if (medium) {
        width = "32";
        height = "32";
      }
      if (large) {
        width = "40";
        height = "40";
      }
      const logo = LOGOS[name] || genericLogo;
      return <img width={width} height={height} alt={altText} src={logo} />;
    } else {
      const card = CARDS[name] || genericCard;
      return <img src={card} alt={altText} />;
    }
  }
);

ExchangeLogo.displayName = "ExchangeLogo";

export default ExchangeLogo;
