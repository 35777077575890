import { makeActionCreator } from "app.actions/helpers";

/******************************************************************************/
/* rename linked account */
/******************************************************************************/
export const RENAME_LINKED_ACCOUNT_BEGIN = "RENAME_LINKED_ACCOUNT_BEGIN";
export const RENAME_LINKED_ACCOUNT_REQUEST = "RENAME_LINKED_ACCOUNT_REQUEST";
export const RENAME_LINKED_ACCOUNT_RECEIVE = "RENAME_LINKED_ACCOUNT_RECEIVE";
export const RENAME_LINKED_ACCOUNT_ERROR = "RENAME_LINKED_ACCOUNT_ERROR";

export const renameLinkedAccount = makeActionCreator(
  RENAME_LINKED_ACCOUNT_BEGIN,
  "linkedAccountId",
  "nickname"
);

export const requestRenameLinkedAccount = makeActionCreator(
  RENAME_LINKED_ACCOUNT_REQUEST
);

export const receiveRenameLinkedAccount = makeActionCreator(
  RENAME_LINKED_ACCOUNT_RECEIVE,
  "linkedAccountId",
  "nickname"
);

export const renameLinkedAccountError = makeActionCreator(
  RENAME_LINKED_ACCOUNT_ERROR,
  "response",
  "error"
);
