import { getSortBy } from "./getSortBy";

const EXCHANGE_RANKS = {
  API_KEY: 3,
  API_OAUTH: 2,
  FILE_UPLOAD: 1,
};

export const sortExchanges = (exchanges) => {
  return [...exchanges]
    .sort(getSortBy((x) => x.label.toLowerCase(), true))
    .sort((a, b) => {
      if (a.available === true && b.available === false) return -1;
      if (a.available === false && b.available === true) return 1;
      return 0;
    });
};

export const hasLedgersForExchange = (ledgers, exchangeId) => {
  let findExchange = ledgers.findIndex((l) => l.exchangeId === exchangeId);
  if (findExchange === -1) {
    return false;
  }
  return true;
};

export const groupAndRankExchanges = (supportedExchanges) => {
  const groups = supportedExchanges.reduce((groupBy, ex) => {
    return groupBy.add(ex.groupKey);
  }, new Set([]));

  const groupedAndRanked = {};
  groups.forEach((group) => {
    let exchangesForGroup = supportedExchanges.filter(
      (ex) => ex.groupKey === group && ex.hidden !== true
    );

    if (exchangesForGroup.length > 1) {
      exchangesForGroup = exchangesForGroup.filter(
        (ex) => ex.available === true
      );
    }

    exchangesForGroup.sort((a, b) => {
      if (
        EXCHANGE_RANKS[a.integrationType] < EXCHANGE_RANKS[b.integrationType]
      ) {
        return 1;
      }
      if (
        EXCHANGE_RANKS[a.integrationType] > EXCHANGE_RANKS[b.integrationType]
      ) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });

    if (exchangesForGroup.length > 0) {
      groupedAndRanked[group] = {
        exchanges: exchangesForGroup,
        groupKey: exchangesForGroup[0].groupKey,
        label: exchangesForGroup[0].label,
      };
    }
  });
  return groupedAndRanked;
};

export const getPrimaryExchange = (exchange, supportedExchanges) => {
  const { groupKey, name } = exchange;
  if (name === groupKey) return undefined;

  const primaryExchange = supportedExchanges.find((ex) => ex.name === groupKey);
  return primaryExchange;
};

export const hasExchangeAlternateLedgers = (
  primaryExchange,
  alternateExchange,
  ledgers
) => {
  const userHasPrimaryExchangeLedgers = hasLedgersForExchange(
    ledgers,
    primaryExchange.id
  );
  const userHasAlternateExchanbeLedgers = hasLedgersForExchange(
    ledgers,
    alternateExchange.id
  );

  return { userHasPrimaryExchangeLedgers, userHasAlternateExchanbeLedgers };
};
