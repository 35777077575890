import { call, delay, put, all } from "redux-saga/effects";
import * as organizationActions from "app.reducers/organizations";
import OrganizationAPI from "app.api/OrganizationAPI";
import { dismissModal } from "app.reducers/ui";
import { addFlashMessage, removeFlashMessage } from "app.reducers/ui";
import { generateGUID } from "app.utils";
import {
  STATUS_SUCCESS,
  STATUS_DANGER,
} from "app.components/Messages/FlashMessage";
import { TEN_SECONDS } from "app.constants";
import i18n from "i18next";

function* reinviteUser(action) {
  const { userGUID, email } = action.payload;

  yield put(organizationActions.reinviteUserRequest(userGUID));

  const response = yield call(OrganizationAPI.reinviteUser, userGUID);

  if (response.error) {
    let errorMessage;
    switch (response.body.type) {
      case "INVALID_REQUEST":
      default:
        errorMessage = "An unexpected error occured reinviting user.";
        break;
    }
    const messageId = generateGUID();
    const flashMessage = addFlashMessage(
      errorMessage,
      messageId,
      STATUS_DANGER
    );

    yield put(organizationActions.reinviteUserError(userGUID, errorMessage));
    yield put(flashMessage);

    return;
  }

  const messageId = generateGUID();

  const flashMessage = addFlashMessage(
    i18n.t("messages.inviteSentMessage", { email: email }),
    messageId,
    STATUS_SUCCESS
  );

  yield all([
    put(dismissModal()),
    put(organizationActions.reinviteUserReceive(userGUID)),
    put(flashMessage),
  ]);

  yield delay(TEN_SECONDS);

  yield put(removeFlashMessage(messageId));
}

export default reinviteUser;
