import api from "../clients/exchange";

/**
 * Submits a request to add an exchange ledger to the user's account
 * @async
 * @function
 * @param {number} exchangeId - The id of the exchange
 * @param {number} accountId - The id of the account
 * @param {string} userLedgerName - The Node40 name
 * @returns {object}  response - The Response or Error message
 */
export const addExchangeLedger = (exchangeId, accountId, userLedgerName) => {
  return api.post(`/${exchangeId}/ledger`, {
    data: { accountId, userLedgerName },
    authenticated: true,
  });
};

/**
 * Submits a request to bulk import exchange ledgers
 * @async
 * @function
 * @param {number} exchangeId - The id of the exchange
 * @param {String} linkGUID - The identifier of the specific echange link
 * @param {number[]} accountIds - An array of account ids
 * @param {string} accountingStrategy - The strategy (LIFO or FIFO)
 * @returns {object}  response - The Response or Error message
 */
export const addBulkExchangeLedgers = (
  exchangeId,
  linkGUID,
  accountIds,
  accountingStrategy,
  ledgerName
) => {
  const body = { accountIds, accountingStrategy };
  if (typeof ledgerName !== "undefined") {
    body.ledgerName = ledgerName;
  }
  return api.post(`/${exchangeId}/${linkGUID}/ledger/bulk`, {
    data: body,
    authenticated: true,
  });
};

/**
 * Submits a request to upload transactions
 * @async
 * @function
 * @param {number} transactionsFile - The file containing the transactions
 * @param {number} exchangeId - The id of the exchange
 * @param {number} accountingStrategy - The strategy (LIFO or FIFO)
 * @returns {object}  response - The Response or Error message
 */
export const createExchangeLedgersViaUpload = (
  files,
  exchangeId,
  accountingStrategy
) => {
  let formData = new FormData();
  let fileMap = {};

  for (let i in files) {
    formData.append("files", files[i], i);
    fileMap[i] = files[i].name;
  }
  formData.append("accountingStrategy", accountingStrategy);
  formData.append("fileMap", JSON.stringify(fileMap));

  return api.post(`/${exchangeId}/upload/ledger/async/create`, {
    data: formData,
    authenticated: true,
  });
};

/**
 * Submits a request to upload an import transaction file
 * @async
 * @function
 * @param {number} transactionsFile - The file containing the transactions
 * @param {number} exchangeId - The id of the exchange
 * @param {number[]} ledgerIds - The ids of the ledgers to update
 * @returns {object}  response - The Response or Error message
 */
export const uploadImportTransactions = (files, exchangeId, ledgerIds) => {
  let formData = new FormData();
  let fileMap = {};

  for (let i in files) {
    formData.append("files", files[i], i);
    fileMap[i] = files[i].name;
  }
  for (let i in ledgerIds) {
    formData.append("ledgerIds", ledgerIds[i]);
  }

  formData.append("fileMap", JSON.stringify(fileMap));

  return api.post(`/${exchangeId}/upload/ledger/async/import`, {
    data: formData,
    authenticated: true,
  });
};

export const uploadRollforwardOpenDebits = (file) => {
  let formData = new FormData();
  let fileMap = {};

  formData.append("files", file, "file");
  fileMap.file = file.name;

  formData.append("fileMap", JSON.stringify(fileMap));

  return api.post("/upload/ledger/roll-forward-position", {
    data: formData,
    authenticated: true,
  });
};

/**
 * Submits a request to upload transactions
 * @async
 * @function
 * @param {number} transactionsFile - The file containing the transactions
 * @param {number} exchangeId - The id of the exchange
 * @param {number} accountingStrategy - The strategy (LIFO or FIFO)
 * @returns {object}  response - The Response or Error message
 */
export const migrateViaUpload = (files) => {
  let formData = new FormData();
  let fileMap = {};

  for (let i in files) {
    formData.append("files", files[i], i);
    fileMap[i] = files[i].name;
  }

  formData.append("fileMap", JSON.stringify(fileMap));

  return api.post(`/koinly/storeFileFetchSources`, {
    data: formData,
    authenticated: true,
  });
};

export const migrateSources = (responseId, sources) => {
  return api.post(`/koinly/doImports`, {
    data: {
      responseId,
      sources,
    },
    authenticated: true,
  });
};
