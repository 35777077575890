import { WALLETS_LABEL, SOURCE_TYPES } from "app.constants";

export const getSourceGroupName = function (source, supportedExchanges) {
  if (source === SOURCE_TYPES.WALLET) {
    return WALLETS_LABEL;
  }
  const foundExchange = supportedExchanges.find(
    ({ name, id }) => name === source || id === source
  );

  if (typeof foundExchange !== "undefined") {
    return foundExchange.label;
  }

  return source;
};
