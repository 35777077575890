import { all, call, put, take, race, select, delay } from "redux-saga/effects";
import SmartWalletAPI from "app.api/SmartWalletAPI";
import * as smartWalletActions from "app.reducers/smartWallet";

import {
  STATUS,
  HALF_MINUTE,
  COST_BASIS_COMPLETE_MESSAGE,
  COST_BASIS_COMPLETE_MESSAGE_PATH,
  NFT,
} from "app.constants";
import { getSortBy } from "app.utils";

import { getLoadingState } from "app.utils/selectors";
import { generateGUID, lookupTranslation } from "app.utils";

import { STATUS_INFO } from "app.components/Messages/FlashMessage";

import { addFlashMessage, removeFlashMessage } from "app.reducers/ui";
import { resetWorksheetCache } from "app.actions/exchangeLedger";

const sortByCurrencyType = getSortBy(
  (item) => item.currencyType.toLowerCase(),
  true
);

function* fetchSmartWalletList() {
  const { isLoading } = yield* getLoadingState(
    (state) => state.smartWallet.smartWalletListStatus
  );

  if (isLoading) return;

  // if the user's subscription is not sufficient, skip loading and mark as LOADED
  const subscription = yield select((state) => state.user.subscription);
  const ledgers = yield select((state) => state.exchanges.ledgers);
  const ledgersByCurrency = yield select(
    (state) => state.computables.ledgersByCurrency
  );
  const { smartWalletRunning, smartWalletListStatus } = yield select(
    (state) => state.smartWallet
  );
  const isLoaded = smartWalletListStatus === STATUS.LOADED;

  if (subscription.smartWallet === false) {
    yield put(
      smartWalletActions.receiveSmartWalletList({
        smartWalletList: [],
        smartWalletViolations: undefined,
        smartWalletEnabled: false,
        smartWalletRunning: false,
      })
    );
    return;
  }

  yield put(smartWalletActions.requestSmartWalletList());
  // if the user leaves the section - this cancel action will fire
  // cancelling the handling the API response
  const { response } = yield race({
    response: call(SmartWalletAPI.getSmartWalletList),
    cancel: take(smartWalletActions.leaveSmartWalletSection),
  });

  // handle the API response
  if (!response || response.error) {
    if (response?.error === "NETWORK") {
      // if the error was a network error - just keep the status as loaded,
      // try again in 30 seconds
      yield put(
        smartWalletActions.receiveSmartWalletListError({
          nextStatus: isLoaded ? STATUS.LOADED : STATUS.ERROR,
        })
      );
      yield delay(HALF_MINUTE);
      return yield* fetchSmartWalletList();
    }
    // if not, log the error to the console
    console.error(
      "Error in fetchSmartWalletList",
      response?.body,
      response?.error
    );
  } else if (
    typeof response.body !== "undefined" &&
    typeof response.body.smartWallets !== "undefined"
  ) {
    // label a smart wallet at NFT if it contains an NFT ledger
    // this computation will also be done in the computables section
    // as that may not be complete the first time we load smart wallet list
    const smartWalletsComputed = response.body.smartWallets.map((sw) => {
      const currencyLedgers =
        (ledgersByCurrency && ledgersByCurrency[NFT]) || [];
      const isNFT =
        currencyLedgers.findIndex((l) => l.currency === sw.currencyType) > -1;
      const priceLoading = !isNFT && sw.currentPrice === null ? true : false;
      return { ...sw, nft: isNFT, priceLoading };
    });

    const pricesLoading = smartWalletsComputed.filter((x) => x.priceLoading);
    yield put(smartWalletActions.setPricesLoading(pricesLoading.length > 0));

    smartWalletsComputed.sort(sortByCurrencyType);

    const { enabled, running, violationsByCurrency } = response.body;

    yield put(
      smartWalletActions.receiveSmartWalletList({
        smartWalletRunning: running,
        smartWalletEnabled: enabled,
        smartWalletViolations: violationsByCurrency,
        smartWalletList: smartWalletsComputed,
      })
    );

    // if SMART wallet has finished calc cost basis
    if (
      response.body.running === false && // not currently running
      smartWalletRunning === true && // was running last update
      typeof ledgers !== "undefined" && // there are ledgers
      ledgers.length > 0
    ) {
      yield put(resetWorksheetCache());
      const messageId = generateGUID();
      yield all([
        put(
          addFlashMessage(
            lookupTranslation(
              COST_BASIS_COMPLETE_MESSAGE,
              COST_BASIS_COMPLETE_MESSAGE_PATH
            ),
            messageId,
            STATUS_INFO
          )
        ),
      ]);

      yield delay(HALF_MINUTE);
      yield put(removeFlashMessage(messageId));
    }
  } else {
    return;
  }
}

export default fetchSmartWalletList;
