import { makeActionCreator } from "app.actions/helpers";
/******************************************************************************/
/* supportedExchanges */
/******************************************************************************/
export const SUPPORTED_EXCHANGES_ERROR = "SUPPORTED_EXCHANGES_ERROR";
export const SUPPORTED_EXCHANGES_BEGIN = "SUPPORTED_EXCHANGES_BEGIN";
export const SUPPORTED_EXCHANGES_RECEIVE = "SUPPORTED_EXCHANGES_RECEIVE";
export const SUPPORTED_EXCHANGES_REQUEST = "SUPPORTED_EXCHANGES_REQUEST";

export const requestSupportedExchanges = makeActionCreator(
  SUPPORTED_EXCHANGES_REQUEST,
  "nextStatus"
);
export const receiveSupportedExchanges = makeActionCreator(
  SUPPORTED_EXCHANGES_RECEIVE,
  "sortedExchanges",
  "supportedCurrencies",
  "custodialWallets",
  "evmWallets",
  "apiBasedExchanges",
  "nextStatus"
);

export const receiveSupportedExchangesError = makeActionCreator(
  SUPPORTED_EXCHANGES_ERROR,
  "response"
);

export const fetchSupportedExchanges = makeActionCreator(
  SUPPORTED_EXCHANGES_BEGIN
);
