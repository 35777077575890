import { testLocalStorage } from "app.utils";
import { LOCAL_STORAGE_KEY } from "app.constants";
import { receiveAuthorizeUser } from "app.reducers/user";
import * as enrollmentActions from "app.actions/enrollment";

const enrollmentStorageKey = `${LOCAL_STORAGE_KEY}-enrol`;

const resetInfo = (
  state,
  accountOverrides = {},
  billingOverrides = {},
  locationOverrides = {},
  organizationOverrides = {}
) => {
  return {
    accountInformation: {
      ...state.accountInformation,
      ...accountOverrides,
      accountError: undefined,
      emailError: undefined,
    },
    billingInformation: {
      ...state.billingInformation,
      ...billingOverrides,
      billingError: undefined,
    },
    locationInformation: {
      ...state.locationInformation,
      ...locationOverrides,
      locationError: undefined,
    },
    organizationInformation: {
      ...state.organizationInformation,
      ...organizationOverrides,
      organizationError: undefined,
    },
  };
};

let initialState = {
  referredBy: undefined,
  enrollablePlans: [],
  isLoggedIn: false,
  hasExistingCardInfo: false,
  enrollmentLoaded: false,
  currentlySelectedPlanId: undefined,
  queryingEnrollmentData: false,
  enrollmentSubmitting: false,
  planQuote: undefined,
  planQuoteStatus: "unset",
  accountInformation: {
    accountError: undefined,
    emailError: undefined,
    email: "",
    password: "",
    confirmPassword: "",
    acceptTOS: false,
  },
  billingInformation: {
    billingError: undefined,
    useCurrentBillingInfo: false,
    coupon: "",
    cardValid: false,
    couponStatus: "unset",
    cardholderName: "",
  },
  locationInformation: {
    locationError: undefined,
    zipCode: "",
  },
  organizationInformation: {
    organizationError: undefined,
    organizationName: "",
    profileLimit: 1,
  },
  enrollmentError: undefined,
};

// if local storage is supported, we rehydrate some of the initial state from localStorage (if present)
if (testLocalStorage()) {
  try {
    if (typeof localStorage[enrollmentStorageKey] !== "undefined") {
      const savedState = JSON.parse(localStorage[enrollmentStorageKey]) || {};
      initialState = { ...initialState, ...savedState };
    }
  } catch (e) {
    console.log(e);
  }
}

const enrollment = (state = initialState, action) => {
  switch (action.type) {
    case enrollmentActions.ENROLLMENT_CHECKOUT_LEAVE: {
      return {
        ...state,
        planQuote: undefined,
        planQuoteStatus: "unset",
        ...resetInfo(state, undefined, { coupon: "" }, undefined, undefined),
      };
    }
    case enrollmentActions.ENROLLMENT_CHECKOUT_ENTER: {
      return {
        ...state,
        planQuoteStatus: "unset",
        ...resetInfo(state),
      };
    }
    case enrollmentActions.BILLING_ERROR_SET: {
      return {
        ...state,
        billingInformation: {
          ...state.billingInformation,
          billingError: action.billingError,
        },
      };
    }
    case enrollmentActions.ORGANIZATION_ERROR_SET: {
      return {
        ...state,
        organizationInformation: {
          ...state.organizationInformation,
          organizationError: action.organizationError,
        },
      };
    }

    case enrollmentActions.ACCOUNT_ERROR_SET: {
      return {
        ...state,
        accountInformation: {
          ...state.accountInformation,
          accountError: action.accountError,
        },
      };
    }

    case enrollmentActions.ACCOUNT_EMAIL_ERROR: {
      return {
        ...state,
        accountInformation: {
          ...state.accountInformation,
          emailError: action.emailError,
        },
      };
    }

    case enrollmentActions.EMAIL_SET: {
      return {
        ...state,
        accountInformation: {
          ...state.accountInformation,
          email: action.email,
        },
      };
    }

    case enrollmentActions.PASSWORD_SET: {
      return {
        ...state,
        accountInformation: {
          ...state.accountInformation,
          password: action.password,
        },
      };
    }

    case enrollmentActions.CONFIRM_PASSWORD_SET: {
      return {
        ...state,
        accountInformation: {
          ...state.accountInformation,
          confirmPassword: action.confirmPassword,
        },
      };
    }

    case enrollmentActions.TOS_SET: {
      return {
        ...state,
        accountInformation: {
          ...state.accountInformation,
          acceptTOS: action.acceptTOS,
        },
      };
    }

    case enrollmentActions.USE_CURRENT_BILLING_INFO_SET: {
      return {
        ...state,
        billingInformation: {
          ...state.billingInformation,
          billingError: undefined,
          useCurrentBillingInfo: action.useCurrentInfo,
        },
      };
    }

    case enrollmentActions.CARDHOLDER_NAME_SET: {
      return {
        ...state,
        billingInformation: {
          ...state.billingInformation,
          cardholderName: action.cardholderName,
        },
      };
    }

    case enrollmentActions.CARD_VALIDITY_SET: {
      return {
        ...state,
        billingInformation: {
          ...state.billingInformation,
          cardValid: action.cardValid,
        },
      };
    }

    case enrollmentActions.COUPON_SET: {
      return {
        ...state,
        billingInformation: {
          ...state.billingInformation,
          coupon: action.coupon,
        },
      };
    }

    case enrollmentActions.COUPON_CLEAR: {
      return {
        ...state,
        billingInformation: {
          ...state.billingInformation,
          coupon: "",
        },
      };
    }

    case enrollmentActions.ZIP_SET: {
      return {
        ...state,
        locationInformation: {
          ...state.locationInformation,
          zipCode: action.zipCode,
        },
        queryingEnrollmentData: true,
      };
    }

    case enrollmentActions.ORGANIZATION_NAME_SET: {
      return {
        ...state,
        organizationInformation: {
          ...state.organizationInformation,
          organizationName: action.organizationName,
        },
      };
    }

    case enrollmentActions.ORGANIZATION_PROFILE_LIMIT_SET: {
      return {
        ...state,
        organizationInformation: {
          ...state.organizationInformation,
          profileLimit: action.profileLimit,
        },
        queryingEnrollmentData: true,
      };
    }

    case enrollmentActions.ENROLL_BEGIN: {
      return {
        ...state,
        ...resetInfo(state),
      };
    }

    case enrollmentActions.ENROLLMENT_PLAN_QUOTE_REQUEST: {
      return {
        ...state,
        planQuoteStatus: "loading",
        ...resetInfo(state),
      };
    }

    case enrollmentActions.ENROLLMENT_PLAN_QUOTE_RECEIVE: {
      return {
        ...state,
        planQuote: action.planQuote,
        planQuoteStatus: "loaded",
        queryingEnrollmentData: !action.completedQueries,
      };
    }

    case enrollmentActions.ENROLLMENT_PLAN_QUOTE_ERROR: {
      return { ...state, queryingEnrollmentData: !action.completedQueries };
    }

    case enrollmentActions.SELECT_PLAN_FOR_CHECKOUT: {
      return persistState({
        ...state,
        currentlySelectedPlanId: action.planId,
      });
    }
    case enrollmentActions.ENROLLING_REQUEST: {
      return { ...state, enrollmentSubmitting: true };
    }
    case enrollmentActions.ENROLLING_RECEIVE_ERROR: {
      return {
        ...state,
        enrollmentSubmitting: false,
        enrollmentError: action.error,
      };
    }
    case enrollmentActions.ENROLLING_RECEIVE: {
      return { ...state, enrollmentSubmitting: false };
    }
    case enrollmentActions.ENROLLMENT_LOADED: {
      return {
        ...state,
        enrollmentSubmitting: false,
        enrollmentLoaded: true,
        enrollablePlans: action.plans,
        referredBy: action.referral,
        isLoggedIn: action.isLoggedIn,
      };
    }
    case receiveAuthorizeUser.type: {
      return { ...state, isLoggedIn: true };
    }
    case enrollmentActions.ENROLLMENT_ENTER: {
      return { ...state, enrollmentLoaded: false };
    }
    case enrollmentActions.SET_REFERRER: {
      return {
        ...state,
        referredBy: action.referredBy,
        promotionalPlans: action.plansWithPromo,
      };
    }
    default:
      return state;
  }
};

export default enrollment;

// we don't want to persist all state to local storage, so when a some UI actions happen,
// we'll pull out the keys we are interested in and save them
function persistState(state) {
  if (testLocalStorage()) {
    const { currentlySelectedPlanId } = state;

    localStorage[enrollmentStorageKey] = JSON.stringify({
      currentlySelectedPlanId,
    });
  }
  return state;
}
