import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  getWeeksInMonth,
  startOfWeek,
  startOfMonth,
  addDays,
  addMonths,
  addYears,
} from "date-fns";
import CalendarWeek from "./CalendarWeek";
import SvgLoader from "app.components/Util/SvgLoader";
import Button from "app.components/Util/Button";
import { toZonedTime } from "date-fns-tz";
import { useGetUserTimeZone } from "app.hooks";

const CalendarPicker = ({
  isStart = false,
  isEnd = false,
  noRange = false,
  selectedPreset,
  selectedDate,
  setSelectedDate,
  otherDate,
  open,
  blockFutureDates = false,
  minSelectableDate,
  maxSelectableDate,
  enforceMaxVisible = false,
}) => {
  const { t } = useTranslation();
  const userTimeZone = useGetUserTimeZone();

  const today = useMemo(
    () => toZonedTime(new Date(), userTimeZone),
    [userTimeZone]
  );

  let visibleDay = today;
  if (enforceMaxVisible) {
    visibleDay = maxSelectableDate;
  }

  let [visibleMonth, setVisibleMonth] = useState(
    typeof selectedDate === "undefined"
      ? startOfMonth(addDays(visibleDay, isEnd || noRange ? 0 : -30))
      : startOfMonth(selectedDate)
  );

  const nextVisibleMonth = useMemo(() => {
    return typeof selectedDate === "undefined"
      ? startOfMonth(addDays(visibleDay, isEnd || noRange ? 0 : -30))
      : startOfMonth(selectedDate);
  }, [selectedDate, isEnd, noRange, visibleDay]);

  // when selected date changes, update visible date
  useEffect(() => {
    setVisibleMonth(nextVisibleMonth);
  }, [selectedDate, selectedPreset, nextVisibleMonth]);

  // whenever the picker closes, reset visible window.
  useEffect(() => {
    if (!open) {
      setVisibleMonth(nextVisibleMonth);
    }
  }, [open, nextVisibleMonth]);

  const weeksInMonth = getWeeksInMonth(visibleMonth);
  const monthStart = startOfMonth(visibleMonth);
  const startOfFirstWeek = startOfWeek(monthStart);

  const weeks = [];

  for (let w = 0; w < weeksInMonth; w++) {
    weeks.push(
      <CalendarWeek
        key={`week${w}`}
        open={open}
        firstDayOfWeek={addDays(startOfFirstWeek, w * 7)}
        firstDayOfMonth={monthStart}
        setDate={setSelectedDate}
        selectedDate={selectedDate}
        otherDate={otherDate}
        isEnd={isEnd}
        isStart={isStart}
        blockFutureDates={blockFutureDates}
        minSelectableDate={minSelectableDate}
        maxSelectableDate={maxSelectableDate}
        today={today}
      />
    );
  }

  const previousMonth = startOfMonth(addMonths(visibleMonth, -1));
  const nextMonth = startOfMonth(addMonths(visibleMonth, 1));
  const previousYear = startOfMonth(addYears(visibleMonth, -1));
  const nextYear = startOfMonth(addYears(visibleMonth, 1));

  return (
    <div className="flex flex-col justify-center">
      <div className="flex items-center justify-between">
        <div className="flex">
          <Button
            buttonType="pagination"
            buttonSize="xs"
            customClasses="pl-0 pr-0"
            onClick={(e) => {
              e.preventDefault();
              setVisibleMonth(previousYear);
            }}
            disabled={!open}
          >
            <SvgLoader
              id="Chevron"
              className="h-4 w-4 rotate-180 stroke-black"
              title=""
            />
            <SvgLoader
              id="Chevron"
              className="-ml-2 h-4 w-4 rotate-180 stroke-black"
              title=""
            />
            <span className="sr-only">{t("utils.backOneYear")}</span>
          </Button>
          <Button
            buttonType="pagination"
            buttonSize="xs"
            customClasses="pl-0 pr-0"
            onClick={(e) => {
              e.preventDefault();
              setVisibleMonth(previousMonth);
            }}
            disabled={!open}
          >
            <SvgLoader
              id="Chevron"
              className="h-4 w-4 rotate-180 stroke-black"
              title=""
            />
            <span className="sr-only">{t("utils.backOneMonth")}</span>
          </Button>
        </div>

        <div className="flex w-32 flex-auto select-none justify-center text-sm">
          {t(`constants.dateTime.months.${visibleMonth.getMonth()}`)}&nbsp;
          {visibleMonth.getFullYear()}
        </div>

        <div className="flex">
          <Button
            buttonType="pagination"
            buttonSize="xs"
            title={`${t("utils.forwardOneMonth")}`}
            customClasses="pl-0 pr-0"
            onClick={(e) => {
              e.preventDefault();
              setVisibleMonth(nextMonth);
            }}
            disabled={!open}
          >
            <SvgLoader id="Chevron" className="h-4 w-4 stroke-black" title="" />
            <span className="sr-only">{t("utils.forwardOneMonth")}</span>
          </Button>
          <Button
            buttonType="pagination"
            buttonSize="xs"
            title={`${t("utils.forwardOneYear")}`}
            onClick={(e) => {
              e.preventDefault();
              setVisibleMonth(nextYear);
            }}
            customClasses="pl-0 pr-0"
            disabled={!open}
          >
            <SvgLoader id="Chevron" className="h-4 w-4 stroke-black" title="" />
            <SvgLoader
              id="Chevron"
              className="-ml-2  h-4 w-4 stroke-black"
              title=""
            />
            <span className="sr-only">{t("utils.forwardOneYear")}</span>
          </Button>
        </div>
      </div>

      <div className="mt-1 flex justify-center">
        <table>
          <thead>
            <tr>
              {Array.from(Array(7).keys()).map((i, index) => {
                const day = t(`constants.dateTime.days.${i}`);
                return (
                  <th
                    className="px-1 text-center text-sm font-normal leading-5 text-zinc-400"
                    key={day.substring(0, 2) + index}
                  >
                    {day.substring(0, 2)}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {weeks}
            {Array.from(Array(6 - weeksInMonth).keys()).map((i, index) => (
              <tr key={index}>
                <td
                  id="calendar-extra-week-space"
                  className="pointer-events-none mb-1 h-8 w-8"
                ></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

CalendarPicker.displayName = "CalendarPicker";
export default CalendarPicker;
