import { put, call, select } from "redux-saga/effects";
import {
  setReferrer,
  enrollmentSectionLoaded,
  enrollmentQuerying,
  setUseCurrentBillingInfo,
} from "app.actions/enrollment";
import enterSubscriptionSection from "app.sagas/account/enterSubscriptionSection";
import fetchUser from "app.sagas/user/fetchUser";
import { parseCookie } from "app.utils";
import TellerAPI from "app.api/TellerAPI";
import { DEFAULT_PLAN_ID, AVAILABLE_PLANS } from "app.constants/plans";

function* enterEnrollmentSection(action) {
  const userToken = yield select((state) => state.user.token);
  const isLoggedIn = typeof userToken !== "undefined";

  if (isLoggedIn) {
    yield* fetchUser();
    yield* enterSubscriptionSection();

    const userBillingInfo = yield select(
      (state) => state.account.billingInformation
    );
    const userHasBillingInfo =
      typeof userBillingInfo !== "undefined" && userBillingInfo !== null;

    if (userHasBillingInfo) {
      yield put(setUseCurrentBillingInfo(true));
    }
  }

  // Store if we've got a user
  const referral = yield* getReferralInformation();
  const hasReferral = typeof referral !== "undefined";
  if (hasReferral) {
    yield put(setReferrer(referral));
  }

  const referralCode = hasReferral ? referral.code : undefined;

  let couponCode = undefined;
  let profileLimit = undefined;
  let zipCode = undefined;

  yield put(enrollmentQuerying());

  const getPlansCall = isLoggedIn
    ? TellerAPI.getPaymentProductsForUser
    : TellerAPI.getPaymentProductsForEnrollment;

  const planResponse = yield call(
    getPlansCall,
    couponCode || referralCode,
    zipCode,
    profileLimit
  );

  const plansReceived = planResponse.body.reduce(
    (acc, product) => [...acc, ...product.plans],
    []
  );

  const currentUserPlan = isLoggedIn
    ? plansReceived.filter((x) => x.subscribed || x.subscribedThroughPromo)[0]
    : undefined;

  const defaultPlan = {
    subscribed: isLoggedIn && typeof currentUserPlan === "undefined",
    planId: DEFAULT_PLAN_ID,
    isDefaultPlan: true,
    active: currentUserPlan ? false : true,
  };

  const plans = AVAILABLE_PLANS.reduce((acc, plan) => {
    const findPlan = plansReceived.find(({ planId }) => planId === plan.id);

    if (plan.id === DEFAULT_PLAN_ID)
      acc.push({ ...defaultPlan, level: plan.level });
    if (findPlan) acc.push({ ...findPlan, level: plan.level });
    if (plan.subPlans) {
      const subPlans = plan.subPlans.map((id) =>
        plansReceived.find(({ planId }) => planId === id)
      );

      acc.push({
        planId: plan.id,
        level: plan.level,
        subPlans,
      });
    }

    return acc;
  }, []).sort((a, b) => a.level - b.level);

  yield put(enrollmentSectionLoaded(isLoggedIn, referral, plans));
}

function* getReferralInformation() {
  const cookieData = parseCookie(document.cookie);
  const referral = cookieData.NODE40_REFERRAL;
  if (typeof referral === "undefined") return;
  const referralCode = referral.code;
  const promoValidResponse = yield call(TellerAPI.checkPromoCode, referralCode);
  const isPlanValid = promoValidResponse.body.active;
  if (!isPlanValid) return undefined;
  return referral;
}

export default enterEnrollmentSection;
