import * as apiKeys from "./apiKeys";
import * as accountingPreferences from "./accountingPreferences";
import * as likeKind from "./likeKind";
import * as settings from "./settings";
import * as rollForward from "./rollForward";

const SettingsAPI = {
  ...apiKeys,
  ...accountingPreferences,
  ...likeKind,
  ...settings,
  ...rollForward,
};

export default SettingsAPI;
