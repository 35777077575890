export const APP_NAME = whitelabel.config.APP_NAME;

export const COMPANY_NAME = whitelabel.config.COMPANY_NAME;

export const BASE_PATH = "/";

export const LOCAL_STORAGE_KEY = `${import.meta.env.VITE_BUILD_CUSTOMER}-app`;

export const LOCAL_STORAGE_UI_PREFIX = `${
  import.meta.env.VITE_BUILD_CUSTOMER
}-ui`;
export const LOCAL_STORAGE_ORG_TOKEN_KEY = `${
  import.meta.env.VITE_BUILD_CUSTOMER
}-org-token`;
export const LOCAL_STORAGE_USER_TOKEN_KEY = `${
  import.meta.env.VITE_BUILD_CUSTOMER
}-auth-token`;
export const LOCAL_STORAGE_SSO_TOKEN_KEY = `${
  import.meta.env.VITE_BUILD_CUSTOMER
}-sso-token`;

export const PASSWORD_MIN_LENGTH = 14;

export const SESSION_STORAGE_KEY_UI = `${
  import.meta.env.VITE_BUILD_CUSTOMER
}-ui`;

export const SESSION_STORAGE_KEY_ORGS = `${
  import.meta.env.VITE_BUILD_CUSTOMER
}-app-orgs`;

export const SESSION_COPY_URL_ID = "scp";

export const SESSION_STORAGE_MISSING_LOGOS = "logolookup";
