import React, { Suspense, lazy } from "react";
import GenericPlaceholder from "app.components/Placeholder/GenericPlaceholder";

const ForgotPasswordContainer = lazy(() =>
  import("app.components/Account/ForgotPasswordContainer")
);

const ForgotPassword = () => {
  return (
    <Suspense fallback={<GenericPlaceholder />}>
      <ForgotPasswordContainer />
    </Suspense>
  );
};

export default ForgotPassword;
