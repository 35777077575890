// essentially reproduces event.path since that is not wideley supported.
export const getElementPath = function(target) {
  let path = [];
  while (target) {
    path.push(target);
    target = target.parentElement;
  }
  if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
    path.push(document);
  if (path.indexOf(window) === -1) path.push(window);
  return path;
};
