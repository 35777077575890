import api from "app.api/clients/exchange";

/**
 * Submits a request to get the custom fields names.
 *
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getCustomFieldsData = () => {
  return api.get(`/profile/customData`, {
    authenticated: true,
  });
};

/**
 * Submits a request to create custom fields.
 *
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @param {number} itemId - The id of the transaction/item
 * @returns {object}  response - The Response or Error message
 */
export const addLedgerCustomData = (ledgerId, itemId, data) => {
  return api.put(`/ledger/${ledgerId}/item/${itemId}/customData`, {
    data: data,
    authenticated: true,
  });
};

/**
 * Submits a request to delete custom fields.
 *
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @param {number} itemId - The id of the transaction/item
 * @returns {object}  response - The Response or Error message
 */
export const deleteLedgerCustomData = (ledgerId, itemId) => {
  return api.delete(`/ledger/${ledgerId}/item/${itemId}/customData`, {
    authenticated: true,
  });
};

/**
 * Submits a request to set the custom fields names.
 *
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const setCustomFieldsData = (data) => {
  return api.put(`/profile/customData`, {
    data,
    authenticated: true,
  });
};
