import React from "react";

import PageLink from "./PageLink";
import NextButton from "./NextButton";
import PreviousButton from "./PreviousButton";

function PaginationList({
  numPages,
  currentPage,
  location,
  type,
  onSelect,
  showPageLabel,
}) {
  const pages = [...Array(numPages)].map((x, pageIndex) => {
    return (
      <PageLink
        key={`page-${pageIndex}`}
        location={location}
        pageNumber={pageIndex}
        currentPage={currentPage}
        type={type}
        onSelect={onSelect}
        showPageLabel={showPageLabel}
      />
    );
  });

  return (
    <div className="flex items-center justify-center">
      <PreviousButton
        location={location}
        currentPage={currentPage}
        type={type}
        onSelect={onSelect}
      />
      {pages}
      <NextButton
        location={location}
        currentPage={currentPage}
        numPages={numPages}
        type={type}
        onSelect={onSelect}
      />
    </div>
  );
}
PaginationList.displayName = "PaginationList";
export default PaginationList;
