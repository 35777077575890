import { delay, select, call, put, all } from "redux-saga/effects";
import i18n from "i18next";

import { generateGUID } from "app.utils";

import { handleApiKeyError, handleWalletTokenError } from "app.actions/helpers";
import {
  requestDoExchangeLedgerImport,
  receiveDoExchangeLedgerImport,
  doExchangeLedgerImportError,
  fetchExchangeLedgerRange,
} from "app.actions/exchangeLedger";
import {
  fetchLedgers,
  updateExchangeAllLedgersError,
} from "app.actions/exchanges";
import {
  removeFlashMessage,
  addFlashMessage,
  openModal,
  openApiKeyModal,
  openWalletTokenModal,
  dismissModal,
} from "app.reducers/ui";

import { MODAL_EXCHANGE_OAUTH_POP_UP } from "app.constants/modals";
import { FIVE_SECONDS, TEN_SECONDS } from "app.constants";

import {
  STATUS_WARNING,
  STATUS_SUCCESS,
} from "app.components/Messages/FlashMessage";

import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import { MESSAGE_TYPES } from "app.components/Messages/MessageGenerator";

function* doExchangeLedgerImport(action) {
  const { ledgerIds, exchangeId, linkGUID } = action;

  yield put(requestDoExchangeLedgerImport(ledgerIds, linkGUID));

  const response = yield call(
    ExchangeLedgerAPI.doExchangeLedgerImport,
    ledgerIds
  );
  const { body, error } = response;

  if (error) {
    const exchange = yield select((state) =>
      state.exchanges.supportedExchanges.find((item) => item.id === exchangeId)
    );

    if (
      typeof body.metadata !== "undefined" &&
      typeof body.metadata.code !== "undefined"
    ) {
      switch (body.metadata.code) {
        case "oauthRequired":
          yield put(
            openModal(MODAL_EXCHANGE_OAUTH_POP_UP, undefined, {
              exchange: exchange,
              reLink: true,
            })
          );
          break;
        case "exchangeLinkInactive":
        case "apiKeyInvalid":
          yield put(
            handleApiKeyError({
              errorCode: body.metadata.code, // errorCode
              exchange,
              linkGUID,
              openApiKeyModal,
              errorCallback: doExchangeLedgerImportError,
              error,
              callLedgerImportAfterHandleKey: true,
              ledgerIds,
              exchangeId,
            })
          );
          break;
        case "apiTokenInvalid":
          yield put(
            handleWalletTokenError(
              body.metadata.code, // errorCode
              exchange,
              linkGUID,
              openWalletTokenModal,
              updateExchangeAllLedgersError,
              error,
              undefined,
              undefined
            )
          );
          break;
        default:
          break;
      }
      yield put(doExchangeLedgerImportError(body, error));
    }
  } else {
    yield all([
      put(receiveDoExchangeLedgerImport(body, ledgerIds, linkGUID)),
      put(fetchLedgers()),
      put(dismissModal()),
    ]);

    if (body.importResults.length === 0) {
      const messageId = generateGUID();
      yield put(
        addFlashMessage(
          i18n.t("messages.noTransactionsFoundMessage"),
          messageId,
          STATUS_WARNING
        )
      );

      yield delay(FIVE_SECONDS);
      yield put(removeFlashMessage(messageId));
    } else if (body.importResults[0].asyncImportInProgress !== true) {
      // if we are currently looking at a ledger that was just updated
      const currentLedgerSummary = yield select(
        (state) => state.exchangeLedger.ledgerSummary
      );

      if (typeof currentLedgerSummary !== "undefined") {
        const found = ledgerIds.findIndex(
          (item) => parseInt(item, 10) === currentLedgerSummary.ledgerId
        );
        if (found !== -1) {
          yield put(fetchExchangeLedgerRange(currentLedgerSummary.ledgerId));
        }
      }

      // report to the UI
      const ledgers = yield select((state) => state.exchanges.ledgers);

      if (body.importResults.length > 0) {
        const importMessageData = body.importResults.map((importResult) => {
          const messageId = generateGUID();
          const message = {
            type: MESSAGE_TYPES.LEDGER_CREATED,
            ledgers: ledgers,
            importResult: importResult,
          };
          return {
            // eslint-disable-next-line redux-saga/yield-effects
            removeFlashMessageAction: put(removeFlashMessage(messageId)),
            // eslint-disable-next-line redux-saga/yield-effects
            putAction: put(addFlashMessage(message, messageId, STATUS_SUCCESS)),
          };
        });

        yield all(importMessageData.map((x) => x.putAction));
        yield delay(TEN_SECONDS);
        yield all(importMessageData.map((x) => x.removeFlashMessageAction));
      }
    }
  }
}

export default doExchangeLedgerImport;
