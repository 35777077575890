import React from "react";
import CashMoney from "app.components/Util/CashMoney";

import { isFromReferral, isOnNormalSale } from "app.utils";
import { useTranslation, Trans } from "react-i18next";

function PlanCost({ plan, referrer }) {
  const { t } = useTranslation();
  // things we care about:
  // is there an availableCouponId and is it a referall
  // is this seat based
  // what is the amountDollars (normal cost)
  // what is the subtotalAmountDollars

  const fromReferral = isFromReferral(plan, referrer);
  const fromNormalSale = isOnNormalSale(plan, referrer);

  // removed prorated price display from plan card
  // left here for now, because maybe...'

  //  const { planDetails } = plan;'

  // const isProrated =
  //   !planDetails.seatBased &&
  //   plan.adjustedAmountDollars !== plan.amountDollars &&
  //   plan.prorationType === "upgrade";
  // {isProrated ? (
  //   <p className="flex flex-col">
  //     <CashMoney
  //       className="line-through text-zinc-500 text-xs"
  //       size="none"
  //       dollars={plan.amountDollars}
  //       fiatOverride="USD"
  //     />
  //     <CashMoney
  //       size="none"
  //       dollars={plan.subtotalAmountDollars}
  //       fiatOverride="USD"
  //     />
  //   </p>
  // ) :(
  // <CashMoney
  //   size="none"
  //   dollars={plan.subtotalAmountDollars}
  //   fiatOverride="USD"
  // />
  // )
  //

  if (fromReferral || fromNormalSale) {
    return (
      <React.Fragment>
        <p className="flex items-center font-bold leading-none">
          <s className="opacity-70">
            <CashMoney
              size="none"
              dollars={plan.amountDollars}
              fiatOverride="USD"
            />
          </s>
          &nbsp;
          <CashMoney
            size="none"
            dollars={plan.subtotalAmountDollars}
            fiatOverride="USD"
          />
        </p>
        {fromNormalSale ? (
          <p className="font-italic text-xs text-red-700">
            {t("modals.limitedTimeDiscount")}
          </p>
        ) : (
          <p className="font-italic text-xs">
            <Trans i18nKey="modals.firstYearDiscountFromReferrer">
              <em>{{ referrerName: "node40" || referrer.referrerName }}</em>
            </Trans>
          </p>
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="c_plan-cost flex items-center font-bold leading-none">
        <CashMoney
          size="none"
          dollars={plan.amountDollars}
          fiatOverride="USD"
        />
      </div>
    </React.Fragment>
  );
}

PlanCost.displayName = "PlanCost";
export default PlanCost;
