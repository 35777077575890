import i18n from "i18next";

const generateTens = (firstValue, secondValue, divisor) => {
  if (firstValue < 2) {
    return i18n.t(
      `constants.numbers.${parseInt(`${firstValue}${secondValue}`)}`
    );
  }
  const firstPart = firstValue
    ? i18n.t(`constants.numbers.tens.${firstValue}`)
    : "";

  const secondPart = secondValue
    ? i18n.t(`constants.numbers.${secondValue}`)
    : "";

  return `${firstPart}${(secondPart && divisor) || ""}${secondPart}`;
};

export const numToWord = function (num, localeFormat) {
  const numAsInt = parseInt(num, 10);

  if (numAsInt >= 0 && numAsInt < 100) {
    const firstValue = parseInt(numAsInt / 10);
    const secondValue = numAsInt % 10;
    const divisor = i18n.t("constants.numbers.divisor");
    return generateTens(firstValue, secondValue, divisor);
  }

  if (numAsInt >= 100 && numAsInt < 1000) {
    const firstValue = parseInt(numAsInt / 100);
    const secondValue = parseInt(numAsInt % 100);

    if (secondValue <= 0) {
      return i18n.t(`constants.numbers.hundreds.${firstValue}`);
    }
  }

  if (numAsInt >= 1000 && numAsInt <= 10000) {
    const firstValue = parseInt(numAsInt / 1000);
    const secondValue = parseInt(numAsInt % 1000);

    if (secondValue <= 0) {
      return i18n.t(`constants.numbers.thousands.${firstValue}`);
    }
  }
  return localeFormat ? numAsInt.toLocaleString(i18n.language) : numAsInt;
};
