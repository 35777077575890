// when fetching supported exchanges or linked exchanges, we may want to adjust
// some of the fields for various reasons
// this function _SHOULD_ only ever be called when the data is retrieved, as
// it mutates the data _BEFORE_ it is stored in the Redux Store.
import { SHADOW_EXCHANGE, EXCHANGE_IDS, SOURCELESS } from "app.constants";

export const applySourceOverrides = (sourceList) => {
  sourceList.forEach((exchange) => {
    if (exchange.name === EXCHANGE_IDS.COINBASE_UPLOAD) {
      exchange.label = "Coinbase";
    }
    if (exchange.name === EXCHANGE_IDS.BITTREX_UPLOAD) {
      exchange.label = "Bittrex";
    }
    if (exchange.name === EXCHANGE_IDS.GEMINI_UPLOAD) {
      exchange.label = "Gemini";
    }
    if (exchange.name === EXCHANGE_IDS.GEMINI_UPLOAD) {
      exchange.label = "Gemini";
    }
    if (exchange.name === EXCHANGE_IDS.GENERIC) {
      exchange.label = "Custom Upload";
    }
    if (exchange.name === EXCHANGE_IDS.KOINLY) {
      exchange.sourceSelectionLabel = "Migrate from Koinly";
    }
    if (exchange.type === SHADOW_EXCHANGE) {
      exchange.label = "Tracking Ledgers";
    }
    if (exchange.type === SOURCELESS) {
      exchange.label = "Manual Ledgers";
    }
  });
};
