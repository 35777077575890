import React, { useState, useMemo } from "react";
import Big from "big.js";
import { useTranslation } from "react-i18next";
import BaseTable from "app.components/TableList/BaseTable";
import Button from "app.components/Util/Button";
import CardIndicator from "app.components/Util/CardIndicator";
import ChainLogo from "app.components/Images/ChainLogo";
import SearchInput from "app.components/Util/Form/SearchInput";
import { formatCurrencyValue } from "app.utils/currencies";

const SelectableLedgersList = ({
  ledgers,
  selectedLedgers,
  setSelectedLedgers,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");

  const hasManyLedgers = ledgers.length > 3;
  const allLedgersSelected = selectedLedgers.length === ledgers.length;

  const selectAllLedgers = () => {
    if (allLedgersSelected) {
      setSelectedLedgers([]);
    } else {
      setSelectedLedgers(ledgers.map(({ ledger }) => ledger.id));
    }
  };

  const selectItem = (id, isSelected) => {
    setSelectedLedgers(
      isSelected
        ? selectedLedgers.filter((ledger) => ledger !== id)
        : [...selectedLedgers, id]
    );
  };

  const columns = [
    {
      label: t("common.token"),
      left: true,
      column: "currency",
      sticky: true,
      sortable: true,
      searchable: true,
      customSearch: (currency, search, row) =>
        currency.toLowerCase().includes(search) ||
        row.name.toLowerCase().includes(search),
      customRender: (currency, row) => (
        <div className="flex items-center gap-2">
          <ChainLogo coin={currency} small={true} />
          <div className="max-w-[200px]">
            <p title={row.name} className="truncate p-0">
              {row.name}
            </p>
            <p
              title={currency}
              className="truncate whitespace-nowrap p-0 text-xs text-black/75"
            >
              {currency}
            </p>
          </div>
        </div>
      ),
    },
    {
      label: t("common.balance"),
      right: true,
      column: "balance",
      sticky: true,
      sortable: true,
      customRender: (balance, row) => (
        <div className="flex items-center justify-end gap-1 whitespace-nowrap">
          <span>{formatCurrencyValue(balance)}</span>

          <span className="inline-block max-w-[200px] truncate">
            {row.currency}
          </span>
        </div>
      ),
    },
    {
      label: <span className="sr-only">{t("common.selected")}</span>,
      column: "isSelected",
      sticky: true,
      customClass: "w-16",
      customRender: (isSelected) => (
        <span className="flex justify-end">
          <CardIndicator disabled={false} type="square" active={isSelected} />
        </span>
      ),
    },
  ];

  const data = ledgers.map(({ name, ledger, currency }) => ({
    name,
    id: ledger.id,
    currency,
    balance: new Big(ledger.balanceAmount),
    isSelected: !!selectedLedgers.find((item) => item === ledger.id),
  }));

  const filteredData = useMemo(() => {
    const filterSplit = search
      .split(",")
      .filter((item) => !!item)
      .map((item) => item.trim().toLowerCase());

    if (!hasManyLedgers || !filterSplit.length) return data;

    return data.filter((item) => {
      for (let i = 0; i < filterSplit.length; i++) {
        const foundItem =
          item.name.toLowerCase().includes(filterSplit[i]) ||
          item.currency.toLowerCase().includes(filterSplit[i]);

        if (foundItem) return true;
      }
      return false;
    });
  }, [data, hasManyLedgers, search]);

  return (
    <React.Fragment>
      <div className="my-4 rounded bg-white shadow">
        {hasManyLedgers ? (
          <div className="p-2">
            <SearchInput
              fullWidth
              clearCallback={() => setSearch("")}
              placeholder={t("input.placeholder.searchForOneOrMoreCurrencies")}
              value={search}
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
            />
          </div>
        ) : null}
        <BaseTable
          tableCustomClass="min-h-[300px] max-h-[300px] overflow-y-scroll"
          columns={columns}
          data={filteredData}
          sortable
          handleColumnClick={(e, x) => {
            e.preventDefault();
            selectItem(x.id, x.isSelected);
          }}
          recordsShowing={true}
        />
        <div className="flex items-center justify-between border-t px-4 py-2">
          <div>
            {filteredData.length !== ledgers.length ? (
              <p className="text-sm text-zinc-500">
                {t("common.showingLedgers", {
                  count: ledgers.length,
                  visibleCount: filteredData.length,
                })}
              </p>
            ) : null}
          </div>
          <Button buttonSize="sm" buttonType="text" onClick={selectAllLedgers}>
            <div className="justify- flex items-center">
              <p className="mr-2">
                {t("common.selectAllLedgers", { count: ledgers.length })}
              </p>
              <CardIndicator
                disabled={false}
                type="square"
                active={allLedgersSelected}
              />
            </div>
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SelectableLedgersList;
