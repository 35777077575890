import { makeActionCreator } from "app.actions/helpers";

export const CREATE_MANUAL_TRANSFER_LINK_BEGIN =
  "CREATE_MANUAL_TRANSFER_LINK_BEGIN";
export const CREATE_MANUAL_TRANSFER_LINK_REQUEST =
  "CREATE_MANUAL_TRANSFER_LINK_REQUEST";
export const CREATE_MANUAL_TRANSFER_LINK_RECEIVE =
  "CREATE_MANUAL_TRANSFER_LINK_RECEIVE";
export const CREATE_MANUAL_TRANSFER_LINK_ERROR =
  "CREATE_MANUAL_TRANSFER_LINK_ERROR";

export const createManualTransferLink = makeActionCreator(
  CREATE_MANUAL_TRANSFER_LINK_BEGIN,
  "itemIds",
);

export const requestCreateManualTransferLink = makeActionCreator(
  CREATE_MANUAL_TRANSFER_LINK_REQUEST,
  "nextStatus",
);

export const receiveCreateManualTransferLink = makeActionCreator(
  CREATE_MANUAL_TRANSFER_LINK_RECEIVE,
  "nextStatus",
);

export const errorCreateManualTransferLink = makeActionCreator(
  CREATE_MANUAL_TRANSFER_LINK_ERROR,
  "message",
  "nextStatus",
);
