import { all, spawn } from "redux-saga/effects";
import accountSagas from "app.sagas/account";
import authSagas from "app.sagas/authentication";
import computableSagas from "app.sagas/computables";
import exchangeLedgerSagas from "app.sagas/exchangeLedger";
import exchangeSagas from "app.sagas/exchanges";
import organizationSagas from "app.sagas/organization";
import portfolioSagas from "app.sagas/portfolios";
import smartWalletSagas from "app.sagas/smartWallet";
import uiSagas from "app.sagas/ui";
import userSagas from "app.sagas/user";
import filesSagas from "app.sagas/files";
import enrollmentSagas from "app.sagas/enrollment";

export default function* rootSaga() {
  const sagas = [
    ...accountSagas,
    ...authSagas,
    ...computableSagas,
    ...exchangeLedgerSagas,
    ...exchangeSagas,
    ...organizationSagas,
    ...portfolioSagas,
    ...smartWalletSagas,
    ...uiSagas,
    ...userSagas,
    ...filesSagas,
    ...enrollmentSagas,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield saga;
            break;
          } catch (e) {
            console.log(e);
          }
        }
      })
    )
  );
}
