import { call, put } from "redux-saga/effects";
import * as organizationActions from "app.reducers/organizations";
import OrganizationAPI from "app.api/OrganizationAPI";

function* fetchOrganization(action) {
  const response = yield call(OrganizationAPI.getOrganizationDetails);

  if (response.error) {
    console.error("Error in fetchOrganization", response.body, response.error);
  } else {
    yield put(organizationActions.receiveUserOrganization(response.body));
  }
}

export default fetchOrganization;
