import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { dismissModal, bulkCreateTag } from "app.reducers/ui";

import { useTranslation } from "react-i18next";
import Button from "app.components/Util/Button";
import Label from "app.components/Util/Form/Label";
import Input from "app.components/Util/Form/Input";

import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";

function ModalBulkTags({ entryIds, isTimeline = true }) {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();
  const [newTag, setNewTag] = useState("");

  const bulkTagsWorking = useSelector(
    (state) => state.exchangeLedger.bulkTagsWorking
  );
  const { existingTags } = useSelector((state) => state.ui);

  const handleDismiss = (e) => {
    e.preventDefault();
    reduxDispatch(dismissModal());
  };

  const submitTag = () => {
    reduxDispatch(bulkCreateTag(entryIds, newTag, isTimeline));
  };

  const count = entryIds.length || 0;

  return (
    <React.Fragment>
      <ModalHeader
        title={t("modals.bulkTags.applyTagToTransactions", { count })}
        closeCallback={handleDismiss}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitTag();
        }}
      >
        <div className="m-4">
          <p className="mb-4 text-lg">
            {t("modals.bulkTags.bulkTagsDescription", { count })}
          </p>
          <div className="mb-4 flex w-52 flex-col gap-1">
            <Label htmlFor="newTag">{t("modals.bulkTags.enterATag")}</Label>
            <Input
              id="newTag"
              name="newTag"
              type="text"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
            />
          </div>
          {existingTags.length > 0 ? (
            <div>
              <p className="mb-4">
                {t("modals.bulkTags.orChooseAnExistingTag")}
              </p>
              <div className="flex flex-wrap gap-2">
                {existingTags.map((tag, i) => (
                  <Button
                    type="button"
                    buttonType={newTag === tag ? "tag" : "tagAlt"}
                    key={i}
                    onClick={(e) => setNewTag(tag)}
                    title={tag}
                  >
                    <span className="max-w-[275px] truncate">{tag}</span>
                  </Button>
                ))}
              </div>
            </div>
          ) : null}
        </div>

        <ModalControls>
          <Button
            type="submit"
            disabled={bulkTagsWorking || newTag === ""}
            buttonType="primary"
            customClasses="ml-4"
            text={t("modals.bulkTags.applyButton", { count })}
          />
          <Button
            onClick={handleDismiss}
            disabled={bulkTagsWorking}
            buttonType="text"
            text={t("common.close")}
          />
        </ModalControls>
      </form>
    </React.Fragment>
  );
}

ModalBulkTags.displayName = "ModalBulkTags";

export default ModalBulkTags;
