import i18n from "i18next";

export const lookupTranslation = (entry, path, entryReturn, props) => {
  const key = path ? `${path}.${entry}` : entry;

  if (i18n.exists(key)) return i18n.t(key, props);

  if (entryReturn && i18n.exists(entryReturn)) return i18n.t(entryReturn);

  return entryReturn || entry;
};
