import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";

import PortfolioPlaceholder from "./PortfolioPlaceholder";
import PortfolioNewPlaceholder from "./PortfolioNewPlaceholder";
import WalletPlaceholderRouter from "./WalletPlaceholderRouter";
import TimelinePlaceholder from "./TimelinePlaceholder";
import AccountPlaceholder from "./AccountPlaceholder";
import GenericPlaceholder from "./GenericPlaceholder";

import { useTranslation } from "react-i18next";

const PlaceholderRouter = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <p className="sr-only">{t("common.loading_withEllipsis")}</p>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route index element={<TimelinePlaceholder />} />
          <Route path="smart" element={<GenericPlaceholder />} />
          <Route path="forms" element={<GenericPlaceholder />} />
          <Route path="reports" element={<GenericPlaceholder />} />
          <Route path="archived" element={<GenericPlaceholder />} />
          <Route path="settings/*" element={<GenericPlaceholder />} />
          <Route path="new/*" element={<PortfolioNewPlaceholder />} />
          <Route path="add-ledger/*" element={<GenericPlaceholder />} />
          <Route path="ledgers" element={<PortfolioPlaceholder />} />
          <Route
            path="ledger/:ledgerId/*"
            element={<WalletPlaceholderRouter />}
          />
          <Route
            path="smart/:smartWalletGUID"
            element={<WalletPlaceholderRouter />}
          />
          <Route path="account/*" element={<AccountPlaceholder />} />
        </Route>
      </Routes>
    </React.Fragment>
  );
};

PlaceholderRouter.displayName = "PlaceholderRouter";

export default PlaceholderRouter;

/*



<Route
path={`${match.url}/`}
render={(ownProps) => {
  return ;
}}
/>
</Routes>

*/
