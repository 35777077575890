import { makeActionCreator } from "app.actions/helpers";
/******************************************************************************/
/* disable like kind */
/******************************************************************************/
export const DISABLE_LIKE_KIND_BEGIN = "DISABLE_LIKE_KIND_BEGIN";
export const DISABLE_LIKE_KIND_REQUEST = "DISABLE_LIKE_KIND_REQUEST";
export const DISABLE_LIKE_KIND_RECEIVE = "DISABLE_LIKE_KIND_RECEIVE";
export const DISABLE_LIKE_KIND_ERROR = "DISABLE_LIKE_KIND_ERROR";

export const disableLikeKind = makeActionCreator(DISABLE_LIKE_KIND_BEGIN);

export const requestDisableLikeKind = makeActionCreator(
  DISABLE_LIKE_KIND_REQUEST,
  "nextStatus"
);

export const receiveDisableLikeKind = makeActionCreator(
  DISABLE_LIKE_KIND_RECEIVE,
  "nextStatus"
);

export const disableLikeKindError = makeActionCreator(
  DISABLE_LIKE_KIND_ERROR,
  "nextStatus"
);
