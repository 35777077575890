import React from "react";
import PlaceholderLine from "app.components/Placeholder/PlaceholderLine";

const LedgerHeaderPlaceholder = () => (
  <div className="c_ledger_header_placeholder mx-4 mt-5">
    <div className="flex items-center justify-between mb-2 h-10">
      <div>
        <PlaceholderLine width="w-72" />
      </div>

      <div className="ml-16">
        <p>
          <PlaceholderLine width="w-40" />
        </p>
      </div>
    </div>
    <div className="mb-4 flex flex-wrap gap-8 border-t">
      <div className="flex flex-col flex-1">
        <div className="h-6">
          <PlaceholderLine width="w-40" height="h-2" />
        </div>
        <div className="h-8">
          <PlaceholderLine width="w-40" />
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="h-6">
          <PlaceholderLine width="w-40" height="h-2" />
        </div>
        <div className="h-8">
          <PlaceholderLine width="w-40" />
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="h-6">
          <PlaceholderLine width="w-40" height="h-2" />
        </div>
        <div className="h-8">
          <PlaceholderLine width="w-40" />
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="h-6">
          <PlaceholderLine width="w-40" height="h-2" />
        </div>
        <div className="h-8">
          <PlaceholderLine width="w-40" />
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="h-6">
          <PlaceholderLine width="w-40" height="h-2" />
        </div>
        <div className="h-8">
          <PlaceholderLine width="w-40" />
        </div>
      </div>
    </div>
  </div>
);

LedgerHeaderPlaceholder.displayName = "LedgerHeaderPlaceholder";

export default LedgerHeaderPlaceholder;
