import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { requestSecurityVerification } from "app.actions/account";

import { ACCOUNT_STATUS } from "app.constants/loadingStates";

import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";
import { PasswordInput } from "app.components/Util/Form";

import ErrorMessage from "app.components/Util/ErrorMessage";
import Button from "app.components/Util/Button";

import { useTranslation } from "react-i18next";

function InitialScreen({ state, handleUpdate, handleDismiss }) {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();

  const { securityStatus, securityError } = useSelector(
    (state) => state.account
  );

  const blockSubmit = securityStatus === ACCOUNT_STATUS.UPDATING;

  const { currentPassword } = state;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (blockSubmit) return;
    reduxDispatch(requestSecurityVerification(currentPassword));
  };

  const disableForm = !currentPassword;

  return (
    <React.Fragment>
      <ModalHeader
        title={t("account.twoFactorAuthentication.twoFactorAuthentication")}
        closeCallback={handleDismiss}
      />
      <form onSubmit={handleSubmit} data-testid="modal-mfa-disabled-form">
        <div className="m-4">
          <ErrorMessage message={securityError} type={securityError} />
          <p className="my-4">
            {t("account.twoFactorAuthentication.intialScreenEnableInfo")}
          </p>

          <PasswordInput
            currentPassword={{
              value: currentPassword,
              label: t("input.label.password"),
              disabled: blockSubmit,
              callback: handleUpdate,
            }}
          />
        </div>
        <ModalControls>
          <Button
            type="submit"
            disabled={disableForm || blockSubmit}
            buttonType="primary"
            customClasses="ml-4"
            working={blockSubmit}
            text={t("button.enableTwoFactor")}
            workingText={t("button.working")}
          />
          <Button
            buttonType="text"
            onClick={handleDismiss}
            text={t("button.cancel")}
            disabled={blockSubmit}
          />
        </ModalControls>
      </form>
    </React.Fragment>
  );
}

InitialScreen.displayName = "InitialScreen";

export default InitialScreen;
