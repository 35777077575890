import { call, put } from "redux-saga/effects";
import { getLoadingState } from "app.utils/selectors";
import { sortExchanges } from "app.utils";
import {
  requestSupportedExchanges,
  receiveSupportedExchangesError,
  receiveSupportedExchanges,
} from "app.actions/exchanges";
import ExchangesAPI from "app.api/ExchangesAPI";

import { applySourceOverrides } from "app.utils";

import {
  STATUS,
  API_INTEGRATION_TYPES,
  SOURCE_TYPES,
  CUSTODIAL_WALLETS,
} from "app.constants";

function* fetchSupportedExchanges(action) {
  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.exchanges.supportedExchangesStatus
  );

  if (isLoading) return;

  yield put(requestSupportedExchanges(nextStatus));

  const response = yield call(ExchangesAPI.getSupportedExchanges);

  if (response.error) {
    yield put(receiveSupportedExchangesError(response.body, response.error));
  } else {
    const sortedExchanges = sortExchanges(response.body);

    applySourceOverrides(sortedExchanges);

    // give ALL exchanges a groupKey
    sortedExchanges.forEach((exchange) => {
      if (typeof exchange.groupKey === "undefined") {
        exchange.groupKey = exchange.name;
      }
    });

    const wallets = sortedExchanges.filter(
      (x) =>
        x.type === SOURCE_TYPES.WALLET &&
        CUSTODIAL_WALLETS.findIndex((w) => w === x.name) < 0 &&
        x.evm !== true &&
        x.smartContractPlatform !== true
    );

    const custodialWallets = sortedExchanges.filter(
      (x) => CUSTODIAL_WALLETS.findIndex((w) => w === x.name) > -1
    );

    const evmWallets = sortedExchanges.filter(
      (x) => x.evm === true || x.smartContractPlatform
    );

    const currencies = new Set();
    wallets.forEach((exchange) => {
      if (exchange.hidden !== true) {
        currencies.add(exchange.groupKey);
      }
    });

    const supportedCurrencies = [...currencies];

    const apiBasedExchanges = sortedExchanges.filter(
      (x) => API_INTEGRATION_TYPES.indexOf(x.integrationType) >= 0
    );

    yield put(
      receiveSupportedExchanges(
        sortedExchanges,
        supportedCurrencies,
        custodialWallets,
        evmWallets,
        apiBasedExchanges,
        STATUS.LOADED
      )
    );
  }
}

export default fetchSupportedExchanges;
