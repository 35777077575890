import { put, call } from "redux-saga/effects";
//import { setSettingsFiat } from "app.reducers/ui";
import TellerAPI from "app.api/TellerAPI";
import { refreshUserToken } from "app.actions/authentication";
import { fetchLedgers } from "app.actions/exchanges";
import { fetchSmartWalletList } from "app.reducers/smartWallet";

import {
  updateFiatRequest,
  updateFiatReceive,
  updateFiatError,
} from "app.actions/account";

function* handleUserFiat(action) {
  const { fiat } = action;
  yield put(updateFiatRequest());

  const response = yield call(TellerAPI.updateUserFiat, fiat);
  if (response.error) {
    yield put(updateFiatError());
  } else {
    yield put(refreshUserToken()); // ensure the cache version in local storage is properly updated
    yield put(fetchLedgers());
    yield put(fetchSmartWalletList());
    yield put(updateFiatReceive(fiat));
  }
}

export default handleUserFiat;
