import { takeEvery, takeLeading } from "redux-saga/effects";

import * as exchangeActions from "app.actions/exchanges";

// sagas

import bulkDeleteExchangeLedgers from "./bulkDeleteExchangeLedgers";
import bulkDeleteExchangeLedgersByIds from "./bulkDeleteExchangeLedgersByIds";
import bulkDeleteExchangeLedgersByCurrency from "./bulkDeleteExchangeLedgersByCurrency";
import bulkUpdateLedgersBySource from "./bulkUpdateLedgersBySource";
import connectExchangeViaAPI from "./connectExchangeViaAPI";
import connectExchangeViaOAuth from "./connectExchangeViaOAuth";
import createExchangeLedgers from "./createExchangeLedgers";
import createWalletLedgers from "./createWalletLedgers";
import createExchangeLedgersViaUpload from "./createExchangeLedgersViaUpload";
import deleteExchangeLedger from "./deleteExchangeLedger";
import disableLikeKind from "./disableLikeKind";
import enableLikeKind from "./enableLikeKind";
import fetchApiKeyVaults from "./fetchApiKeyVaults";
import importApiKeyVaults from "./importApiKeyVaults";
import fetchExchangeAccounts from "./fetchExchangeAccounts";
import fetchLedgers from "./fetchLedgers";
import fetchLinkedExchanges from "./fetchLinkedExchanges";
import fetchSettings from "./fetchSettings";
import fetchSupportedExchanges from "./fetchSupportedExchanges";
import pollSettings from "./pollSettings";
import rebuildExchangeLedgers from "./rebuildExchangeLedgers";
import renameLedger from "./renameLedger";
import renameLinkedAccount from "./renameLinkedAccount";
import unlinkExchange from "./unlinkExchange";
import updateExchangeAllLedgers from "./updateExchangeAllLedgers";
import updateExchangeAPI from "./updateExchangeAPI";
import updateExchangeAPIToken from "./updateExchangeAPIToken";
import updateExchangeLedgersViaAddressGroupings from "./updateExchangeLedgersViaAddressGroupings";
import updateExchangeLedgersViaUpload from "./updateExchangeLedgersViaUpload";
import verifyExchangeLink from "./verifyExchangeLink";
import updateRollforwardViaUpload from "./updateRollforwardViaUpload";
import migrateSources from "./migrateSources";
import migrateViaUpload from "./migrateViaUpload";

// watchers
const exchangeSagas = [
  takeLeading(exchangeActions.SETTINGS_BEGIN, fetchSettings),
  takeEvery(
    exchangeActions.BULK_DELETE_EXCHANGE_LEDGERS_BEGIN,
    bulkDeleteExchangeLedgers
  ),
  takeEvery(
    exchangeActions.BULK_DELETE_EXCHANGE_LEDGERS_BY_IDS_BEGIN,
    bulkDeleteExchangeLedgersByIds
  ),
  takeEvery(
    exchangeActions.BULK_DELETE_EXCHANGE_LEDGERS_BY_CURRENCY_BEGIN,
    bulkDeleteExchangeLedgersByCurrency
  ),
  takeEvery(
    exchangeActions.BULK_UPDATE_BY_SOURCE_BEGIN,
    bulkUpdateLedgersBySource
  ),
  takeEvery(exchangeActions.ENABLE_LIKE_KIND_BEGIN, enableLikeKind),
  takeEvery(exchangeActions.DISABLE_LIKE_KIND_BEGIN, disableLikeKind),
  takeLeading(
    exchangeActions.SUPPORTED_EXCHANGES_BEGIN,
    fetchSupportedExchanges
  ),
  takeLeading(exchangeActions.LINKED_EXCHANGES_BEGIN, fetchLinkedExchanges),
  takeEvery(
    exchangeActions.CONNECT_EXCHANGE_API_KEY_BEGIN,
    connectExchangeViaAPI
  ),
  takeEvery(
    exchangeActions.CONNECT_EXCHANGE_OAUTH_BEGIN,
    connectExchangeViaOAuth
  ),
  takeEvery(
    exchangeActions.CREATE_EXCHANGE_LEDGERS_BEGIN,
    createExchangeLedgers
  ),
  takeEvery(
    exchangeActions.CREATE_EXCHANGE_LEDGERS_UPLOAD_BEGIN,
    createExchangeLedgersViaUpload
  ),
  takeEvery(exchangeActions.CREATE_WALLET_LEDGERS_BEGIN, createWalletLedgers),
  takeEvery(exchangeActions.DELETE_EXCHANGE_LEDGER_BEGIN, deleteExchangeLedger),
  takeEvery(exchangeActions.EXCHANGE_ACCOUNTS_BEGIN, fetchExchangeAccounts),
  takeEvery(
    exchangeActions.UPDATE_EXCHANGE_LEDGERS_VIA_ADDRESS_GROUPINGS_BEGIN,
    updateExchangeLedgersViaAddressGroupings
  ),
  takeEvery(
    exchangeActions.UPDATE_EXCHANGE_LEDGERS_UPLOAD_BEGIN,
    updateExchangeLedgersViaUpload
  ),
  takeEvery(exchangeActions.MIGRATE_UPLOAD_BEGIN, migrateViaUpload),
  takeLeading(exchangeActions.LEDGERS_BEGIN, fetchLedgers),
  takeEvery(exchangeActions.RENAME_EXCHANGE_LEDGER_BEGIN, renameLedger),
  takeEvery(exchangeActions.RENAME_LINKED_ACCOUNT_BEGIN, renameLinkedAccount),
  takeEvery(exchangeActions.UNLINK_EXCHANGE_BEGIN, unlinkExchange),
  takeEvery(exchangeActions.SETTINGS_POLL, pollSettings),
  takeEvery(exchangeActions.UPDATE_EXCHANGE_API_KEY_BEGIN, updateExchangeAPI),
  takeEvery(
    exchangeActions.UPDATE_EXCHANGE_API_TOKEN_BEGIN,
    updateExchangeAPIToken
  ),
  takeEvery(
    exchangeActions.UPDATE_EXCHANGE_LEDGERS_BEGIN,
    updateExchangeAllLedgers
  ),
  takeEvery(exchangeActions.VERIFY_EXCHANGE_LINK_BEGIN, verifyExchangeLink),
  takeEvery(
    exchangeActions.REBUILD_EXCHANGE_LEDGERS_BEGIN,
    rebuildExchangeLedgers
  ),
  takeEvery(
    exchangeActions.UPDATE_ROLLFORWARD_OPEN_LOTS_UPLOAD_BEGIN,
    updateRollforwardViaUpload
  ),
  takeEvery(exchangeActions.API_KEY_VAULTS_BEGIN, fetchApiKeyVaults),
  takeEvery(exchangeActions.IMPORT_API_KEY_VAULTS_BEGIN, importApiKeyVaults),
  takeEvery(exchangeActions.MIGRATE_SOURCES_BEGIN, migrateSources),
];

// export all watcher sagas as an array to be composed in the root saga
export default exchangeSagas;
