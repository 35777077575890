import api from "../clients/exchange";

/**
 * Submits a request to enable link kind support on an account
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const enableLikeKind = () => {
  return api.post(`/profile/likeKind/enable`, {
    authenticated: true,
  });
};

/**
 * Submits a request to enable link kind support on an account
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const disableLikeKind = () => {
  return api.post(`/profile/likeKind/disable`, {
    authenticated: true,
  });
};
