import React from "react";
import CashMoney from "app.components/Util/CashMoney";
import LoadingSpinner from "app.components/Util/LoadingSpinner";

import BasePricingRows from "./BasePricingRows";
import SaleRow from "./SaleRow";
import SubtotalRow from "./SubtotalRow";
import Coupon from "./Coupon";
import CouponRow from "./CouponRow";
import TaxRow from "./TaxRow";
import TableList from "app.components/TableList/TableList";
import TableListCell from "app.components/TableList/TableListCell";
import { useTranslation } from "react-i18next";

export default function OrderBreakdown({
  plan,
  availableCouponId,
  applyCoupon,
  couponError,
  planDetails,
  finalCost,
  fromReferral,
  isUpgradeQuoteLoaded,
  zipCode,
}) {
  const { t } = useTranslation();

  return plan ? (
    <TableList className="text-sm">
      <tbody>
        <BasePricingRows
          plan={plan}
          unitDiscountPercent={plan.unitDiscountPercent}
          amountDollars={plan.amountDollars}
          title={planDetails.fullTitle}
          description={planDetails.description}
        />
        <SaleRow
          availableDiscountDollars={plan.availableDiscountDollars}
          availableDiscountPercent={plan.availableDiscountPercent}
          availableCouponId={plan.availableCouponId}
          fromReferral={fromReferral}
        />
        {fromReferral || availableCouponId === "ESSENTIALS_DISCOUNT" ? null : (
          <Coupon
            isUpgradeQuoteLoaded={isUpgradeQuoteLoaded}
            fromReferral={fromReferral}
            availableCouponId={availableCouponId}
            applyCoupon={applyCoupon}
            couponError={couponError}
          />
        )}
        <SubtotalRow plan={plan} amountDollars={plan.amountDollars} />

        <CouponRow
          fromReferral={fromReferral}
          availableCouponId={plan.availableCouponId}
          couponError={couponError}
          availableDiscountDollars={plan.availableDiscountDollars}
          availableDiscountPercent={plan.availableDiscountPercent}
        />
        <TaxRow
          isUpgradeQuoteLoaded={isUpgradeQuoteLoaded}
          taxPercent={plan.taxPercent}
          taxDollars={plan.taxDollars}
          zipCode={zipCode}
        />
      </tbody>
      <tfoot>
        <tr className="border-t border-t-zinc-900" data-testid="order-total">
          <TableListCell custom="font-bold pl-0" size="sm">
            {t("common.total")}
          </TableListCell>
          <TableListCell custom="pr-0 font-bold" size="sm" right>
            {isUpgradeQuoteLoaded ? (
              <CashMoney
                size="none"
                align="right"
                dollars={finalCost}
                fiatOverride="USD"
              />
            ) : (
              <small>{t("modals.calculating")}</small>
            )}
          </TableListCell>
        </tr>
      </tfoot>
    </TableList>
  ) : (
    <div className="flex justify-center items-center p-4">
      <LoadingSpinner />
    </div>
  );
}
