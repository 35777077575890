export const HAS_STRIPE =
  typeof whitelabel.config.STRIPE_KEY !== "undefined" &&
  whitelabel.config.STRIPE_KEY !== "";

async function getStripe(HAS_STRIPE) {
  if (HAS_STRIPE) {
    try {
      let { loadStripe } = await import("@stripe/stripe-js");
      return loadStripe(whitelabel.config.STRIPE_KEY);
    } catch (error) {
      console.error(error);
    }
  } else {
    return undefined;
  }
}

export const STRIPE_INSTANCE = getStripe(HAS_STRIPE);

export const STRIPE_ELEMENTS_FONT_URL =
  "https://assets.node40.com/fonts/fonts.2.min.css";

export const CARD_ELEMENT_CONFIG = {
  hidePostalCode: true,
  style: {
    base: {
      fontFamily: "'montserrat', Sans-Serif",
      color: "#52525b",
      fontWeight: "normal",
    },
  },
};
