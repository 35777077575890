let localStorageTestResult;

export const testLocalStorage = function () {
  const latest = "node40-ls-test";

  if (typeof localStorageTestResult === "undefined") {
    try {
      localStorage.setItem(latest, latest);
      localStorage.removeItem(latest);
      localStorageTestResult = true;
      return localStorageTestResult;
    } catch (e) {
      localStorageTestResult = false;
      return localStorageTestResult;
    }
  } else {
    return localStorageTestResult;
  }
};
