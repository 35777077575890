import { call, put, all, delay, select } from "redux-saga/effects";
import i18n from "i18next";

import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestArchiveLedger,
  receiveArchiveLedger,
} from "app.actions/exchangeLedger";
import {
  dismissModal,
  startModalWorking,
  doRedirect,
  addFlashMessage,
  removeFlashMessage,
} from "app.reducers/ui";

import { fetchLedgers } from "app.actions/exchanges";

import { generateGUID } from "app.utils";
import { ONE_MINUTE } from "app.constants";
import { STATUS_DANGER } from "app.components/Messages/FlashMessage";

function* archiveLedger(action) {
  const { ledgerId, userArchive, redirectAfterArchive } = action;

  yield all(
    yield put(startModalWorking()),
    yield put(requestArchiveLedger(ledgerId, userArchive))
  );

  const lastPortfolioTab = yield select((state) => state.ui.lastPortfolioTab);

  const failedLedgers = [];

  if (Array.isArray(ledgerId)) {
    const syncEverything = yield all(
      ledgerId.map((id) =>
        call(ExchangeLedgerAPI.archiveLedger, id, userArchive)
      )
    );

    yield all(
      syncEverything.map((sync, index) => {
        const id = ledgerId[index];
        if (sync.error) {
          failedLedgers.push(id);
        }
        return put(receiveArchiveLedger(id));
      })
    );
  } else {
    const response = yield call(
      ExchangeLedgerAPI.archiveLedger,
      ledgerId,
      userArchive
    );

    if (response.error) {
      failedLedgers.push(ledgerId);
    }

    yield put(receiveArchiveLedger(ledgerId));
  }

  if (redirectAfterArchive) {
    yield put(doRedirect(lastPortfolioTab));
  } else {
    yield put(dismissModal());
  }

  yield put(fetchLedgers(true));

  if (failedLedgers.length) {
    const messageId = generateGUID();

    const { ledgers } = yield select((state) => state.exchanges);

    const failedLedgerName = (id) =>
      id
        ? ledgers.find(({ id: ledgerId }) => ledgerId === id)?.userLedgerName
        : "";

    const errorMessage = i18n.t("constants.error.archiveLedgers", {
      context: failedLedgers.length,
      name: failedLedgerName(failedLedgers[0]),
      name2: failedLedgerName(failedLedgers[1]),
      name3: failedLedgerName(failedLedgers[2]),
    });

    yield put(addFlashMessage(errorMessage, messageId, STATUS_DANGER));
    yield delay(ONE_MINUTE);
    yield put(removeFlashMessage(messageId));
  }
}

export default archiveLedger;
