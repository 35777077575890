import React from "react";

const ToggleSwitch = ({
  id,
  label,
  currentValue,
  callback,
  disabled = false,
  reverseLabel = false,
  className,
}) => {
  const toggleValue = !currentValue;

  const switchClass = ["relative", "flex", "items-center"];

  if (className) {
    switchClass.push(className);
  }

  const triggerKeyDown = (e) => {
    if (e.key === " " || e.key === "Enter") callback(toggleValue);
  };

  // before
  // border: 1px solid mix($gray4, $green, 75%);
  // background: mix($gray1, $green, 95%);

  const toggleClass = [
    "text-black",
    "transition-all",
    "peer-checked:text-green-600",
    "leading-none",
    "flex",
    "items-center",
    // container
    "before:transition-all",
    "before:content-[' ']",
    "before:absolute",
    "before:block",
    "before:rounded-full",
    "before:w-8",
    "before:h-4",
    "before:bg-zinc-300",
    "peer-checked:before:bg-green-400",
    "before:border",
    "before:border-zinc-400",
    "peer-checked:before:border-green-600",
    "before:shadow-inner",
    reverseLabel ? "before:right-0" : "before:left-0",
    // pill
    "after:shadow",
    "after:transition-all",
    "after:content-[' ']",
    "after:absolute",
    "after:top-0",
    "after:w-3.5",
    "after:h-3.5",
    "after:rounded-full",
    "after:bg-white",
    reverseLabel ? "peer-checked:after:right-px" : "peer-checked:after:left-4",
    reverseLabel ? "after:right-4" : "after:left-px",
  ]
    .filter(Boolean)
    .join(" ");

  const labelClass = [
    "h-4",
    "font-normal",
    "text-sm",
    "cursor-pointer",
    "relative",
    reverseLabel ? "pr-10" : "pl-10",
  ].join(" ");

  const toggleActive = [
    "absolute w-8 h-3.5 rounded-full bg-transparent",
    reverseLabel ? "right-0" : "left-0",
  ].join(" ");

  return (
    <div className={switchClass.join(" ")}>
      <label className={labelClass} htmlFor={id}>
        <button className={toggleActive} onKeyDown={triggerKeyDown} />
        <input
          className="peer hidden"
          disabled={disabled}
          role="switch"
          aria-checked={currentValue}
          id={id}
          checked={currentValue}
          onChange={() => {
            callback(toggleValue);
          }}
          type="checkbox"
        />
        <span className={toggleClass}>{label}</span>
      </label>
    </div>
  );
};

ToggleSwitch.displayName = "ToggleSwitch";

export default ToggleSwitch;
