import React, { useState, useEffect } from "react";

import { ONE_SECOND } from "app.constants";
import SvgLoader from "app.components/Util/SvgLoader";
import { useTranslation } from "react-i18next";

const CopyToClipboard = ({ text, label, disabled }) => {
  const [copyState, setCopyState] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const { t } = useTranslation();

  function copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(
      function () {
        setCopyState(true);
        setTimeout(() => {
          if (isMounted) {
            setCopyState(false);
          }
        }, ONE_SECOND * 2);
      },
      function (e) {
        console.log({ e });
      }
    );
  }

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  return (
    <button
      disabled={disabled}
      title={
        label
          ? t("clipboard.copyToClipboardwithLabel", { label: label })
          : t("clipboard.copyToClipboard")
      }
      onClick={(e) => {
        e.preventDefault();
        copyToClipboard(text);
      }}
      className="group relative px-0 py-0"
    >
      {copyState ? (
        <span className="absolute right-0 top-full rounded bg-black px-1 py-1 text-xs normal-case leading-tight text-white shadow">
          {t("clipboard.copied")}
        </span>
      ) : null}
      <SvgLoader
        id="DocumentDuplicate"
        className="h-4 w-4 stroke-zinc-500 transition-all group-hover:stroke-blue-600"
      />
    </button>
  );
};

export default CopyToClipboard;
