import React, { Suspense, lazy } from "react";
import EnrollPlaceholder from "app.components/Placeholder/EnrollPlaceholder";

const EnrollContainer = lazy(() =>
  import("app.components/Enroll/EnrollContainer")
);

const EnrollUser = () => {
  return (
    <Suspense fallback={<EnrollPlaceholder />}>
      <EnrollContainer />
    </Suspense>
  );
};

export default EnrollUser;
