import { makeActionCreator } from "app.actions/helpers";
/******************************************************************************/
/* connect exchange api */
/******************************************************************************/
export const VERIFY_EXCHANGE_LINK_BEGIN = "VERIFY_EXCHANGE_LINK_BEGIN";
export const VERIFY_EXCHANGE_LINK_RECEIVE = "VERIFY_EXCHANGE_LINK_RECEIVE";
export const VERIFY_EXCHANGE_LINK_REQUEST = "VERIFY_EXCHANGE_LINK_REQUEST";
export const VERIFY_EXCHANGE_LINK_ERROR = "VERIFY_EXCHANGE_LINK_ERROR";

export const verifyExchangeLink = makeActionCreator(
  VERIFY_EXCHANGE_LINK_BEGIN,
  "exchange",
  "linkGUID",
  "apiLinkSuccessCallback",
  "apiDismissCallback",
  "oauthRequiredCallback"
);
export const requestVerifyExchangeLink = makeActionCreator(
  VERIFY_EXCHANGE_LINK_REQUEST,
  "linkGUID"
);
export const receiveVerifyExchangeLink = makeActionCreator(
  VERIFY_EXCHANGE_LINK_RECEIVE,
  "linkGUID",
  "response"
);
export const verifyExchangeLinkError = makeActionCreator(
  VERIFY_EXCHANGE_LINK_ERROR,
  "linkGUID",
  "response"
);
