import { call, put, all } from "redux-saga/effects";
import TellerAPI from "app.api/TellerAPI";
import {
  receiveRenewSubscription,
  enterSubscriptionSection
} from "app.actions/account";
import { dismissModal } from "app.reducers/ui";

function* renewSubscription(action) {
  const { planId } = action;
  const response = yield call(TellerAPI.subscribeToPlan, planId);

  if (response.error) {
    console.log(response.error);
  } else {
    yield all([
      put(dismissModal()),
      put(receiveRenewSubscription()),
      put(enterSubscriptionSection())
    ]);
  }
}

export default renewSubscription;
