import { put, all, race, take } from "redux-saga/effects";

export function* getSagaResult(startingAction, successAction, errorAction) {
  const [, response] = yield all([
    put(startingAction),
    race({
      success: take(successAction),
      error: take(errorAction)
    })
  ]);
  // Response should always get populated if we continue

  const { success, error } = response;

  if (success) {
    return success;
  } else {
    throw error;
  }
}
