import { put } from "redux-saga/effects";
import { setLedgersBySource } from "app.actions/computables";
import { LEDGER_TYPES } from "app.constants";

function* computeLedgersBySource(allLedgers) {
  const entriesSet = allLedgers.reduce((bySource, entry) => {
    let foundEntry = {
      source: entry.source,
      linkGUID: entry.linkGUID,
      ledgers: [],
      isWallet: entry.isWallet,
      isShadow: entry.isShadow,
      isSourceless: entry.isSourceless,
      isEVM: entry.isEVM === true ? true : false,
      isSmartContractPlatform:
        entry.isSmartContractPlatform === true ? true : false,
      id: crypto.randomUUID(),
    };

    bySource.forEach((item, k) => {
      if (item.source === entry.source && item.linkGUID === entry.linkGUID) {
        foundEntry = item;
      }

      if (entry.isShadow && item.isShadow) {
        foundEntry = item;
      }
    });

    foundEntry.ledgers.push(entry);

    bySource.add(foundEntry);

    return bySource;
  }, new Set([]));

  // regular wallets are single wallets which are not grouped together
  // as opposed to EVM wallets, where many ledgers may come from a single
  // address, a regular wallet is a 1:1 address/xpub to wallet ratio.
  const regularWallets = [...entriesSet].filter(
    (e) => e.isWallet && !e.isEVM && !e.isSmartContractPlatform
  );

  // filter out regular old, non grouped wallets
  const walletLedgers = regularWallets.reduce(
    (ledgers, wallet) => [...ledgers, ...wallet.ledgers],
    []
  );

  const regularWalletsCombined = [
    walletLedgers.length > 0 // do not create the wallet group if there are no wallet ledgers
      ? {
          source: LEDGER_TYPES.WALLET,
          linkGUID: undefined,
          ledgers: walletLedgers,
          isWallet: true,
          isEVM: false,
          isSmartContractPlatform: false,
        }
      : null,
  ].filter(Boolean);

  const entriesBySource = [...entriesSet].filter(
    (e) => !(e.isWallet && !e.isEVM && !e.isSmartContractPlatform)
  );

  // convert to an array, then return
  yield put(
    setLedgersBySource([...entriesBySource, ...regularWalletsCombined])
  );
}

export default computeLedgersBySource;
