import { makeActionCreator } from "app.actions/helpers";
/******************************************************************************/
/* connect exchange api */
/******************************************************************************/
export const CONNECT_EXCHANGE_API_KEY_BEGIN = "CONNECT_EXCHANGE_API_KEY_BEGIN";
export const CONNECT_EXCHANGE_API_RECEIVE = "CONNECT_EXCHANGE_API_RECEIVE";
export const CONNECT_EXCHANGE_API_REQUEST = "CONNECT_EXCHANGE_API_REQUEST";
export const CONNECT_EXCHANGE_API_ERROR = "CONNECT_EXCHANGE_API_ERROR";

export const connectExchangeViaApiKey = makeActionCreator(
  CONNECT_EXCHANGE_API_KEY_BEGIN,
  "exchange",
  "apiKey",
  "secretKey",
  "passphrase",
  "nickname",
  "autoImport"
);

export const requestConnectExchangeViaApiKey = makeActionCreator(
  CONNECT_EXCHANGE_API_REQUEST
);
export const receiveConnectExchangeViaApiKey = makeActionCreator(
  CONNECT_EXCHANGE_API_RECEIVE,
  "response"
);
export const receiveConnectExchangeViaApiKeyError = makeActionCreator(
  CONNECT_EXCHANGE_API_ERROR,
  "response"
);
