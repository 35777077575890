import { call, put } from "redux-saga/effects";
import TellerAPI from "app.api/TellerAPI";
import {
  requestSupportedLanguages,
  receiveSupportedLanguages,
} from "app.actions/account";
import { ACCOUNT_STATUS } from "app.constants";

function* fetchSupportedLanguages() {
  const response = yield call(TellerAPI.getSupportedLanguages);

  yield put(requestSupportedLanguages(ACCOUNT_STATUS.LOADING));

  if (response.error) {
    console.log("error in fetch lang");
    //yield put(receiveSupportedLanguagesError(response.body.message));
  } else {
    yield put(receiveSupportedLanguages(response.body, ACCOUNT_STATUS.LOADED));
  }
}

export default fetchSupportedLanguages;
