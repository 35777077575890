import { makeActionCreator } from "app.actions/helpers";
/******************************************************************************/
/* connect exchange api */
/******************************************************************************/
export const IMPORT_API_KEY_VAULTS_BEGIN = "IMPORT_API_KEY_VAULTS_BEGIN";
export const IMPORT_API_KEY_VAULTS_RECEIVE = "IMPORT_API_KEY_VAULTS_RECEIVE";
export const IMPORT_API_KEY_VAULTS_REQUEST = "IMPORT_API_KEY_VAULTS_REQUEST";
export const IMPORT_API_KEY_VAULTS_ERROR = "IMPORT_API_KEY_VAULTS_ERROR";

export const importApiKeyVaults = makeActionCreator(
  IMPORT_API_KEY_VAULTS_BEGIN,
  "exchange",
  "vaults"
);

export const requestImportApiKeyVaults = makeActionCreator(
  IMPORT_API_KEY_VAULTS_REQUEST
);
export const receiveImportApiKeyVaults = makeActionCreator(
  IMPORT_API_KEY_VAULTS_RECEIVE,
  "response"
);
export const receiveImportApiKeyVaultsError = makeActionCreator(
  IMPORT_API_KEY_VAULTS_ERROR,
  "response"
);
