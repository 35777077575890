import { call, put } from "redux-saga/effects";

import ExchangeLedgerAPI from "app.api/ExchangeLedgerAPI";

import {
  requestExchangeLedgerRange,
  exchangeLedgerRangeError,
  receiveExchangeLedgerRange
} from "app.actions/exchangeLedger";

import { getLoadingState } from "app.utils/selectors";

import { STATUS } from "app.constants";

function* fetchExchangeLedgerRange(action) {
  const { ledgerId } = action;

  const { isLoading, nextStatus } = yield* getLoadingState(
    state => state.exchangeLedger.ledgerDatesStatus
  );

  if (isLoading) return;

  yield put(requestExchangeLedgerRange(nextStatus));

  const response = yield call(
    ExchangeLedgerAPI.getExchangeLedgerRange,
    ledgerId
  );

  if (response.error) {
    yield put(exchangeLedgerRangeError(response.body, response.error));
  } else {
    yield put(receiveExchangeLedgerRange(response.body, STATUS.LOADED));
  }
}
export default fetchExchangeLedgerRange;
