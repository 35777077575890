import { put, select } from "redux-saga/effects";
import * as organizationActions from "app.reducers/organizations";
import { isLoaded } from "app.utils";

function* enterTeamMemberSection(action) {
  const teamMemberStatus = yield select(
    state => state.organizations.teamMemberStatus
  );

  // Got a list already
  if (isLoaded(teamMemberStatus)) return;

  yield put(organizationActions.fetchTeamMemberList());
}

export default enterTeamMemberSection;
