import { call, put, all } from "redux-saga/effects";
import * as organizationActions from "app.reducers/organizations";
import { doRedirect } from "app.reducers/ui";
import OrganizationAPI from "app.api/OrganizationAPI";
import { STATUS } from "app.constants";

function* addTeamMember(action) {
  const { email, firstName, lastName, admin } = action.payload;

  yield put(organizationActions.addTeamMemberRequest(STATUS.LOADING));

  const response = yield call(
    OrganizationAPI.addCoworker,
    email,
    firstName,
    lastName,
    admin
  );

  if (response.error) {
    const code = response.error;
    const message = response.body.message;
    const error = { code, message };

    yield put(organizationActions.addTeamMemberError(error, STATUS.ERROR));
    return;
  }

  yield all([
    put(organizationActions.addTeamMemberReceive(response.body, STATUS.LOADED)),
    put(organizationActions.fetchTeamMemberList()),
    put(doRedirect("/organization/team-members")),
  ]);
}

export default addTeamMember;
