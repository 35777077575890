import React, { useMemo, useState } from "react";
import TableList from "app.components/TableList/TableList";
import TableListHeader from "app.components/TableList/TableListHeader";
import TableListCell from "./TableListCell";
import Pagination from "app.components/Util/Pagination";
import SearchInput from "app.components/Util/Form/SearchInput";
import { makeKey } from "app.utils";
import { SORT_DIRECTIONS } from "app.constants";

const BaseTable = ({
  id = "",
  highlight = true,
  zebra = true,
  columns = [],
  data = [],
  noDataMessage = "",
  handleColumnClick,
  cellHeight,
  caption,
  captionClass = "",
  sortable = false,
  paginable = false,
  pageSize = 10,
  paginationLabel = "",
  showPageLabel = false,
  showPaginationCount,
  paginationTop = false,
  paginationBottom = true,
  size,
  defaultPageNumber = 0,
  handlePageChange,
  defaultSort = {},
  handleSortChange,
  searchable = false,
  searchPlaceholder,
  tableCustomClass,
  footer,
}) => {
  const [search, updateSearch] = useState("");
  const [sort, setSort] = useState(
    defaultSort?.sort ? defaultSort.sort : columns[0]?.column
  );

  const [sortDirection, setSortDirection] = useState(
    defaultSort?.sortDirection
      ? defaultSort.sortDirection
      : SORT_DIRECTIONS.ASCENDING
  );
  const [pageNumber, setPageNumber] = useState(defaultPageNumber);

  const filteredData = useMemo(() => {
    const filterSplit = search
      .split(",")
      .filter((item) => !!item)
      .map((item) => item.trim().toLowerCase());

    if (!searchable || !filterSplit.length) return data;

    return data.filter((item) => {
      for (let i = 0; i < filterSplit.length; i++) {
        const foundItem = columns.find(
          ({ column, customSearch, searchable }) => {
            if (!searchable) return false;

            if (customSearch) {
              return customSearch(item[column], filterSplit[i], item);
            } else {
              if (typeof item[column] === "string") {
                return item[column].toLowerCase().includes(filterSplit[i]);
              }
            }
            return false;
          }
        );

        if (foundItem) return true;
      }
      return false;
    });
  }, [data, searchable, search, columns]);

  const isPaginationEnabled = paginable && filteredData.length > pageSize;
  const pageStart = pageNumber * pageSize;
  const pageEnd = (pageNumber + 1) * pageSize;

  const totalRecordCount = data.length;
  const numPages = Math.ceil(totalRecordCount / pageSize);

  const changePage = (page) => {
    setPageNumber(page);
    handlePageChange && handlePageChange(page);
  };

  if (pageNumber < 0 || pageNumber > numPages) changePage(0);

  const sortedData = useMemo(() => {
    if (!sortable) return filteredData;

    const sortByColumn = columns.find((c) => c.column === sort);
    const sortFunction = sortByColumn?.sortFunction;

    if (sortFunction) {
      if (sortDirection === SORT_DIRECTIONS.ASCENDING) {
        return filteredData.sort(sortFunction);
      } else {
        return filteredData.sort(sortFunction).reverse();
      }
    } else {
      if (sortDirection === SORT_DIRECTIONS.ASCENDING) {
        return filteredData.sort((a, b) => {
          const asort = a[sort] || "";
          const bsort = b[sort] || "";
          if (asort < bsort) return -1;
          if (asort > bsort) return 1;
          return 0;
        });
      }

      if (sortDirection === SORT_DIRECTIONS.DESCENDING) {
        return filteredData.sort((a, b) => {
          const asort = a[sort] || "";
          const bsort = b[sort] || "";
          if (asort > bsort) return -1;
          if (asort < bsort) return 1;
          return 0;
        });
      }
    }

    return filteredData;
  }, [filteredData, sort, columns, sortDirection, sortable]);

  const displayData = isPaginationEnabled
    ? sortedData.slice(pageStart, pageEnd)
    : sortedData;

  const paginator = isPaginationEnabled ? (
    <Pagination
      onSelect={changePage}
      curPage={pageNumber}
      perPage={pageSize}
      numPages={numPages}
      totalRecords={totalRecordCount}
      showPageLabel={showPageLabel}
      showPaginationCount={showPaginationCount}
      pageStart={pageStart}
      visibleRecordCount={displayData.length}
      totalRecordCount={totalRecordCount}
      label={paginationLabel}
    />
  ) : null;

  const header = columns.map(
    (
      {
        label,
        labelHidden,
        left,
        right,
        center,
        customClass,
        sortable,
        column,
        sticky,
        expandCallback,
        headerCustomClass,
      },
      index
    ) => {
      const sortableProps = sortable
        ? {
            sortCallback: (e) => {
              e.preventDefault();
              const nextDirection =
                !sortDirection ||
                sort !== column ||
                sortDirection === SORT_DIRECTIONS.DESCENDING
                  ? SORT_DIRECTIONS.ASCENDING
                  : SORT_DIRECTIONS.DESCENDING;
              setSortDirection(nextDirection);
              setSort(column);
              handleSortChange && handleSortChange(column, nextDirection);
            },
            sortColumn: column,
            sortedBy: sort,
            sortDirection:
              sortDirection && sort === column
                ? sortDirection === SORT_DIRECTIONS.ASCENDING
                : undefined,
          }
        : {};

      const key = makeKey(id, "th", index);

      return (
        <TableListHeader
          key={key}
          data-testid={key}
          left={left}
          right={right}
          center={center}
          custom={customClass}
          overrideHeaderClass={headerCustomClass}
          size={size}
          sticky={sticky}
          expandCallback={expandCallback}
          onlyShowWhenSorted
          {...sortableProps}
        >
          {labelHidden === true ? (
            <span className="sr-only">{label}</span>
          ) : (
            label
          )}
        </TableListHeader>
      );
    }
  );

  const body = !data.length ? (
    <tr>
      <td colSpan={columns.length}>
        <p className="mx-8 my-4 text-center">{noDataMessage}</p>
      </td>
    </tr>
  ) : (
    displayData.map((cur, idx) => {
      const key = makeKey(id, pageNumber, "sorted-tr", idx);

      return (
        <tr
          data-testid={key}
          key={key}
          className={
            handleColumnClick && !cur.rowDisabled ? "cursor-pointer" : ""
          }
        >
          {columns.map(
            (
              {
                column,
                center,
                left,
                right,
                customRender,
                tabbable,
                disableClick,
                lastDisplay,
                firstDisplay,
                customCellClass,
              },
              index
            ) => {
              const content = customRender
                ? customRender(cur[column], cur)
                : cur[column];

              return (
                <TableListCell
                  height={cellHeight}
                  center={center}
                  left={left}
                  right={right}
                  key={makeKey(id, "td", index)}
                  size={size}
                  custom={customCellClass}
                  onClick={
                    cur.rowDisabled || disableClick || !handleColumnClick
                      ? undefined
                      : (e) => handleColumnClick(e, cur)
                  }
                  lastDisplay={lastDisplay}
                  firstDisplay={firstDisplay}
                >
                  {tabbable ? (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleColumnClick(e, cur);
                      }}
                    >
                      {content}
                    </button>
                  ) : (
                    content
                  )}
                </TableListCell>
              );
            }
          )}
        </tr>
      );
    })
  );

  return (
    <React.Fragment>
      <div
        className={[
          searchable && paginationTop ? "grid grid-cols-3" : "",
          searchable ? "p-2" : "",
        ]
          .filter(Boolean)
          .join(" ")}
      >
        {searchable ? (
          <SearchInput
            fullWidth
            clearCallback={() => updateSearch("")}
            placeholder={searchPlaceholder}
            value={search}
            onChange={(e) => {
              e.preventDefault();
              updateSearch(e.target.value);
            }}
          />
        ) : null}
        {paginationTop ? paginator : null}
      </div>
      <div className={tableCustomClass}>
        <TableList highlight={highlight} zebra={zebra}>
          {caption ? (
            <caption className={captionClass}>{caption}</caption>
          ) : null}
          <thead>
            <tr>{header}</tr>
          </thead>
          <tbody>{body}</tbody>
          {footer ? <tfoot>{footer}</tfoot> : null}
        </TableList>
      </div>
      {paginationBottom ? paginator : null}
    </React.Fragment>
  );
};

export default BaseTable;
