import { parseISO } from "date-fns";

function parseDate(dt) {
  if (typeof dt !== "undefined") {
    const d = parseISO(dt.substring(0, 10));
    if (d instanceof Date && !isNaN(d)) {
      return d;
    }
  }
  return undefined;
}

export function getStartDate(selectedRange) {
  const startDate = selectedRange?.startDate;
  return parseDate(startDate);
}

export function getEndDate(selectedRange) {
  const endDate = selectedRange?.endDate;
  return parseDate(endDate);
}
