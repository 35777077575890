import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { dismissModal } from "app.reducers/ui";
import {
  changeTransferInFromSourceless,
  clearTransferError,
} from "app.actions/exchangeLedger";

import Button from "app.components/Util/Button";
import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";
import WarningBanner from "app.components/Util/WarningBanner";

function ModalSourcelessTransferIn({ entry, sourcelessLedgers }) {
  const [selectedLedger, setSelectedLedger] = useState("");

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { transferWorking, transferErrorMessage } = useSelector(
    (state) => state.exchangeLedger
  );

  const handleDismiss = (e) => {
    e.preventDefault();
    dispatch(dismissModal());
  };

  useEffect(() => {
    dispatch(clearTransferError());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <ModalHeader
        title={t(
          "modals.sourcelessTransaction.markTransactionAsTransferInEvent"
        )}
        closeCallback={handleDismiss}
      />
      <div className="mx-4">
        {transferErrorMessage ? (
          <div className="mb-4">
            <WarningBanner type="danger" noPadding>
              {transferErrorMessage}
            </WarningBanner>
          </div>
        ) : null}
        <p>{t("modals.payableTransaction.transactionTransferDescription")}</p>

        <div className="flex items-center pt-4">
          <p>
            {t("modals.payableTransaction.whereWasThisAssetTransferedFrom")}
          </p>

          <select
            className="ml-2 rounded border border-zinc-100 bg-white px-2 py-1 text-sm shadow"
            name="select-ledger"
            id="select-ledger"
            value={selectedLedger}
            onChange={(e) => {
              e.preventDefault();
              setSelectedLedger(e.target.value);
            }}
          >
            <option value="" disabled>
              {t("modals.payableTransaction.selectLedger")}
            </option>

            {sourcelessLedgers.map(({ id, userLedgerName }) => (
              <option key={id} value={id}>
                {userLedgerName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <ModalControls>
        <Button
          type="submit"
          disabled={transferWorking || !selectedLedger}
          buttonType="primary"
          customClasses="ml-4"
          onClick={(e) => {
            e.preventDefault();
            dispatch(
              changeTransferInFromSourceless(entry.id, Number(selectedLedger))
            );
          }}
          text={t("ledgers.transactionTaxOptions.markAsTransfer")}
        />
        <Button
          onClick={handleDismiss}
          disabled={transferWorking}
          buttonType="text"
          text={t("common.close")}
        />
      </ModalControls>
    </React.Fragment>
  );
}

ModalSourcelessTransferIn.displayName = "ModalSourcelessTransferIn";

export default ModalSourcelessTransferIn;
