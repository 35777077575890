import { call, put, all } from "redux-saga/effects";
import { getLoadingState } from "app.utils/selectors";
import {
  requestEnableLikeKind,
  enableLikeKindError,
  enableLikeKindReceive,
  fetchSettings,
} from "app.actions/exchanges";
import SettingsAPI from "app.api/SettingsAPI";

import { STATUS } from "app.constants";

function* enableLikeKind(action) {
  const { isLoading, nextStatus } = yield* getLoadingState(
    (state) => state.exchanges.likeKindStatus
  );

  if (isLoading) return;

  yield put(requestEnableLikeKind(nextStatus));

  const response = yield call(SettingsAPI.enableLikeKind);

  if (response.error) {
    yield put(enableLikeKindError(response.body, response.error, STATUS.ERROR));
  } else {
    yield all([
      put(enableLikeKindReceive(STATUS.UNINITIALIZED)),
      put(fetchSettings()),
    ]);
  }
}

export default enableLikeKind;
