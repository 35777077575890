import { makeActionCreator } from "app.actions/helpers";
/******************************************************************************/
/* rebuild exchange ledgers */
/******************************************************************************/
export const REBUILD_EXCHANGE_LEDGERS_BEGIN = "REBUILD_EXCHANGE_LEDGERS_BEGIN";
export const REBUILD_EXCHANGE_LEDGERS_RECEIVE =
  "REBUILD_EXCHANGE_LEDGERS_RECEIVE";
export const REBUILD_EXCHANGE_LEDGERS_REQUEST =
  "REBUILD_EXCHANGE_LEDGERS_REQUEST";
export const REBUILD_EXCHANGE_LEDGERS_ERROR = "REBUILD_EXCHANGE_LEDGERS_ERROR";

export const requestRebuildExchangeLedgers = makeActionCreator(
  REBUILD_EXCHANGE_LEDGERS_REQUEST
);
export const receiveRebuildExchangeLedgers = makeActionCreator(
  REBUILD_EXCHANGE_LEDGERS_RECEIVE,
  "exchangeId",
  "linkGUID",
  "ledgerIds"
);
export const rebuildExchangeLedgersError = makeActionCreator(
  REBUILD_EXCHANGE_LEDGERS_ERROR,
  "exchangeId",
  "linkGUID",
  "error"
);

export const rebuildExchangeLedgers = makeActionCreator(
  REBUILD_EXCHANGE_LEDGERS_BEGIN,
  "exchangeId",
  "linkGUID"
);
