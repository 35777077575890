import { makeActionCreator } from "app.actions/helpers";
/******************************************************************************/
/* update exchange ledgers via upload */
/******************************************************************************/
export const UPDATE_EXCHANGE_LEDGERS_UPLOAD_BEGIN =
  "UPDATE_EXCHANGE_LEDGERS_UPLOAD_BEGIN";
export const UPDATE_EXCHANGE_LEDGERS_UPLOAD_REQUEST =
  "UPDATE_EXCHANGE_LEDGERS_UPLOAD_REQUEST";
export const UPDATE_EXCHANGE_LEDGERS_UPLOAD_RECEIVE =
  "UPDATE_EXCHANGE_LEDGERS_UPLOAD_RECEIVE";
export const UPDATE_EXCHANGE_LEDGERS_UPLOAD_ERROR =
  "UPDATE_EXCHANGE_LEDGERS_UPLOAD_ERROR";

export const updateExchangeLedgersViaUpload = makeActionCreator(
  UPDATE_EXCHANGE_LEDGERS_UPLOAD_BEGIN,
  "files",
  "exchangeId",
  "linkGUID",
  "ledgerIds"
);

export const requestUpdateExchangeLedgersViaUpload = makeActionCreator(
  UPDATE_EXCHANGE_LEDGERS_UPLOAD_REQUEST
);
export const receiveUpdateExchangeLedgersViaUpload = makeActionCreator(
  UPDATE_EXCHANGE_LEDGERS_UPLOAD_RECEIVE,
  "response",
  "exchangeId"
);
export const updateExchangeLedgersViaUploadError = makeActionCreator(
  UPDATE_EXCHANGE_LEDGERS_UPLOAD_ERROR,
  "response",
  "error"
);
