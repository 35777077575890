import { put } from "redux-saga/effects";
import {
  EXCHANGE_TABBED_LEDGER_SECTION_LEAVE,
  EXCHANGE_TABBED_LEDGER_SECTION_CHANGE,
} from "app.actions/exchangeLedger";

import { viewedLedger } from "app.reducers/ui";
import { LEDGER_TYPES } from "app.constants";

const EXCHANGE_TYPES = [
  EXCHANGE_TABBED_LEDGER_SECTION_LEAVE,
  EXCHANGE_TABBED_LEDGER_SECTION_CHANGE,
];

function* viewLedger(action) {
  const { type } = action;
  let ledgerType, id;

  const matchType = (x) => x === type;

  if (EXCHANGE_TYPES.find(matchType)) {
    ledgerType = LEDGER_TYPES.EXCHANGE;
    id = action.ledgerId;
  } else {
    throw new Error("Unknown ledger type");
  }

  yield put(viewedLedger(parseInt(id, 10), ledgerType));
}

export default viewLedger;
