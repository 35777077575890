import React, { useState, useEffect } from "react"; // , PropTypes
import { useDispatch, useSelector } from "react-redux";

import { fetchUpgradeQuote } from "app.actions/account";

import PaymentSummary from "./PaymentSummary";
import PaymentForm from "./PaymentForm";

function Payment({ newPlan }) {
  const reduxDispatch = useDispatch();

  // the ID of the selected plan
  const planId = newPlan.planId;

  const { billingInformation, upgradeQuote } = useSelector(
    (state) => state.account
  );

  // does a user already have billing information?
  const hasBillingInformation =
    billingInformation !== null && typeof billingInformation !== "undefined";

  // if yes, default to using that
  const [useExistingBillingInformation, setUseExistingBillingInformation] =
    useState(hasBillingInformation);

  //const [appliedCouponCode, setAppliedCouponCode] = useState("");
  const [newZipCode, setNewZipCode] = useState("");

  const availableCouponId = upgradeQuote?.availableCouponId || null;

  // if the user is using their existing billing info, use that zip, if not, use whatever zip code they enter
  const zipCodeToUse = useExistingBillingInformation
    ? billingInformation.zipCode
    : newZipCode;

  // if user was referred in from a promotion
  const { referrer } = useSelector((state) => state.account);
  const referrerCode = referrer?.referrerCode;

  const applyCoupon = (couponCode) => {
    reduxDispatch(
      fetchUpgradeQuote(
        planId,
        zipCodeToUse,
        undefined,
        couponCode,
        referrerCode
      )
    );
  };

  // if any of the data below changes, we need to get a new quote
  useEffect(() => {
    reduxDispatch(
      fetchUpgradeQuote(
        planId,
        zipCodeToUse,
        undefined,
        availableCouponId,
        referrerCode
      )
    );
  }, [planId, zipCodeToUse, availableCouponId, referrerCode, reduxDispatch]);

  //const isDowngrade = newPlan?.prorationType === "downgrade";

  return (
    <React.Fragment>
      <div className="m-4 flex">
        <PaymentForm
          useExistingBillingInformation={useExistingBillingInformation}
          setUseExistingBillingInformation={setUseExistingBillingInformation}
          setNewZipCode={setNewZipCode}
          newPlan={newPlan}
        />

        <div className="basis-1/3 ml-4 -mt-4 bg-blue-50 p-4 rounded">
          <PaymentSummary
            newPlan={newPlan}
            zipCode={zipCodeToUse}
            applyCoupon={applyCoupon}
            referrer={referrer}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Payment;
