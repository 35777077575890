import { makeActionCreator } from "app.actions/helpers";
/******************************************************************************/
/* create exchange ledgers */
/******************************************************************************/
export const CREATE_WALLET_LEDGERS_BEGIN = "CREATE_WALLET_LEDGERS_BEGIN";
export const CREATE_WALLET_LEDGERS_REQUEST = "CREATE_WALLET_LEDGERS_REQUEST";

export const CREATE_WALLET_LEDGERS_RECEIVE = "CREATE_WALLET_LEDGERS_RECEIVE";
export const CREATE_WALLET_LEDGERS_ERROR = "CREATE_WALLET_LEDGERS_ERROR";
export const CREATE_WALLET_LEDGERS_RESET = "CREATE_WALLET_LEDGERS_RESET";

export const createWalletLedgers = makeActionCreator(
  CREATE_WALLET_LEDGERS_BEGIN,
  "exchange",
  "xpubs",
  "addresses",
  "token",
  "file",
  "signatures",
  "addressGroupings",
  "ledgerName"
);
export const requestCreateWalletLedgers = makeActionCreator(
  CREATE_WALLET_LEDGERS_REQUEST
);
export const receiveCreateWalletLedgers = makeActionCreator(
  CREATE_WALLET_LEDGERS_RECEIVE,
  "response",
  "exchangeId"
);
export const createWalletLedgersError = makeActionCreator(
  CREATE_WALLET_LEDGERS_ERROR,
  "response",
  "error"
);
export const resetWallletLedgerState = makeActionCreator(
  CREATE_WALLET_LEDGERS_RESET
);
