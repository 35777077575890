// intial state, eventually we'll restore this from local storage (per user)
import { sortByField } from "app.utils";
import * as addressActions from "app.actions/addresses";

let initialState = {
  data: [],
  inspectedAddress: undefined,
  inspectedAddressData: undefined,
  loadingAddresses: false,
  loadingAddress: false
};

const Addresss = (state = initialState, action) => {
  switch (action.type) {
    case addressActions.INSPECT_ADDRESS:
      return { ...state, inspectedAddress: action.address };
    case addressActions.ADDRESS_TRANSACTION_HISTORY_REQUEST:
      return { ...state, loadingAddress: true };
    case addressActions.ADDRESS_TRANSACTION_HISTORY_RECEIVE:
      return {
        ...state,
        loadingAddress: false,
        inspectedAddressData: action.response
      };
    case addressActions.ADDRESSES_BY_IMPORT_REQUEST:
      return { ...state, loadingAddresses: true };
    case addressActions.ADDRESSES_BY_IMPORT_RECEIVE:
      return {
        ...state,
        data: action.response.addresses.sort((a, b) => {
          return sortByField(a, b, "timestamp", true);
        }),
        loadingAddresses: false
      };
    default:
      return state;
  }
};

export default Addresss;
