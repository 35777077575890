import React from "react";

import { PAGINATION_TYPES } from "./constants";

import PaginationPartitionedList from "./PaginationPartitionedList";
import PaginationList from "./PaginationList";
import PaginationShowing from "app.components/Util/Pagination/PaginationShowing";

function getPaginationSegments(numPages) {
  if (numPages <= 25) return 5;
  if (numPages <= 50) return 6;
  if (numPages <= 100) return 7;
  if (numPages <= 250) return 8;
  if (numPages <= 500) return 9;
  return 10;
}

const Pagination = ({
  location,
  onSelect,
  curPage,
  totalRecords,
  showPageLabel = true,
  showPaginationCount = false,
  pageStart,
  visibleRecordCount,
  totalRecordCount,
  label,
  numPages,
}) => {
  if (!totalRecords || numPages < 2) {
    return null;
  }

  const currentPage = parseInt(curPage || 0, 10);
  const type =
    typeof location !== "undefined"
      ? PAGINATION_TYPES.LINK
      : PAGINATION_TYPES.BUTTON;

  const pagination =
    numPages < 15 ? (
      <PaginationList
        location={location}
        numPages={numPages}
        currentPage={currentPage}
        onSelect={onSelect}
        type={type}
        showPageLabel={showPageLabel}
      />
    ) : (
      <PaginationPartitionedList
        location={location}
        numPages={numPages}
        currentPage={currentPage}
        partitions={getPaginationSegments(numPages)}
        type={type}
        onSelect={onSelect}
        showPageLabel={showPageLabel}
      />
    );

  return (
    <div
      className="c_pagination flex items-center justify-center bg-white px-4 py-1.5"
      data-testid="pagination"
    >
      {showPaginationCount && (
        <PaginationShowing
          pageStart={pageStart}
          visibleRecordCount={visibleRecordCount}
          totalRecordCount={totalRecordCount}
          label={label}
        />
      )}
      {pagination}
      {showPaginationCount && <div className="flex-1" />}
    </div>
  );
};

Pagination.displayName = "Pagination";

export default Pagination;
