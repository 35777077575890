import { put, delay, select, cancel, take, fork } from "redux-saga/effects";
import {
  fetchSmartWalletList,
  cancelPollSmartWalletList,
} from "app.reducers/smartWallet";
import { FIVE_SECONDS, HALF_MINUTE, STATUS } from "app.constants";

function* pollSmartWalletList(action) {
  const pollSmart = yield fork(runPoll);
  yield take(cancelPollSmartWalletList);
  yield cancel(pollSmart);
}

function* runPoll() {
  while (true) {
    // we only want the smart group list to keep polling if the user is
    // still in a portfolio - an ORG manager might be back on the org admin
    // screen and we should not be reloading another user's smart list

    // jgleman 5-JUN-2024 - this logic is not currently necessary in that
    // the typical ways a user goes from portfolio to org admin screens and
    // back causes a total app reload because there are too many potentially
    // async things happening to ensure they are all cancelled

    const { portfolioStatus } = yield select((state) => state.portfolios);
    if (portfolioStatus === STATUS.INITIAL) {
      // fetch the ledger list again to see if any progress on processing
      // has been made
      const { pricesLoading } = yield select((state) => state.smartWallet);
      const interval = pricesLoading ? FIVE_SECONDS : HALF_MINUTE;
      yield put(fetchSmartWalletList());
      yield delay(interval); // wait for 30 seconds
    } else {
      yield put(cancelPollSmartWalletList());
    }
  }
}
export default pollSmartWalletList;
