import { makeActionCreator } from "app.actions/helpers";
/******************************************************************************/
/* fetch ledgers */
/******************************************************************************/

export const LEDGERS_BEGIN = "LEDGERS_BEGIN";
export const LEDGERS_REQUEST = "LEDGERS_REQUEST";
export const LEDGERS_RECEIVE = "LEDGERS_RECEIVE";
export const LEDGERS_ERROR = "LEDGERS_ERROR";

export const fetchLedgers = makeActionCreator(LEDGERS_BEGIN, "oneTime");
export const requestLedgers = makeActionCreator(LEDGERS_REQUEST, "nextStatus");
export const receiveLedgers = makeActionCreator(
  LEDGERS_RECEIVE,
  "response",
  "nextStatus"
);
export const ledgersError = makeActionCreator(
  LEDGERS_ERROR,
  "response",
  "error"
);
