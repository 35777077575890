import React from "react";

const Input = React.forwardRef(
  ({ hasError, className, type, size = "md", ...htmlAttributes }, ref) => {
    const inputClass = [
      "transition-all",
      "rounded",
      "border",
      "px-2",
      "shadow-inner",
      "leading-7",
      "text-sm",
      "text-zinc-600",
      "disabled:cursor-not-allowed",
      "disabled:opacity-70",
      "disabled:bg-zinc-50",
      "placeholder:text-sm",
      size === "md" ? "h-8" : null,
      size === "sm" ? "h-6 text-sm" : null,
      className,
      hasError ? "border-red-700 text-red-700" : "border-zinc-300",
    ]
      .filter(Boolean)
      .join(" ");
    return (
      <input
        ref={ref}
        type={type}
        className={type === "checkbox" ? "" : inputClass}
        {...htmlAttributes}
      />
    );
  }
);

Input.displayName = "Input";

export default Input;
