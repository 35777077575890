import { put, call } from "redux-saga/effects";
import {
  setReferrer,
  subscriptionSectionLoaded,
  receiveUserBillingInfo,
  receiveUserPlanSubscription,
} from "app.actions/account";
import { parseCookie } from "app.utils";
import TellerAPI from "app.api/TellerAPI";

import { DEFAULT_PLAN_ID, AVAILABLE_PLANS } from "app.constants/plans";

import { HAS_STRIPE } from "app.constants/stripe";

function* fetchCurrentBillingInfo() {
  const billingInfoResponse = HAS_STRIPE
    ? yield call(TellerAPI.getCurrentPaymentInfo)
    : {};

  if (billingInfoResponse.error) {
    // ToDo: figure this out
    console.log({ billingInfoResponse: billingInfoResponse.error });
    return;
  } else {
    if (HAS_STRIPE) {
      const billingInfo =
        billingInfoResponse._meta.status === 204
          ? null
          : billingInfoResponse.body;
      yield put(receiveUserBillingInfo(billingInfo));
    } else {
      yield put(receiveUserBillingInfo(null));
    }
  }
}

function* fetchCurrentSubscription() {
  const response = HAS_STRIPE
    ? yield call(TellerAPI.getCustomerSubscriptions)
    : { body: [{}] };

  if (response.error) {
    // ToDo: figure this out
    //console.log({ fetchCurrentSubscription: response.error });
    return;
  } else {
    yield put(receiveUserPlanSubscription(response.body[0]));
  }
}

function* enterSubscriptionSection(action) {
  // 1. Fetch billing info and subscription
  // we used to call these in parallel, but due to the way token
  // refreshing was working, it's actually more efficient
  // for us to call this in series for now.
  yield call(fetchCurrentSubscription);
  yield call(fetchCurrentBillingInfo);

  // 2. Setup referral information
  const referral = yield* getReferralInformation();
  const hasReferral = typeof referral !== "undefined";
  if (hasReferral) {
    yield put(setReferrer(referral));
  }
  if (!HAS_STRIPE) {
    yield put(subscriptionSectionLoaded({}, undefined));
    return;
  }

  const planResponse = yield call(
    TellerAPI.getPaymentProductsForUser,
    referral?.code
  );

  const plansReceived = (planResponse?.body || []).reduce(
    (acc, product) => [...acc, ...product.plans],
    []
  );

  const currentUserPlan = plansReceived.filter(
    (x) => x.subscribed || x.subscribedThroughPromo
  )[0];

  const defaultPlan = {
    subscribed: typeof currentUserPlan === "undefined",
    planId: DEFAULT_PLAN_ID,
    isDefaultPlan: true,
    active: currentUserPlan ? false : true,
  };

  const userPlan =
    typeof currentUserPlan === "undefined" ? defaultPlan : currentUserPlan;

  const plans = AVAILABLE_PLANS.reduce((acc, plan) => {
    const findPlan = plansReceived.find(({ planId }) => planId === plan.id);

    if (plan.id === DEFAULT_PLAN_ID)
      acc.push({
        ...defaultPlan,
        planRanking: plan.level,
        planId: plan.id,
      });
    if (findPlan)
      acc.push({
        ...findPlan,
        planRanking: plan.level,
        planId: plan.id,
      });
    if (plan.subPlans) {
      const subPlans = plan.subPlans.map((id) => ({
        ...plansReceived.find(({ planId }) => planId === id),
        planRanking: plan.level,
        planId: id,
      }));

      acc.push({
        planId: plan.id,
        planRanking: plan.level,
        subPlans,
      });
    }

    return acc;
  }, []);

  const completeUserPlan = {
    ...userPlan,
    planId: userPlan?.planId,
  };

  yield put(subscriptionSectionLoaded(plans, completeUserPlan));
}

function* getReferralInformation() {
  const cookieData = parseCookie(document.cookie);
  const referral = cookieData.NODE40_REFERRAL;

  if (typeof referral === "undefined") return;

  const referralCode = referral.code;

  const promoValidResponse = yield call(TellerAPI.checkPromoCode, referralCode);
  const isPlanValid = promoValidResponse.body.active;
  if (!isPlanValid) return undefined;

  return referral;
}

export default enterSubscriptionSection;
