import { makeActionCreator } from "app.actions/helpers";
/******************************************************************************/
/* enable like kind */
/******************************************************************************/
export const ENABLE_LIKE_KIND_BEGIN = "ENABLE_LIKE_KIND_BEGIN";
export const ENABLE_LIKE_KIND_REQUEST = "ENABLE_LIKE_KIND_REQUEST";
export const ENABLE_LIKE_KIND_RECEIVE = "ENABLE_LIKE_KIND_RECEIVE";
export const ENABLE_LIKE_KIND_ERROR = "ENABLE_LIKE_KIND_ERROR";

export const enableLikeKind = makeActionCreator(ENABLE_LIKE_KIND_BEGIN);

export const requestEnableLikeKind = makeActionCreator(
  ENABLE_LIKE_KIND_REQUEST,
  "nextStatus"
);

export const enableLikeKindReceive = makeActionCreator(
  ENABLE_LIKE_KIND_RECEIVE,
  "nextStatus"
);

export const enableLikeKindError = makeActionCreator(
  ENABLE_LIKE_KIND_ERROR,
  "nextStatus"
);
