import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FEATURES } from "app.constants";
import { useDispatch, useSelector } from "react-redux";
import { dismissModal } from "app.reducers/ui";
import UpgradeCommonDocuments from "app.components/Upgrade/UpgradeCommonDocuments";
import UpgradeCommonLedgers from "app.components/Upgrade/UpgradeCommonLedgers";
import FeatureList from "app.components/Upgrade/FeatureList";
import Button from "app.components/Util/Button";
import ModalControls from "app.components/Modal/ModalControls";
import ModalHeader from "app.components/Modal/ModalHeader";

const ModalUpgrade = ({ canceling, canDismissCallback, feature }) => {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();

  const subscription = useSelector((state) => state.user.subscription);

  const disableButtons = useMemo(() => {
    if (canceling) return true;
    return false;
  }, [canceling]);

  useEffect(() => {
    // prevents modal from dismissing when esc is pressed if the user is working
    canDismissCallback(!disableButtons);
  }, [canDismissCallback, disableButtons]);

  let f = null;

  switch (feature) {
    case FEATURES.DOCUMENTS: {
      f = <UpgradeCommonDocuments />;
      break;
    }
    case FEATURES.WALLETS: {
      f = (
        <UpgradeCommonLedgers
          type={FEATURES.WALLETS}
          limit={subscription.walletLedger}
        />
      );
      break;
    }
    case FEATURES.EXCHANGES: {
      f = (
        <UpgradeCommonLedgers
          type={FEATURES.EXCHANGES}
          limit={subscription.exchangeLedger}
        />
      );
      break;
    }
    default:
      break;
  }

  return (
    <React.Fragment>
      <ModalHeader
        title={t("modals.upgradeNow")}
        closeCallback={(e) => {
          e.preventDefault();
          reduxDispatch(dismissModal());
        }}
      />
      <div className="m-4">
        {f}
        <p>{t("modals.upgradeAndGainAccessToGreatFeatures")}</p>
        <FeatureList />
      </div>
      <ModalControls>
        <Button
          asAnchor={true}
          to="/portfolio/account"
          disabled={disableButtons}
          data-tabbable={true}
          buttonType="primary"
          customClasses="ml-4"
          text={t("modals.seePlanOptions")}
        />
        <Button
          disabled={disableButtons}
          onClick={(e) => {
            e.preventDefault();
            reduxDispatch(dismissModal());
          }}
          data-tabbable={true}
          buttonType="text"
          text={t("button.upgradeLater")}
        />
      </ModalControls>
    </React.Fragment>
  );
};

ModalUpgrade.displayName = "ModalUpgrade";

export default ModalUpgrade;
