import api from "app.api/clients/exchange";

/**
 * Submits a request to get an exchange ledger sheet
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @param {number} offset - The offset
 * @param {number} max - The max
 * @param {string} sort - How to sort
 * @param {string} order - How to order
 * @param {object} range - An object describing the range
 * @returns {object}  response - The Response or Error message
 */
export const getExchangeLedger = (
  ledgerId,
  offset,
  max,
  sort,
  order,
  range,
  filter
) => {
  const query = {};

  if (max) query.max = max;
  if (offset) query.offset = offset;
  if (sort) query.sort = sort;
  if (order) query.order = order;
  if (filter) query[filter] = true;

  if (
    typeof range?.startDate !== "undefined" &&
    typeof range?.endDate !== "undefined"
  ) {
    query.startDate = range.startDate;
    query.endDate = range.endDate;
  }

  return api.get(`/ledger/${ledgerId}`, {
    params: new URLSearchParams(query),
    authenticated: true,
  });
};

/**
 * Submits a request to get an exchange ledger summary
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @param {object} range - An object describing the range
 * @returns {object}  response - The Response or Error message
 */
export const getExchangeLedgerSummary = (ledgerId, range) => {
  let query = undefined;

  if (
    typeof range?.startDate !== "undefined" &&
    typeof range?.endDate !== "undefined"
  ) {
    query = new URLSearchParams({
      startDate: range.startDate,
      endDate: range.endDate,
    });
  }

  return api.get(`/ledger/${ledgerId}/summary`, {
    params: query,
    authenticated: true,
  });
};

/**
 * Submits a request to get the date range supported by a ledger
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @returns {object}  response - The Response or Error message
 */
export const getExchangeLedgerRange = (ledgerId) => {
  return api.get(`/ledger/${ledgerId}/range`, {
    authenticated: true,
  });
};

/**
 * Submits a request to get the import history of a ledger
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @returns {object}  response - The Response or Error message
 */
export const getExchangeLedgerImportHistory = (ledgerId) => {
  return api.get(`/ledger/${ledgerId}/import`, {
    authenticated: true,
  });
};

/**
 * Submits a request to get the wallet xpubs or addresses for a ledger
 * @async
 * @function
 * @param {number} exchangeId - The id of the exchange
 * @param {number} ledgerId - The id of the ledger
 * @returns {object}  response - The Response or Error message
 */
export const getExchangeLedgerKeys = (exchangeId, ledgerId) => {
  return api.get(`/${exchangeId}/walletKey/ledger/${ledgerId}`, {
    authenticated: true,
  });
};

/**
 * Submits a request to get an exchange ledger worksheet
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @param {number} transactionId - The id of the transaction
 * @returns {object}  response - The Response or Error message
 */
export const getExchangeWorksheet = (ledgerId, transactionId) => {
  return api.get(`/ledger/${ledgerId}/transaction/${transactionId}/worksheet`, {
    authenticated: true,
  });
};

/**
 * Submits a request to get an exchange ledger worksheet
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @param {number} transactionId - The id of the transaction
 * @returns {object}  response - The Response or Error message
 */
export const getExchangeMultiTokenWorksheet = (ledgerId, transactionId) => {
  return api.get(
    `/ledger/${ledgerId}/transaction/${transactionId}/worksheet/multitoken`,
    {
      authenticated: true,
    }
  );
};

/**
 * Submits a request to get an exchange NFT metadata
 * @async
 * @function
 * @param {number} exchangeId - The id of the exchange
 * @param {number} tokenId - The id of the NFT
 * @param {string} contract - The contract address
 * @returns {object}  response - The Response or Error message
 */
export const getNftWorksheet = (exchangeId, tokenId, contract) => {
  return api.post(`/tokenMetadata/nft/${exchangeId}`, {
    data: {
      contract: contract,
      tokenIds: [tokenId],
    },
    authenticated: true,
  });
};

/**
 * Submits a request to favorite a ledger
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @param {boolean} userFavorite - if the ledger is favorite or not
 * @returns {object}  response - The Response or Error message
 */
export const favoriteLedger = (ledgerId, userFavorite) => {
  const query = new URLSearchParams({
    userFavorite,
  });

  return api.put(`/ledger/${ledgerId}/context`, {
    params: query,
    authenticated: true,
  });
};

/**
 * Submits a request to favorite a ledger
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @param {boolean} userFavorite - if the ledger is favorite or not
 * @returns {object}  response - The Response or Error message
 */
export const archiveLedger = (ledgerId, userArchived) => {
  const query = new URLSearchParams({
    userArchived,
  });

  return api.put(`/ledger/${ledgerId}/context`, {
    params: query,
    authenticated: true,
  });
};

/**
 * Submits a request list archived ledgers
 * @async
 * @function
 * @returns {object}  response - The Response or Error message
 */
export const getArchivedLedgers = () => {
  return api.get(`/ledger/archived`, {
    authenticated: true,
  });
};

/**
 * Submits a request to delete many exchange ledgers at once
 * @async
 * @function
 * @param {number} ledgerId - The id of the ledger
 * @returns {object}  response - The Response or Error message
 */
export const bulkDeleteExchangeLedgers = (ledgerIds) => {
  return api.post("/ledger/delete", {
    data: {
      ledgerIds,
    },
    authenticated: true,
  });
};
