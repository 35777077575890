import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { dismissModal } from "app.reducers/ui";
import { useGetExchangeById } from "app.hooks";

import Button from "app.components/Util/Button";
import ModalHeader from "app.components/Modal/ModalHeader";
import ModalControls from "app.components/Modal/ModalControls";
import CopyToClipboard from "app.components/Util/CopyToClipboard";
import ExchangeLogo from "app.components/Images/ExchangeLogo";
import WalletGroup from "app.components/Images/WalletGroup";

import { getSourceGroupName } from "app.utils";

import {
  WALLET,
  WALLET_XPUB,
  WALLET_ADDRESS,
  COMPANY_NAME,
} from "app.constants";

const ModalShowKeys = ({ linkedExchange, ledger }) => {
  const { t } = useTranslation();
  const reduxDispatch = useDispatch();
  const getExchangeById = useGetExchangeById();

  const [show, setShow] = useState(false);

  const { ledgersBySource } = useSelector((state) => state.computables);
  const { supportedExchanges } = useSelector((state) => state.exchanges);

  const exchange = getExchangeById(linkedExchange.exchangeId);

  const isBasicWallet =
    exchange.type === WALLET && !exchange.smartContractPlatform;

  const exchangeName = isBasicWallet ? WALLET : exchange.name;

  const source = ledgersBySource.find((s) => s.source === exchangeName);

  const sourceName = getSourceGroupName(source?.source, supportedExchanges);

  const isLedger = typeof ledger !== "undefined";

  const isXpub = exchange.integrationType === WALLET_XPUB;

  const keys =
    (isXpub
      ? linkedExchange.walletInfoDetails?.xpubs
      : linkedExchange.walletInfoDetails?.addresses) || [];

  function showXpubKeys() {
    setShow(true);
  }

  let title = null;
  if (exchange.integrationType === WALLET_XPUB)
    title = t("common.showXpubKeys");

  if (exchange.integrationType === WALLET_ADDRESS)
    title = t("common.showAddresses");

  return (
    <React.Fragment>
      <ModalHeader
        title={title}
        closeCallback={(e) => {
          e.preventDefault();
          reduxDispatch(dismissModal());
        }}
      />

      <div className="m-4">
        {!show || typeof keys === "undefined" ? (
          <div className="my-4 rounded bg-blue-50 p-3">
            {isXpub ? (
              <React.Fragment>
                <p className="mb-4 text-sm text-blue-500">
                  {t("ledgers.exchangeLedger.areYouSureYouWantToShowKeysP1", {
                    companyName: COMPANY_NAME,
                  })}
                </p>
                <p className="mb-4 text-sm text-blue-500">
                  {t("ledgers.exchangeLedger.areYouSureYouWantToShowKeysP2", {
                    companyName: COMPANY_NAME,
                  })}
                </p>
                <p className="text-sm text-blue-500">
                  {t("ledgers.exchangeLedger.areYouSureYouWantToShowKeysP3", {
                    companyName: COMPANY_NAME,
                  })}
                </p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p className="mb-4 text-sm text-blue-500">
                  {t(
                    "ledgers.exchangeLedger.areYouSureYouWantToShowAddressesP1",
                    { companyName: COMPANY_NAME }
                  )}
                </p>
                <p className="text-sm text-blue-500">
                  {t(
                    "ledgers.exchangeLedger.areYouSureYouWantToShowAddressesP2",
                    { companyName: COMPANY_NAME }
                  )}
                </p>
              </React.Fragment>
            )}
          </div>
        ) : null}
        {show && typeof keys !== "undefined" ? (
          <div>
            {isLedger ? (
              <div>
                <div className="flex items-center px-2.5">
                  {source?.isWallet ||
                  source?.isShadow ||
                  source?.isSourceless ? (
                    <WalletGroup
                      shadowLedger={source?.isShadow}
                      source={source.source}
                    />
                  ) : (
                    <ExchangeLogo name={source} alt={source.name} />
                  )}
                  <div className="ml-2.5 flex flex-col">
                    <div className="flex items-center gap-1">
                      <h3 className="h-5 text-lg uppercase leading-none text-black">
                        {sourceName}
                      </h3>
                    </div>
                    {linkedExchange && !source.isShadow ? (
                      <p className="text-xs leading-none text-zinc-500">
                        <span className="sr-only">
                          {t("portfolio.sourceAccount", { source: sourceName })}
                        </span>
                        {linkedExchange ? linkedExchange.nickname : null}
                      </p>
                    ) : null}
                  </div>
                </div>
                <p className="mt-4">
                  {isXpub
                    ? t("ledgers.exchangeLedger.xpubKeysFor", {
                        count: keys.length,
                        ledgerName: ledger.userLedgerName,
                      })
                    : t("ledgers.exchangeLedger.addressesFor", {
                        count: keys.length,
                        ledgerName: ledger.userLedgerName,
                      })}
                </p>
              </div>
            ) : null}
            {!isLedger ? (
              <div>
                <div className="flex items-center px-2.5">
                  {source?.isWallet ||
                  source?.isShadow ||
                  source?.isSourceless ? (
                    <WalletGroup
                      shadowLedger={source?.isShadow}
                      source={source.source}
                    />
                  ) : (
                    <ExchangeLogo name={source} alt={source.name} />
                  )}
                  <div className="ml-2.5 flex flex-col">
                    <div className="flex items-center gap-1">
                      <h3 className="h-5 text-lg uppercase leading-none text-black">
                        {sourceName}
                      </h3>
                    </div>
                    {linkedExchange && !source.isShadow ? (
                      <p className="text-xs leading-none text-zinc-500">
                        <span className="sr-only">
                          {t("portfolio.sourceAccount", { source: sourceName })}
                        </span>
                        {linkedExchange ? linkedExchange.nickname : null}
                      </p>
                    ) : null}
                  </div>
                </div>

                <p className="mt-4">
                  {isXpub
                    ? t("ledgers.exchangeLedger.xpubKeysFor", {
                        count: keys.length,
                        ledgerName: linkedExchange.nickname,
                      })
                    : t("ledgers.exchangeLedger.addressesFor", {
                        count: keys.length,
                        ledgerName: linkedExchange.nickname,
                      })}
                </p>
              </div>
            ) : null}
            <div className="rounded bg-blue-50 p-3">
              <ul>
                {keys.map((k, index) => (
                  <li
                    title={k}
                    className="flex items-center justify-between"
                    key={index}
                  >
                    <span className="inline-block max-w-fit truncate text-sm text-blue-500">
                      {k}
                    </span>
                    <CopyToClipboard text={k} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : null}

        <div className="my-4 flex justify-end">
          {!show || typeof keys === "undefined" || keys.length === 0 ? (
            <Button
              buttonType="primary"
              onClick={(e) => {
                e.preventDefault();
                showXpubKeys();
              }}
              text={
                isXpub ? t("common.showXpubKeys") : t("common.showAddresses")
              }
            />
          ) : (
            <Button
              buttonType="primary"
              onClick={(e) => {
                e.preventDefault();
                setShow(false);
              }}
              text={
                isXpub ? t("common.hideXpubKeys") : t("common.hideAddresses")
              }
            />
          )}
        </div>
      </div>

      <ModalControls>
        <Button
          onClick={(e) => {
            e.preventDefault();
            reduxDispatch(dismissModal());
          }}
          data-tabbable={true}
          buttonType="text"
          text={t("button.close")}
        />
      </ModalControls>
    </React.Fragment>
  );
};

ModalShowKeys.displayName = "ModalShowKeys";
export default ModalShowKeys;
